import DialogType from '../../data/constant/dialog-type'

export default {
  name: 'Error',
  title: 'エラー',

  mounted() {
    this.$emit('showSimpleModal', {
      type: DialogType.NOTICE,
      title: 'Webからのメッセージ',
      message: 'ログイン情報を確認できませんでした。',
      closeFunc: () => window.location.href = process.env.VUE_APP_LOGIN_URL + window.location.search
    });
  }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "modal", "xmlns:v-bind": "http://www.w3.org/1999/xhtml" }
    },
    [
      _c("div", { staticClass: "modal-mask" }, [
        _c("div", { staticClass: "modal-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "modal-dialog",
              class: {
                "modal-lg": _vm.val.size === "large",
                "modal-md": _vm.val.size === "middle",
                "modal-sm": _vm.val.size === "small"
              },
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("h3", { staticStyle: { "margin-bottom": "0" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "badge text-light",
                              class: {
                                "badge-success":
                                  _vm.val.type === 0 || _vm.val.type === 1,
                                "badge-info": _vm.val.type === 2,
                                "badge-warning":
                                  _vm.val.type === 3 || _vm.val.type >= 5,
                                "badge-danger": _vm.val.type === 4
                              }
                            },
                            [
                              _vm.val.type === 0 || _vm.val.type === 1
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: "check" }
                                  })
                                : _vm._e(),
                              _vm.val.type === 2
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: "info" }
                                  })
                                : _vm._e(),
                              _vm.val.type >= 3
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: "exclamation-triangle" }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "col-8 text-center",
                          staticStyle: { "margin-top": "8px" }
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "line-height": "25px" } },
                            [_vm._v(" " + _vm._s(_vm.val.title) + " ")]
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _c("div", {
                  staticClass: "modal-body text-left",
                  staticStyle: {
                    "max-height": "calc(100vh - 180px)",
                    "overflow-y": "auto"
                  },
                  domProps: { innerHTML: _vm._s(_vm.replacedMessage) }
                }),
                _c(
                  "div",
                  {
                    staticClass: "modal-footer",
                    staticStyle: { display: "block" }
                  },
                  [
                    _c(
                      "button",
                      {
                        ref: "focus",
                        staticClass: "btn btn-primary btn-width-md mx-auto",
                        on: { click: _vm.close }
                      },
                      [_vm._v(" 確認 ")]
                    )
                  ]
                )
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
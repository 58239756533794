import SearchType from '../../data/constant/search-type'
import HandsontableWrapper from "../../components/table/Handsontable/Handsontable.vue";
import Handsontable from "handsontable";
import DialogType from '../../data/constant/dialog-type'
import { profit } from '../../api';
import moment from 'moment';
import Validator from '../../validators/index';
export default {
    name: 'ProfitRegist',
    title: '黒修正登録',

    components: {
        HandsontableWrapper,
    },

    data: function () {
        return {
            type: 'PROFIT',
            moveFromSearch: this.$route.query.move_from_search,
            CENTER: SearchType.CENTER,
            CUSTOMER: SearchType.CUSTOMER,
            REGISTRATION_USER: SearchType.REGISTRATION_USER,
            MAKER: SearchType.MAKER,
            modalId: '',
            isSearchModalVisible: false,
            searchModalVal: {},
            isExportModalVisible: false,
            isDuplicateModalVisible: false,
            isAddRow: false,

            oldData: [],
            table: { data: [] },
            yearMonthList: [],
            patternList: [],
            centerList: [],
            customerList: [],
            makerList: [],
            registrationUserList: [],
            yearMonthName: '',
            form: {
                yearMonth: '',
                pattern: '',
                outType: '1'
            },
            searchCondition: {},
            colIndexDel: 0,
            colSelect: null,
            rowCount: 0,
            lastChange: null,
            tableSettings: {
                rowHeaders: true,
                colHeaders: ['パターン', '発生日', 'メーカー', '支店', 'センター', '得意先', '商品',
                    '入数', '建値', '仕入額', 'ボール', '担当者', '黒修正金額', 'コメント', '削除'],
                colWidths: [200, 100, 130, 70, 120, 130, 200, 65, 65, 65, 65, 65, 70, 180, 40],
                cells: this.cells,
                afterChange: this.afterChange,
                afterOnCellMouseDown: this.afterOnCellMouseDown,
                afterBeginEditing: this.afterBeginEditing,
                manualColumnResize: true,
                manualRowResize: true,
            },
            exportSetting: {
                fileType: '1',
                inputFlg: false
            },
            profitAmountTotal: 0,
            oldProfitAmountTotal: 0,
            profitRegistList: [],
            columnSetting: {},
            isDisabled: true,
            endEdit: false,
            removeRowsList: [],
            rowsChangesList: [],
            rowsAdd: [],
            minDate: '',
            maxDate: '',
            commentEror: false,
            commentErorRow: 0,
            commentErorCol: 0,
        }
    },
    created() {
        this.$store.commit('setLoading', true);

        this.yearMonthList = this.getStorage('lastYearMonthList') || [];
        this.profitRegistList = this.getStorage('profitRegistList') || [];
        this.profitRegistList.forEach(value => this.patternList.push({ cd: parseInt(value.cd), name: value.cd + ":" + value.name }))
        this.yearMonthName = this.yearMonthList.length > 0 ? this.yearMonthList[0].yearMonthName : '';

        this.$store.commit('setLoading', false);
    },

    mounted() {
        this.minDate = new Date();
        this.maxDate = new Date();
        this.minDate.setMonth(-9);
        const setting = {
            columns: [
                { data: "registerPattern", type: "dropdown", className: "htLeft", readOnly: false },
                {
                    data: "occurDate", type: "date", className: "htRight", readOnly: false,
                    dateFormat: 'YYYY/MM/DD',
                    correctFormat: true,
                    defaultDate: '01/01/1900',
                    // datePicker additional options (see https://github.com/dbushell/Pikaday#configuration)
                    datePickerConfig: {
                        showDaysInNextAndPreviousMonths: true,
                        enableSelectionDaysInNextAndPreviousMonths: true,
                        // First day of the week (0: Sunday, 1: Monday, etc)
                        firstDay: 0,
                        showWeekNumber: true,
                        numberOfMonths: 1,
                        // endDate: currentDate,
                        minDate: this.minDate,
                        maxDate: this.maxDate,
                        // startDate: startDate,
                        // disableDayFn: function(date) {
                        //     // Disable Sunday and Saturday
                        //     return date.getDay() === 0 || date.getDay() === 6;
                        // }
                    }
                },
                { data: "makerCd", type: "text", className: "htLeft text-nowrap", readOnly: false, columnSorting: true, api: "maker", renderer: this.$refs.myTable.colRenderer },
                { data: "departmentCd", type: "text", className: "htLeft text-nowrap", readOnly: false, api: "branch", renderer: this.$refs.myTable.colRenderer },
                { data: "warehouseCd", type: "text", className: "htLeft text-nowrap", readOnly: false, api: "center", renderer: this.$refs.myTable.colRenderer },
                { data: "customerCd", type: "text", className: "htLeft text-nowrap", readOnly: false, api: "customer", renderer: this.$refs.myTable.colRenderer },
                { data: "itemCd", type: "text", className: "htLeft text-nowrap", readOnly: false, api: "item", renderer: this.$refs.myTable.colRenderer },
                { data: "irisu", type: "numeric", className: "htRight text-nowrap", readOnly: false, numericFormat: { pattern: '0,0' } },
                { data: "tatene", type: "numeric", className: "htRight text-nowrap", readOnly: false, numericFormat: { pattern: '0,0.00' } },
                { data: "purchasePrice", type: "numeric", className: "htRight text-nowrap", readOnly: false, numericFormat: { pattern: '0,0.00' } },
                { data: "bowlNumber", type: "numeric", className: "htRight text-nowrap", readOnly: false, numericFormat: { pattern: '0,0' } },
                { data: "tantoUserCd", type: "text", className: "htLeft text-nowrap", readOnly: false, columnSorting: true, api: "tanto", renderer: this.$refs.myTable.colRenderer },
                { data: "profitAmount", type: "numeric", className: "htRight text-nowrap", numericFormat: { pattern: '0,0' }, readOnly: false },
                { data: "comment", type: "text", className: "htLeft text-nowrap", readOnly: false },
                { data: "delete", renderer: this.deleteButton, readOnly: true },
                { data: "updateId", type: "numeric", readOnly: true },
                { data: "profitId", type: "numeric", readOnly: true },
            ]
        };
        this.$refs.myTable.$refs.hotTable.hotInstance.updateSettings(setting, false);
        this.getColumnSetting()
        this.search();
    },

    methods: {
        /**
         * 検索モーダル表示
         * */
        showSearchModal: function (modalId, isSingle) {

            this.modalId = modalId;

            this.searchModalVal.modalId = modalId;
            this.searchModalVal.isSingle = isSingle;
            switch (modalId) {
                case SearchType.CENTER:
                    this.searchModalVal.selectedItemList = this.centerList;
                    break;
                case SearchType.CUSTOMER:
                    this.searchModalVal.selectedItemList = this.customerList;
                    break;
                case SearchType.MAKER:
                    this.searchModalVal.selectedItemList = this.makerList;
                    break;
                case SearchType.REGISTRATION_USER:
                    this.searchModalVal.selectedItemList = this.registrationUserList;
                    break;
            }
            //検索モーダル表示
            this.isSearchModalVisible = true;
        },
        /**
         * 検索
         * */
        search() {
            if (this.moveFromSearch) {
                this.$store.commit('setLoading', true);
                this.table.data = [];
                var profitIdList = this.$store.state.profitIdList;
                const request = {
                    profitId: profitIdList
                };
                var self = this;
                profit.getEditList(request)
                    .then((response) => {
                        if (response.data.result.responseList.length == 0) {
                            self.$store.commit('setLoading', false);
                            return;
                        }
                        var rs = response.data.result.responseList;
                        for (let i = 0; i < response.data.result.responseList.length; i++) {
                            const element = response.data.result.responseList[i];
                            rs[i].updateId = element.updateId ? parseInt(element.updateId) : '';
                            rs[i].profitId = element.profitId ? parseInt(element.profitId) : '';
                            rs[i].registerPattern = element.registerPattern ? parseInt(element.registerPattern) : '';
                            rs[i].occurDate = element.occurDate ? moment(element.occurDate).format('YYYY/MM/DD') : '';
                            rs[i].tatene = element.tatene ? parseFloat(element.tatene) : '';
                            rs[i].irisu = element.irisu ? parseFloat(element.irisu) : '';
                            rs[i].purchasePrice = element.purchasePrice ? parseFloat(element.purchasePrice) : '';
                            rs[i].bowlNumber = element.bowlNumber ? parseFloat(element.bowlNumber) : '';
                            rs[i].profitAmount = element.profitAmount ? parseFloat(element.profitAmount) : '';
                            if (self.patternList.length > 0) {
                                self.patternList.forEach(value => {
                                    if (value.cd === element.registerPattern) {
                                        rs[i].registerPattern = value.name;
                                    }
                                });
                            }
                        }
                        self.oldData = [];
                        rs.forEach(value => {
                            const temp = JSON.parse(JSON.stringify(value));
                            self.oldData.push(temp);
                        })
                        self.setCdNameExist(rs);
                        //列を非表示にする[削除]
                        var columnSetting = self.$refs.myTable.$refs.hotTable.hotInstance.getSettings().__proto__.columns;
                        columnSetting[0].readOnly = true;
                        self.$refs.myTable.$refs.hotTable.hotInstance.updateSettings({
                            colWidths: [200, 100, 130, 70, 120, 130, 200, 65, 65, 65, 65, 65, 70, 180, 0.1, 0.1, 0.1],
                            columns: columnSetting
                        });

                        //テーブルにデータをロードします
                        self.$refs.myTable.$refs.hotTable.hotInstance.loadData(rs || []);
                        self.rowCount = self.$refs.myTable.$refs.hotTable.hotInstance.countRows();
                        for (let i = 0; i < rs.length; i++) {
                            if (self.getPair(rs[i].registerPattern.split(':')[0]) == 1) {
                                var colReadOnly = ['registerPattern', 'occurDate', 'makerCd', 'itemCd', 'profitAmount'];
                                colReadOnly.forEach(element => {
                                    self.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(i + 1, self.$refs.myTable.$refs.hotTable.hotInstance.propToCol(element), 'readOnly', true);
                                    var className = self.$refs.myTable.$refs.hotTable.hotInstance.getCellMeta(i + 1, self.$refs.myTable.$refs.hotTable.hotInstance.propToCol(element)).className;
                                    self.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(i + 1, self.$refs.myTable.$refs.hotTable.hotInstance.propToCol(element), 'className', className + ' readOnly');
                                });
                                i++;
                            }
                        }
                        for (let i = 0; i < rs.length; i++) {
                            this.validate(i,rs[i].registerPattern.split(':')[0])
                        }
                        self.$refs.myTable.$refs.hotTable.hotInstance.render();
                        rs.forEach(value => {
                            self.profitAmountTotal += value.profitAmount ? value.profitAmount : 0;
                            self.oldProfitAmountTotal += value.profitAmount ? value.profitAmount : 0;
                        })
                        self.profitAmountTotal = new Intl.NumberFormat().format(self.profitAmountTotal);
                        self.oldProfitAmountTotal = new Intl.NumberFormat().format(self.oldProfitAmountTotal);
                        this.isDisabled = true;
                        self.$store.commit('setLoading', false);
                    })
                    .catch((e) => {
                        console.log('error', e);
                        this.rowCount = 0;
                        this.$refs.myTable.$refs.hotTable.hotInstance.loadData([]);
                        this.$emit('displayErrorMessage', e);
                        this.$store.commit('setLoading', false);
                    });
            } else {
                this.$store.commit('setLoading', true);
                for (let i = 0; i < 10; i++) {
                    this.table.data.push({
                        registerPattern: null,
                        occurDate: null,
                        makerCd: null,
                        departmentCd: null,
                        warehouseCd: null,
                        customerCd: null,
                        itemCd: null,
                        tatene: null,
                        irisu: null,
                        purchasePrice: null,
                        bowlNumber: null,
                        tantoUserCd: null,
                        comment: null
                    });
                    this.oldData.push({
                        registerPattern: null,
                        occurDate: null,
                        makerCd: null,
                        departmentCd: null,
                        warehouseCd: null,
                        customerCd: null,
                        itemCd: null,
                        tatene: null,
                        irisu: null,
                        purchasePrice: null,
                        bowlNumber: null,
                        tantoUserCd: null,
                        comment: null
                    });
                }
                this.$refs.myTable.$refs.hotTable.hotInstance.loadData(this.table.data || []);
                this.rowCount = this.$refs.myTable.$refs.hotTable.hotInstance.countRows();
                //列を非表示にする[削除]
                this.$refs.myTable.$refs.hotTable.hotInstance.updateSettings({
                    colWidths: [200, 100, 130, 70, 120, 130, 200, 65, 65, 65, 65, 65, 70, 180, 50, 0.1, 0.1]
                });
                this.$store.commit('setLoading', false);
            }

        },

        /**
         * 検索条件追加
         * @param val
         * */
        addItem(val) {
            switch (this.modalId) {
                case SearchType.CENTER:
                    this.centerList = val;
                    break;
                case SearchType.CUSTOMER:
                    this.customerList = val;
                    break;
                case SearchType.MAKER:
                    this.makerList = val;
                    break;
                case SearchType.REGISTRATION_USER:
                    this.registrationUserList = val;
                    break;
            }
        },

        /**
         * 検索条件クリア
         * @param value
         * */
        clear(value) {
            switch (value) {
                case SearchType.CENTER:
                    this.centerList = [];
                    break;
                case SearchType.CUSTOMER:
                    this.customerList = [];
                    break;
                case SearchType.MAKER:
                    this.makerList = [];
                    break;
                case SearchType.REGISTRATION_USER:
                    this.registrationUserList = [];
                    break;
            }

            if (value) return;

            this.form.yearMonth = this.yearMonthList.length > 0 ? this.yearMonthList[0].yearMonthCd : '';
            this.form.outType = '1';
            this.centerList = [];
            this.customerList = [];
            this.makerList = [];
            this.registrationUserList = [];
        },
        async regist() {
            const hot = this.$refs.myTable.$refs.hotTable.hotInstance;

            Validator.methods.validator(this, this.profitRegistList,(valid)=>{
                if(!valid){
                    var dataTable = hot.getSourceData();
                    var data = JSON.parse(JSON.stringify(dataTable));
                    var registData = [];
                    for (let i = 0; i < data.length; i++) {
                        if (hot.isEmptyRow(i)) {
                            continue;
                        }
                        const dataTemp = JSON.parse(JSON.stringify(data[i]));
                        dataTemp.no = i;
                        dataTemp.registerPattern = this.patternList.length > 0 && dataTemp.registerPattern ? parseInt(this.patternList.find(value => dataTemp.registerPattern === value.name).cd) : null;
                        var tempData = {
                            registerPattern: null,
                            occurDate: null,
                            makerCd: null,
                            departmentCd: null,
                            warehouseCd: null,
                            customerCd: null,
                            itemCd: null,
                            tatene: null,
                            irisu: null,
                            purchasePrice: null,
                            bowlNumber: null,
                            tantoUserCd: null,
                            profitAmount: null,
                            comment: null,
                            updateId: null,
                            profitId: null,
                            no: null
                        }
                        Object.keys(tempData).map((key) => {
                            if (key == 'occurDate') {
                                tempData[key] = dataTemp[key] == undefined ? dataTemp[key] : moment(dataTemp[key]).format('YYYYMMDD');
                            } else {
                                tempData[key] = dataTemp[key] == undefined ? null : dataTemp[key]
                            }
                        })
                        registData.push(tempData)
                    }
                    if (registData.length == 0) {
                        return;
                    }
        
                    const request = {
                        registerList: registData
                    };
                    this.$store.commit('setLoading', true);
                    profit.register(request)
                        .then((response) => {
                            console.log(response)
                            if (response.data.statusCd === 0) {
                                this.$emit('showSimpleModal', {
                                    type: DialogType.SUCCESS,
                                    title: '確認',
                                    message: response.data.message,
                                    closeFunc: () => {
                                        if (this.moveFromSearch) {
                                            this.endEdit = true;
                                            this.$router.push({ path: '/profit_search', query: {move_from_edit: true} });
                                        } else {
                                            let data = [];
                                            for (let i = 0; i < 10; i++) {
                                                data.push({});
                                            }
                                            hot.loadData(data);
                                            this.isDisabled = true;
                                            this.profitAmountTotal = 0;
                                        }
                                    }
                                });
                            } else {
                                this.$emit('showSimpleModal', {
                                    type: DialogType.WARNING,
                                    title: '確認',
                                    message: response.data.message,
                                });
                            }
                            this.$store.commit('setLoading', false);
                        })
                        .catch((e) => {
                            console.log('error', e);
                            this.$emit('displayErrorMessage', e);
                            this.$store.commit('setLoading', false);
                        });
                }
            })
        },
        cancel() {
            this.$emit('showConfirmModal', {
                type: DialogType.INFO,
                title: '確認',
                message: '入力内容を破棄します。よろしいですか？',
                confirm: () => {
                    const oldData = new Array();
                    this.oldData.forEach(value => {
                        const data = JSON.parse(JSON.stringify(value));
                        oldData.push(data);
                    })
                    this.$refs.myTable.$refs.hotTable.hotInstance.loadData(oldData);
                    for (let i = 0; i < oldData.length; i++) {
                        if (this.getPair(oldData[i].registerPattern.split(':')[0]) == 1) {
                            var colReadOnly = ['registerPattern', 'occurDate', 'makerCd', 'itemCd', 'profitAmount'];
                            colReadOnly.forEach(element => {
                                this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(i + 1, this.$refs.myTable.$refs.hotTable.hotInstance.propToCol(element), 'readOnly', true);
                                var className = this.$refs.myTable.$refs.hotTable.hotInstance.getCellMeta(i + 1, this.$refs.myTable.$refs.hotTable.hotInstance.propToCol(element)).className;
                                this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(i + 1, this.$refs.myTable.$refs.hotTable.hotInstance.propToCol(element), 'className', className + ' readOnly');
                            });
                            i++;
                        }
                    }
                    for (let i = 0; i < oldData.length; i++) {
                        this.validate(i,oldData[i].registerPattern.split(':')[0])
                    }
                    this.isDisabled = true;
                    this.profitAmountTotal = this.oldProfitAmountTotal
                    this.$refs.myTable.$refs.hotTable.hotInstance.render();
                }
            });
        },
        cells(row, cols, prop) {
            switch (prop) {
                case 'registerPattern':
                    {
                        const cellProperties = {};
                        cellProperties.source = this.patternList.map(value => value.name);
                        return cellProperties;
                    }
            }
        },
        addRow() {
            this.$refs.myTable.$refs.hotTable.hotInstance.alter('insert_row', this.$refs.myTable.$refs.hotTable.hotInstance.countRows(), 1);
        },
        afterOnCellMouseDown: function (e, coords) {
            //削除行を処理する
            var hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            this.colIndexDel = hot.countCols();
            if (coords.col === this.colIndexDel - 3) {
                var dataFirstCol = hot.getDataAtCell(coords.row, 0);
                var classNameFirstCol = hot.getCellMeta(coords.row, 0);
                if (classNameFirstCol.className.indexOf('readOnly') == -1) {
                    if (dataFirstCol != undefined && this.getPair(dataFirstCol.split(':')[0]) == 1) {
                        hot.alter("remove_row", coords.row+1);
                        hot.alter("remove_row", coords.row);
                    }else{
                        hot.alter("remove_row", coords.row);
                    }
                }
                const data = hot.getSourceData();
                //Calc profitAmountTotal
                let sum = 0;
                data.forEach(value => {
                    sum += value.profitAmount ? value.profitAmount : 0;
                })
                this.profitAmountTotal = new Intl.NumberFormat().format(sum);
            }
            
            //最終行をクリックすると行追加が行われる。
            const MAX_ROW = 999;
            var n = this.$refs.myTable.$refs.hotTable.hotInstance.countRows() - 1;
            var isEdit = this.moveFromSearch;
            if (coords.row === n++ && coords.col !== this.colIndexDel - 1 && n < MAX_ROW - 1 && !isEdit && this.lastChange) {
                this.$refs.myTable.$refs.hotTable.hotInstance.alter('insert_row', n, 1);
            }
            if(this.commentEror){
                this.$refs.myTable.$refs.hotTable.hotInstance.deselectCell();
            }
            this.lastChange = null;
        },
        afterChange: function (changes, source) {
            this.lastChange = changes;
            
            var hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            if (source == 'loadData') {
                return;
            }
            //仕入金額自動計算
            //一括ペーストもあるのでループする 行の配列を作成する            
            let rowarraytmp = [];
            for (let i = 0; i < changes.length; i++) {
                rowarraytmp.push(changes[i][0]);
            }
            const rowarray = Array.from(new Set(rowarraytmp))
            //行ごとに再計算
            for (let i = 0; i < rowarray.length; i++) {               
                let r = rowarray[i]; //row index
                if (hot.getDataAtCell(r, 7) && 
                    hot.getDataAtCell(r, 8) &&
                    hot.getDataAtCell(r, 10)){
                        let calcShiiregaku =   (Math.round(parseInt(hot.getDataAtCell(r, 7)) * parseFloat(hot.getDataAtCell(r, 8).toFixed(2)) * parseInt(hot.getDataAtCell(r, 10)) * 100)) / 100;
                        if(calcShiiregaku != hot.getDataAtCell(r, 9)){
                            hot.setDataAtCell(r, 9, calcShiiregaku);
                        }
                }
            }
            
            this.setOccurDateDefault();
            var col = hot.propToCol(changes[0][1]);
            var className = hot.getCellMeta(changes[0][0], col).className;
            if (changes == null || changes[0][1] == 'occurDate' || changes[0][1] == 'makerCd' || changes[0][1] == 'itemCd' || changes[0][1] == 'profitAmount') {
                var dataFirstCol = hot.getDataAtCell(changes[0][0], 0);
                var classNameFirstCol = hot.getCellMeta(changes[0][0], 0);
                if (classNameFirstCol.className.indexOf('readOnly') == -1) {
                    if (dataFirstCol != undefined && this.getPair(dataFirstCol.split(':')[0]) == 1) {
                        if (changes[0][1] == 'profitAmount') {
                            hot.setDataAtCell(changes[0][0] + 1, col, parseInt(changes[0][3]) * -1);
                        } else {
                            hot.setDataAtCell(changes[0][0] + 1, col, changes[0][3]);
                        }
                    }
                }
                //Calc profitAmountTotal
                if (changes[0][1] === 'profitAmount') {
                    const data = hot.getSourceData();
                    let sum = 0;
                    data.forEach(value => {
                        sum += value.profitAmount ? value.profitAmount : 0;
                    })
                    this.profitAmountTotal = new Intl.NumberFormat().format(sum);
                }
                return;
            }
            if (changes == null || changes[0][1] != 'registerPattern') {
                return;
            }
            if (className.indexOf('readOnly') != -1) {
                this.isAddRow = true;
            } else {
                this.isAddRow = false;
            }

            this.conditionTranfer(changes);

            // create row for pair of patter equal 1
            this.createRowAdd();

            //Calc profitAmountTotal
            const data = hot.getSourceData();
            let sum = 0;
            data.forEach(value => {
                sum += value.profitAmount ? value.profitAmount : 0;
            })
            this.profitAmountTotal = new Intl.NumberFormat().format(sum);

            this.rowsChangesList.forEach(value => {
                this.validate(value.row, value.id);
            })
            this.rowsChangesList = [];
            var temp = this.removeRowsList.filter((v, i, a) => a.indexOf(v) === i)
            for(var i = temp.length - 1; i >= 0; i--){
                hot.alter("remove_row", temp[i]);
            }
            this.removeRowsList = [];
            hot.render();
        },

        validate(row, id) {
            var hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            var validatorData = this.profitRegistList;
            var classNameFirst = hot.getCellMeta(row, 0).className;
            if(id == ''){
                var nextRow = hot.getCellMeta(row+1, 0);
                if(nextRow.readOnly){
                    this.removeRowsList.push(row + 1);
                }
                var columnSetting = this.$refs.myTable.$refs.hotTable.hotInstance.getSettings().__proto__.columns;
                columnSetting.forEach((value, index) => {
                    var className1 = '';
                    hot.setCellMeta(row, index, 'valid', true);
                    hot.setCellMeta(row, index, 'validator', this.unvalidator);
                    hot.setCellMeta(row, index, 'readOnly', false);
                    className1 = hot.getCellMeta(row, index).className;
                    if(className1){
                        hot.setCellMeta(row, index, 'className', className1.replaceAll(' htDimmed', '').replaceAll(' htInvalid', '').replaceAll(' readOnly', ''));
                    }
                })
                return;
            }
            validatorData.forEach(value => {
                if (value.cd == id) {
                    for (const [key, values] of Object.entries(value)) {
                        var className = '';
                        if (this.columnSetting[key] != undefined) {
                            var classNames = hot.getCellMeta(row, this.columnSetting[key]).className;
                            if (classNameFirst.indexOf('readOnly') != -1 && classNames.indexOf('readOnly') != -1) {
                                hot.setCellMeta(row, this.columnSetting[key], 'validator', this.unvalidator);
                                hot.setCellMeta(row, this.columnSetting[key], 'valid', true);
                                hot.setCellMeta(row, this.columnSetting[key], 'readOnly', true);
                                className = hot.getCellMeta(row, this.columnSetting[key]).className;
                                hot.setCellMeta(row, this.columnSetting[key], 'className', className.replaceAll(' htDimmed', '').replaceAll(' htInvalid', '') + ' readOnly');
                                continue;
                            } else {
                                switch (values) {
                                    case '1':
                                        hot.setCellMeta(row, this.columnSetting[key], 'valid', true);
                                        hot.setCellMeta(row, this.columnSetting[key], 'validator', this.validator);
                                        hot.setCellMeta(row, this.columnSetting[key], 'readOnly', false);
                                        className = hot.getCellMeta(row, this.columnSetting[key]).className;
                                        if(className != ""){
                                            className = className.replaceAll(' readOnly', '').replaceAll(' htDimmed', '');
                                            hot.setCellMeta(row, this.columnSetting[key], 'className', className);
                                        }
                                        break;
                                    case '2':
                                        hot.setCellMeta(row, this.columnSetting[key], 'validator', this.unvalidator);
                                        hot.setCellMeta(row, this.columnSetting[key], 'valid', true);
                                        hot.setCellMeta(row, this.columnSetting[key], 'readOnly', true);
                                        if(this.moveFromSearch != 'true'){
                                            hot.setDataAtCell(row, this.columnSetting[key], '');
                                        }
                                        className = hot.getCellMeta(row, this.columnSetting[key]).className;
                                        if(className != ""){
                                            hot.setCellMeta(row, this.columnSetting[key], 'className', className.replaceAll(' htDimmed', '').replaceAll(' htInvalid', '') + ' readOnly');
                                        }
                                        break;
                                    case '0':
                                        hot.setCellMeta(row, this.columnSetting[key], 'valid', true);
                                        hot.setCellMeta(row, this.columnSetting[key], 'validator', this.unvalidator);
                                        hot.setCellMeta(row, this.columnSetting[key], 'readOnly', false);
                                        className = hot.getCellMeta(row, this.columnSetting[key]).className;
                                        if(className != ""){
                                            hot.setCellMeta(row, this.columnSetting[key], 'className', className.replaceAll(' htDimmed', '').replaceAll(' htInvalid', '').replaceAll(' readOnly', ''));
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                    }
                }
            })
            // hot.render();
        },
        validator: function (value, callback) {
            if (this.empty(''+value)) {
                callback(true)
            } else {
                callback(false)
            }
        },
        unvalidator: function (value, callback) {
            callback(true)
        },
        empty(value) {
            if (value == undefined || value == "") {
                return false;
            }
            return true;
        },
        getColumnSetting() {
            var columnSetting = this.$refs.myTable.$refs.hotTable.hotInstance.getSettings().__proto__.columns;
            columnSetting.forEach((value, index) => {
                var colName = value.data.toLowerCase();
                this.columnSetting[colName] = index
            })
        },
        getPair(cd) {
            var validatorData = this.profitRegistList;
            var pair = '';
            validatorData.forEach(value => {
                if (value.cd == cd) {
                    pair = value.pair;
                }
            })
            return pair;
        },
        getMinus(cd) {
            var validatorData = this.profitRegistList;
            var minus = '';
            validatorData.forEach(value => {
                if (value.cd == cd) {
                    minus = value.minus;
                }
            })
            return minus;
        },
        deleteButton(instance, td, row){
            Handsontable.renderers.HtmlRenderer.apply(this, arguments);
            var hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            var classNameFirstCol = hot.getCellMeta(row, 0);
            if (classNameFirstCol.className.indexOf('readOnly') != -1) {
                td.innerHTML = '<button style="border: none; background: none; height: 10px;"></button>'
            }else{
                td.innerHTML = '<button style="border: none; background: none; height: 10px;">✖</button>'
            }
        },
        setCdNameExist(data) {
            data.forEach(element => {
                if (!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName, 'maker', element.makerCd)) {
                    this.$refs.myTable.listName.push({ ['maker']: [element.makerCd ? element.makerCd : '', element.makerName ? ":" + element.makerName : ''] });
                }

                if (!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName, 'branch', element.departmentCd)) {
                    this.$refs.myTable.listName.push({ ['branch']: [element.departmentCd ? element.departmentCd : '', element.departmentName ? ":" + element.departmentName : ''] });
                }

                if (!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName, 'center', element.departmentCd, element.warehouseCd)) {
                    this.$refs.myTable.listName.push({
                        ['center']: [element.departmentCd ? element.departmentCd : '',
                        element.warehouseCd ? element.warehouseCd : '',
                        element.warehouseName ? ":" + element.warehouseName : '']
                    });
                }
                if (!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName, 'customer', element.customerCd)) {
                    this.$refs.myTable.listName.push({ ['customer']: [element.customerCd ? element.customerCd : '', element.customerName ? ":" + element.customerName : ''] });
                }
                if (!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName, 'tanto', element.tantoUserCd)) {
                    this.$refs.myTable.listName.push({ ['tanto']: [element.tantoUserCd ? element.tantoUserCd : '', element.tantoUserName ? ":" + element.tantoUserName : ''] });
                }
                if (!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName, 'item', element.itemCd)) {
                    this.$refs.myTable.listName.push({ ['item']: [element.itemCd ? element.itemCd : '', element.itemName ? ":" + element.itemName : ''] });
                }
            });
        },
        afterBeginEditing: function (row, col) {
            var colName = this.$refs.myTable.$refs.hotTable.hotInstance.colToProp(col);
            var self = this;
            switch (colName) {
                case 'occurDate': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexDate(value, row, col)) {
                            boolean = true;
                        }
                        return boolean;
                    });
                    break;
                } 
                case 'makerCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, 6);
                    break;
                } 
                case 'departmentCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, 8);
                    break;
                } 
                case 'warehouseCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, 8);
                    break;
                } 
                case 'customerCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, 8);
                    break;
                } 
                case 'itemCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, 12);
                    break;
                } 
                case 'irisu': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 5)) {
                            boolean = true;
                            return boolean;
                        }
                        return boolean;
                    });
                    break;
                } 
                case 'tatene': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 8)) {
                            boolean = true;
                            return boolean;
                        }
                        if (self.regexFloat(value, 8, 2)) {
                            boolean = true;
                        }
                        return boolean;
                    });
                    break;
                } 
                case 'purchasePrice': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 8)) {
                            boolean = true;
                            return boolean;
                        }
                        if (self.regexFloat(value, 8, 2)) {
                            boolean = true;
                        }
                        return boolean;
                    });
                    break;
                } 
                case 'bowlNumber': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 9)) {
                            boolean = true;
                            return boolean;
                        }
                        return boolean;
                    });
                    break;
                } 
                case 'tantoUserCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, 8);
                    break;
                } 
                case 'profitAmount': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLengthAllowNagative(value, 11)) {
                            boolean = true;
                            return boolean;
                        }
                        return boolean;
                    });
                }break;
                case 'comment': {
                    this.setFocusOut(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA,row,col);
                } break;
            }
            this.isDisabled = true;
        },
        setInputFilter(textbox, inputFilter) {
            textbox.oldValue = textbox.value;
            textbox.oninput = function () {
                if (inputFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.oldValue != undefined) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd + 1);
                } else {
                    this.value = '';
                }
            };
        },
        setInputFilter2(textbox, row, col, length) {
            var self = this;
            textbox.onblur = function () {
                var rex = /[\uFF10-\uFF19]/g;
                var str = this.value;
                str = str.replace(rex, function(ch) {
                    return String.fromCharCode(ch.charCodeAt(0) - 65248);
                });
                self.$refs.myTable.$refs.hotTable.hotInstance.setDataAtCell(row, col, str)
                this.onblur = ()=>{}

            };
            textbox.oninput = function () {
                if (this.value.length > length) {
                    this.value = this.value.substring(0,length);
                    return false;
                }
            };
        },
        setFocusOut(textbox,row, col) {
            var self = this;
            textbox.oldValue = textbox.value;
            textbox.onblur = function () {
                if (!self.regexSingleChar(this.value)) {
                    self.commentEror = true;
                    self.commentErorRow = row;
                    self.commentErorCol = col;
                    const customBordersPlugin = self.$refs.myTable.$refs.hotTable.hotInstance.getPlugin('customBorders');
                    customBordersPlugin.setBorders([[row, col, row, col]], {left: {width: 2, color: '#4B89FF'}, top: {width: 2, color: '#4B89FF'}, right: {width: 2, color: '#4B89FF'},bottom: {width: 2, color: '#4B89FF'}});
                    self.$refs.myTable.$refs.hotTable.hotInstance.updateSettings({outsideClickDeselects:false}, false);
                    setTimeout(
                        function(){
                            self.$refs.myTable.$refs.hotTable.hotInstance.deselectCell();
                            self.$emit('showSimpleModal', {
                                type: DialogType.NOTICE,
                                title: 'Webからのメッセージ',
                                message: 'コメントは半角で入力してください',
                                closeFunc:()=>{
                                    textbox.onblur = ()=>{};
                                    customBordersPlugin.setBorders([[row, col, row, col]], {left: {width: 1, color: '#CCC'}, top: {width: 1, color: '#CCC'}, right: {width: 1, color: '#CCC'},bottom: {width: 1, color: '#CCC'}});
                                    self.$refs.myTable.$refs.hotTable.hotInstance.selectCell(row,col);
                                    self.commentEror = false;
                                    self.$refs.myTable.$refs.hotTable.hotInstance.updateSettings({outsideClickDeselects:true}, false);
                                }
                            });
                        },100
                    )
                }
            };
            textbox.oninput = function(){};
        },
        regexDate(value, row, col) {
            const hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            const n = value.length;
            let rs = false;
            switch (n) {
                case 0:
                    rs = true
                    break;
                case 1:
                    rs = /^\d{1}$/.test(value)
                    break;
                case 2:
                    rs = /^\d{2}$/.test(value)
                    break;
                case 3:
                    rs = /^\d{3}$/.test(value)
                    break;
                case 4:
                    rs = /^\d{4}$/.test(value)
                    break;
                case 5:
                    rs = /^\d{4}(\/|[0-1])$/.test(value)
                    break;
                case 6:
                    rs = /^\d{4}(\/[0-1]|(0[1-9]|1[0-2]))$/.test(value)
                    break;
                case 7:
                    rs = /^\d{4}(\/(0[1-9]|1[0-2]))|((0[1-9]|1[0-2])[0-3])$/.test(value)
                    break;
                case 8:
                    rs = /^\d{4}((\/(0[1-9]|1[0-2])\/)|(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1]))$/.test(value)
                    if (rs && !value.includes('/')) {
                        let d = `${value.substring(0,4)}/${value.substring(4,6)}/${value.substring(6,8)}`
                        d = this.checkRangeDate(d);
                        hot.setDataAtCell(row, col, d)
                    }
                    break;
                case 9:
                    rs = /^\d{4}\/(0[1-9]|1[0-2])\/[0-3]$/.test(value)
                    break;
                case 10:
                    rs = /^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])$/.test(value)
                    break;
                default:
                    this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA.value = value.substring(0,10);
                    rs = true;
                    break;
            }
            return rs
        },
        checkRangeDate(date) {
            let rs = date;
            if (moment(date) > this.maxDate) {
                rs = this.maxDate;
            } else if (moment(date) < this.minDate) {
                rs = this.minDate;
            }
            return moment(rs).format('YYYY/MM/DD').toString();
        },
        regexLength(value, length) {
            var regex = new RegExp("^[0-9]{0," + length + "}?$")
            return regex.test(value)
        },
        regexLengthAllowNagative(value, length) {
            var regex = new RegExp("^[-]?[0-9]{0," + length + "}?$")
            return regex.test(value)
        },
        regexIntergerPositive(value) {
            return /^[0-9]?$/.test(value)
        },
        regexFloat(value, original, decimal) {
            var regex = new RegExp("^([-])?([0-9]{0," + original + "})?[.,]{1,1}?([0-9]{1," + decimal + "})?$")
            return regex.test(value)
        },
        regexSingleChar(str) {
            var boolean = false;
            if (str.length == 0) {
                return true;
            }
            if (str.length > 200) {
                return false;
            }
            for (var i = 0; i < str.length; i++) {
                var c = str.charCodeAt(i);
                // Shift_JIS: 0x0 ～ 0x80, 0xa0 , 0xa1 ～ 0xdf , 0xfd ～ 0xff
                // Unicode : 0x0 ～ 0x80, 0xf8f0, 0xff61 ～ 0xff9f, 0xf8f1 ～ 0xf8f3
                if ((c >= 0x0 && c < 0x81) || (c == 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
                    boolean = true;
                } else {
                    boolean = false;
                    return boolean;
                }
            }
            return boolean;
        },
        setOccurDateDefault() {
            var hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            var dataTable = hot.getSourceData();
            var columnSetting = hot.getSettings().__proto__.columns;
            for (let i = 0; i < dataTable.length; i++) {
                if (!hot.isEmptyRow(i)) {
                    //Undisable [登録]
                    this.isDisabled = false;

                    //ハンドル発生日
                    const occurDate = dataTable[i].occurDate;
                    if (!occurDate) {
                        const dateAPI = this.yearMonthList[0].yearMonthCd; //YYYYMM
                        const lastDayOfMonth = moment(dateAPI + '01', 'YYYYMMDD').add(1, 'months').subtract(1, 'days').format('YYYY/MM/DD');
                        dataTable[i].occurDate = lastDayOfMonth;
                        hot.setCellMeta(i, hot.propToCol('occurDate'), 'valid', true);
                    }
                }else {
                    columnSetting.forEach((values, index) => {
                        hot.setCellMeta(i, index, 'valid',true);
                    })
                }
            }
            if(hot.countEmptyRows() == dataTable.length){
                //Undisable [登録]
                this.isDisabled = true;
            }
            hot.render();
        },
        conditionTranfer(changes) {
            var hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            var index = 0;
            changes.forEach(values => {
                if (values[1] == 'registerPattern') {
                    var self = this;
                    var tempId = values[3].split(':')[0];
                    this.colIndexDel = hot.countCols();
                    var validatorData = this.profitRegistList;
                    this.rowsChangesList.push({"row":values[0] + index, "id": tempId});
                    validatorData.forEach(value => {
                        if (!self.isAddRow && (values[2] == undefined || values[2].split(':')[0] != values[3].split(':')[0]) && tempId == value.cd && value.pair == 1) {
                            this.rowsAdd.push(values[0] + (index + 1));
                            this.rowsChangesList.push({"row":values[0] + (index + 1), "id": tempId});
                            index++;

                        } else if (values[2] != undefined && self.getPair(values[2].split(':')[0]) == 1 && values[2].split(':')[0] != values[3].split(':')[0] && tempId == value.cd) {
                            hot.alter("remove_row", values[0] + 1);
                        }
                    });
                }
            })
        },
        createRowAdd() {
            this.rowsAdd.forEach(values =>{
                var hot = this.$refs.myTable.$refs.hotTable.hotInstance;
                hot.alter('insert_row', values, 1);
                const data = hot.getSourceData();
                var colReadOnly = ['registerPattern', 'occurDate', 'makerCd', 'itemCd', 'profitAmount'];
                colReadOnly.forEach(element => {
                    hot.setCellMeta(values, hot.propToCol(element), 'readOnly', true);
                    var className = hot.getCellMeta(values, hot.propToCol(element)).className;
                    hot.setCellMeta(values, hot.propToCol(element), 'className', className + ' readOnly');
                    var dataCell = hot.getDataAtCell(values - 1, hot.propToCol(element));

                    if (element == 'profitAmount') {
                        if (dataCell == null) {
                            data[values][element] = dataCell;
                            // hot.setDataAtCell(values, hot.propToCol(element), dataCell);
                        } else {
                            data[values][element] = dataCell * -1;
                            // hot.setDataAtCell(values, hot.propToCol(element), dataCell * -1);
                        }
                    } else {
                        data[values][element] = dataCell;
                        // hot.setDataAtCell(values, hot.propToCol(element), dataCell);
                    }
                });
            });
            this.rowsAdd = [];
        },
        setRegex(col, value, values) {
            var colName = this.$refs.myTable.$refs.hotTable.hotInstance.colToProp(col);
            var self = this;
            var boolean = false;
            switch (colName) {
                case 'occurDate': {
                    if (!self.regexDate(value, 0, col)) {
                        values[3] = '';
                    }
                    break;
                }
                case 'makerCd': {
                    values[3] = this.setFormatValue(value, 6);
                    break;
                }
                case 'departmentCd': {
                    values[3] = this.setFormatValue(value, 8);
                    break;
                }
                case 'warehouseCd': {
                    values[3] = this.setFormatValue(value, 8);
                    break;
                }
                case 'customerCd': {
                    values[3] = this.setFormatValue(value, 8);
                    break;
                }
                case 'itemCd': {
                    values[3] = this.setFormatValue(value, 12);
                    break;
                }
                case 'irisu': {
                    if (self.regexLength(value, 5)) {
                        boolean = true;
                    }
                    if(!boolean){
                        values[3] = "";
                    }
                    break;
                }
                case 'tatene': {
                    if (self.regexLength(value, 8)) {
                        boolean = true;
                    }
                    if (self.regexFloat(value, 8, 2)) {
                        boolean = true;
                    }
                    if(!boolean){
                        values[3] = "";
                    }
                    break;
                }
                case 'purchasePrice': {
                    if (self.regexLength(value, 8)) {
                        boolean = true;
                    }
                    if (self.regexFloat(value, 8, 2)) {
                        boolean = true;
                    }
                    if(!boolean){
                        values[3] = "";
                    }
                    break;
                }
                case 'bowlNumber': {
                    if (self.regexLength(value, 9)) {
                        boolean = true;
                    }
                    if(!boolean){
                        values[3] = "";
                    }
                    break;
                }
                case 'tantoUserCd': {
                    values[3] = this.setFormatValue(value, 8);
                    break;
                }
                case 'profitAmount': {
                    if (self.regexLengthAllowNagative(value, 11)) {
                        boolean = true;
                    }
                    if(!boolean){
                        values[3] = "";
                    }
                    break;
                }
            }
        },
        setFormatValue(value, length){
            var rex = /[\uFF10-\uFF19]/g;
            var str = value;
            str = str.replace(rex, function(ch) {
                return String.fromCharCode(ch.charCodeAt(0) - 65248);
            });
            if (str.length > length) {
                str = str.substring(0,length);
            }
           return str;
        }
    },
}
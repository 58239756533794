import {common} from '../api/index'
import store from '../store/index';
import utils from '../mixins/utils'

export default (from, to, next) => {

  let getMaster = false;

  // 認可コードの保存
  // 「?code=xxxxxx」をCookieに保存
  let hash = location.search;
  if (hash) {
    hash = hash.slice(1);
    hash.split("&").forEach(value => {
      const hashList = value.split("=");
      if (hashList[0] === 'code') {
        document.cookie = `${hashList[0]}=${hashList[1]}; path=/; domain=${process.env.VUE_APP_DOMAIN};max-age=7;`;
        //codeがあれば初期化
        getMaster = true;
        
      }
    });
  }

  store.commit('setLoading', true);
  store.commit('setVendor', '');
  store.commit('setVendorList', []);
  store.commit('setCenter', '');
  store.commit('setCenterList', []);
  store.commit('setCustomer', '');
  store.commit('setCustomerList', []);
  store.commit('setBeforeCustomer', '');
  store.commit('setBeforeCustomerList', []);
  store.commit('setUser', '');
  store.commit('setUserList', []);
  store.commit('setExportUser', '');
  store.commit('setExportUserList', []);
  store.commit('setRegistrationUser', '');
  store.commit('setRegistrationUserList', []);
  store.commit('setMaker', '');
  store.commit('setMakerList', []);
  // VVC 20210311
  store.commit('setMaster', {});
  store.commit('setMasterList', {});

  //ローカルストレージで空のものがある場合、初回APIを実行
  if(!utils.methods.getStorage('yearMonthList').length || !utils.methods.getStorage('userList').length || !utils.methods.getStorage('vendorList').length
  || !utils.methods.getStorage('lastYearMonthList').length || !utils.methods.getStorage('branchList').length || !utils.methods.getStorage('centerList').length
  || !utils.methods.getStorage('profitSearchList').length || !utils.methods.getStorage('profitRegistList').length
  || !utils.methods.getStorage('taxList').length || !utils.methods.getStorage('requestTypeList').length
  ){
    getMaster = true;
  }

  //ログインユーザーを取れてない場合、trueにして、ログイン画面へ遷移
  if(process.env.NODE_ENV !=='local' && store.state.userId == '000000'){
    getMaster = true;
  }
  //ローカルストレージデータありで、cookieもある場合はinit実行しない
  if(!getMaster){
    return next();
  }
  

  common.init({getMaster})
    .then((response) => {
      console.log(response);
      store.commit('setLoading', false);
      store.commit('setUserId', response.data.result.user.id);
      store.commit('setUserName', response.data.result.user.name);

      if (getMaster) {
        utils.methods.setStorage('userList', response.data.result.userList);
        utils.methods.setStorage('vendorList', response.data.result.vendorList);
        // VVC 20210311
        utils.methods.setStorage('yearMonthList', response.data.result.yearMonthList);
        utils.methods.setStorage('lastYearMonthList', response.data.result.lastYearMonthList);
        utils.methods.setStorage('branchList', response.data.result.branchList);
        utils.methods.setStorage('centerList', response.data.result.centerList);
        utils.methods.setStorage('profitSearchList', response.data.result.profitSearchList);
        utils.methods.setStorage('profitRegistList', response.data.result.profitRegistList);
        utils.methods.setStorage('taxList', response.data.result.taxList);
        utils.methods.setStorage('requestTypeList', response.data.result.requestTypeList);
      }

      next();
    })
    .catch((e) => {
      console.log(e);
      store.commit('setLoading', false);
      if (utils.methods.getProperty(e, 'response.status') && e.response.status >= 400 && e.response.status <= 499) {
        window.location.href = process.env.VUE_APP_LOGIN_URL + window.location.search;
      } else {
        next('/error');
      }
    });
  
}
const MSG_NULL = 'は必須入力です'
const MSG_SEARCH = 'のコードがマスタに存在しません。存在するコードを入力してください。'
const MSG_LENGTH = '文字以内で入力してください'
const MSG_NUMBER = '桁以内で入力してください'
const MSG_INPUT = '納価%、建値%はいずれか一つだけ入力してください'
const MSG_MASTER = 'いずれかの検索条件を指定してください'
const MSG_BYTE = 'は半角で入力してください'
let i = 0;

export default {
  NULL: MSG_NULL,
  SEARCH: MSG_SEARCH,
  LENGTH: MSG_LENGTH,
  NUMBER: MSG_NUMBER,
  INPUT: MSG_INPUT,
  MASTER: MSG_MASTER,
  BYTE: MSG_BYTE,
  // 特伝登録編集画面
  SPECIAL: {
    requestMaker: {
      maxLength: 6,
      colNo: i++,
      colName: '請求先メーカーCD',
    },
    itemMaker: {
      maxLength: 6,
      colNo: i++,
      colName: '商品メーカーCD',
    },
    department: {
      maxLength: 8,
      colNo: i++,
      colName: '支店'
    },
    warehouse: {
      maxLength: 8,
      colNo: i++,
      colName: 'センター'
    },
    occurDate: {
      maxLength: 10,
      colNo: i++,
      colName: '発生日'
    },
    customer: {
      maxLength: 8,
      colNo: i++,
      colName: '得意先'
    },
    tantoUser: {
      maxLength: 8,
      colNo: i++,
      colName: '担当'
    },
    item: {
      maxLength: 12,
      colNo: i++,
      colName: '商品CD'
    },
    capacity1: {
      maxLength: 5,
      colNo: i++,
      colName: '容量1',
      validator:{
        null: true,
        maxLength: 2,
      }
    },
    tatene: {
      maxLength: 8,
      colNo: i++,
      colName: '建値',
      validator:{
        null: true,
        maxLength: 2,
      }
    },
    bowlNumber: {
      maxLength: 9,
      colNo: i++,
      colName: 'ボール'
    },
    tatenteAmount: {
      maxLength: 8,
      colNo: i++,
      colName: '建値金額'
    },
    noka: {
      maxLength: 8,
      colNo: i++,
      colName: '納価'
    },
    nokaPercent: {
      maxLength: 4,
      colNo: i++,
      colName: '納価%'
    },
    tatenePercent: {
      maxLength: 4,
      colNo: i++,
      colName: '建値%'
    },
    conditionAt: {
      maxLength: 9,
      colNo: i++,
      colName: '条件@'
    },
    billingAmount: {
      maxLength: 9,
      colNo: i++,
      colName: '請求金額'
    },
    comment: {
      maxLength: 30,
      colNo: i++,
      colName: 'コメント'
    },
    taxRate: {
      maxLength: null,
      colNo: i++,
      colName: '税率'
    },
    requestType: {
      maxLength: null,
      colNo: i++,
      colName: '種別'
    },
  },
  // 特伝登録編集画面
  PROFIT: {
    registerPattern: {
      colName: 'パターン',
      validator:{
        null: true,
      }
    },
    occurDate: {
      colName: '発生日',
    },
    makerCd: {
      colName: 'メーカー',
    },
    departmentCd: {
      colName: '支店',
    },
    warehouseCd: {
      colName: 'センター',
    },
    customerCd: {
      colName: '得意先',
    },
    itemCd: {
      colName: '商品',
    },
    irisu: {
      colName: '入数'
    },
    tatene: {
      colName: '建値',
    },
    purchasePrice: {
      colName: '仕入額',
    },
    bowlNumber: {
      colName: 'ボール'
    },
    tantoUserCd: {
      colName: '担当者'
    },
    profitAmount: {
      colName: '黒修正金額'
    },
    comment: {
      colName: 'コメント'
    }
  },
}
import { render, staticRenderFns } from "./template.html?vue&type=template&id=154620a4&"
import script from "./script.js?vue&type=script&lang=js&"
export * from "./script.js?vue&type=script&lang=js&"
import style0 from "../../../../node_modules/handsontable/dist/handsontable.full.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\CFX\\01_checksheets\\view\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('154620a4')) {
      api.createRecord('154620a4', component.options)
    } else {
      api.reload('154620a4', component.options)
    }
    module.hot.accept("./template.html?vue&type=template&id=154620a4&", function () {
      api.rerender('154620a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/table/Handsontable/Handsontable.vue"
export default component.exports
import HandsontableWrapper from "../../components/table/Handsontable/Handsontable.vue";
import { common, special } from '../../api/index';
import moment from 'moment';
import DialogType from '../../data/constant/dialog-type'
import MessageError from '../../data/constant/message-error'
import Handsontable from "handsontable";

export default {
    name: 'SpecialEdit',
    title: '特伝登録編集',

    components: {
        HandsontableWrapper,
    },

    data: function () {
        return {
            moveFromSearch: this.$route.query.move_from_search,
            occurDateFlg: true,
            taxList: [],
            requestTypeList: [],
            table: { data: [] },
            oldData:[],
            yearMonthList: [],
            yearMonthName: '',
            isSearchModalVisible: false,

            colIndexDel: 0,
            rowCount: 0,
            lastChange: null,
            tableSettings: {
                colHeaders: ['請求先メーカーCD', '商品メーカーCD', '支店', 'センター', '発生日', '得意先', '担当者', '商品CD', '容量1',
                    '建値', 'ボール', '建値金額', '納価', '納価%', '建値%', '条件＠', '請求金額', 'コメント', '税率', '種別', '削除'],
                colWidths: [102, 102, 70, 70, 76, 150, 82, 140, 60, 60, 60, 60, 60, 65, 70, 85, 90, 180, 65, 100, 40],
                manualColumnResize: true,
                cells: this.cells,
                afterChange: this.afterChange,
                afterOnCellMouseDown: this.afterOnCellMouseDown,
                afterBeginEditing: this.afterBeginEditing,
                // beforeChangeRender: this.beforeChangeRender,
                afterDocumentKeyDown: this.afterDocumentKeyDown,
            },
            exportSetting: {
                fileType: '1',
                inputFlg: false
            },
            isDisabled: true,
            tmpDepartment: '',
            endEdit: false,
            commentEror: false,
            commentErorRow: 0,
            commentErorCol: 0,
        }
    },

    created() {
        this.$store.commit('setLoading', true);
        try {
            this.yearMonthList = this.getStorage('lastYearMonthList') || [];
            this.yearMonthName = this.yearMonthList.length > 0 ? this.yearMonthList[0].yearMonthName : '';
            this.taxList = this.getStorage('taxList') || [];
            this.requestTypeList = this.getStorage('requestTypeList') || [];
        } catch (e) {
            console.log('error', e);
            this.yearMonthList = [];
        }
        this.$store.commit('setLoading', false);
    },

    mounted() {
        const setting = {
            columns: [
                { data: "requestMakerCd", type: "text", className: "text-nowrap htLeft", readOnly: false, api: "maker", renderer: this.$refs.myTable.colRenderer },
                { data: "itemMakerCd", type: "text", className: "text-nowrap htLeft", readOnly: false, api: "maker", renderer: this.$refs.myTable.colRenderer },
                { data: "departmentCd", type: "text", className: "text-nowrap htLeft", readOnly: false, api: "branch", renderer: this.$refs.myTable.colRenderer },
                { data: "warehouseCd", type: "text", className: "text-nowrap htLeft", readOnly: false, api: "center", renderer: this.$refs.myTable.colRenderer },
                {
                    data: "occurDate", type: "date", className: "text-nowrap htRight", readOnly: false,
                    dateFormat: 'YYYY/MM/DD',
                    correctFormat: true,
                    defaultDate: '01/01/1900',
                    // datePicker additional options (see https://github.com/dbushell/Pikaday#configuration)
                    datePickerConfig: {
                        showDaysInNextAndPreviousMonths: true,
                        enableSelectionDaysInNextAndPreviousMonths: true,
                        // First day of the week (0: Sunday, 1: Monday, etc)
                        firstDay: 0,
                        showWeekNumber: true,
                        numberOfMonths: 1,
                        editor: false
                        // minDate: new Date(moment().subtract(12, 'months').format('YYYY/MM/DD')),
                        // maxDate: moment().toDate(),
                    }
                },
                { data: "customerCd", type: "text", className: "text-nowrap htLeft", readOnly: false, api: "customer", renderer: this.$refs.myTable.colRenderer },
                { data: "tantoUserCd", type: "text", className: "text-nowrap htLeft", readOnly: false, api: "tanto", renderer: this.$refs.myTable.colRenderer },
                { data: "itemCd", type: "text", className: "text-nowrap htLeft", readOnly: false, api: "item", renderer: this.$refs.myTable.colRenderer },
                { data: "capacity1", type: "numeric", className: "text-nowrap htRight", readOnly: false, numericFormat: { pattern: '0,0' } },
                { data: "tatene", type: "numeric", className: "text-nowrap htRight", readOnly: false, numericFormat: { pattern: '0,0.00' } },
                { data: "bowlNumber", type: "numeric", className: "text-nowrap htRight", readOnly: false, numericFormat: { pattern: '0,0' } },
                { data: "tateneAmount", type: "numeric", className: "text-nowrap htRight", readOnly: false, numericFormat: { pattern: '0,0.00' } },
                { data: "noka", type: "numeric", className: "text-nowrap htRight", readOnly: false, numericFormat: { pattern: '0,0.00' } },
                { data: "nokaPercent", type: "numeric", className: "text-nowrap htRight", readOnly: false, numericFormat: { pattern: '0,0.00' } },
                { data: "tatenePercent", type: "numeric", className: "text-nowrap htRight", readOnly: false, numericFormat: { pattern: '0,0.00' } },
                { data: "conditionAt", type: "numeric", className: "text-nowrap htRight", readOnly: false, numericFormat: { pattern: '0,0.00' } },
                { data: "billingAmount", type: "numeric", className: "text-nowrap htRight", numericFormat: { pattern: '0,0' }, readOnly: false },
                { data: "comment", type: "text", className: "text-nowrap htLeft", readOnly: false },
                { data: "taxRate", type: "dropdown", className: " text-nowrap htLeft", strict: true, readOnly: false, allowInvalid: false },
                { data: "requestType", type: "dropdown", className: " text-nowrap htLeft", strict: true, readOnly: false, allowInvalid: false },
                { data: "delete", renderer: this.deleteButton, readOnly: true },
            ]
        };
        this.$refs.myTable.$refs.hotTable.hotInstance.updateSettings(setting, false);
        this.search();
    },

    methods: {
        /**
         * 検索
         * */
        search() {
            const hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            var tokudenIdList = this.$store.state.tokudenIdList;
            console.log(tokudenIdList);
            if (this.moveFromSearch) {
                this.$store.commit('setLoading', true);
                const request = {
                    tokudenId: tokudenIdList
                };
                special.getEditList(request)
                    .then((response) => {
                        console.log(response);
                        var rs = response.data.result.editList;
                        for (let i = 0; i < response.data.result.editList.length; i++) {
                            const element = response.data.result.editList[i];
                            rs[i].occurDate = element.occurDate ? moment(element.occurDate).format('YYYY/MM/DD') : '';
                            rs[i].customerCd = element.customerHeadCd && element.customerBranchCd ? element.customerHeadCd + element.customerBranchCd : '';
                            rs[i].capacity1 = element.capacity1 ? parseInt(element.capacity1) : '';
                            rs[i].tatene = element.tatene ? parseFloat(element.tatene) : '';
                            rs[i].bowlNumber = element.bowlNumber ? parseFloat(element.bowlNumber) : '';
                            rs[i].tateneAmount = element.tateneAmount ? parseFloat(element.tateneAmount) : '';
                            rs[i].noka = element.noka ? parseFloat(element.noka) : '';
                            rs[i].nokaPercent = element.nokaPercent ? parseFloat(element.nokaPercent) : '';
                            rs[i].tatenePercent = element.tatenePercent ? parseFloat(element.tatenePercent) : '';
                            rs[i].conditionAt = element.conditionAt ? parseFloat(element.conditionAt) : '';
                            rs[i].billingAmount = element.billingAmount ? parseInt(element.billingAmount) : '';
                            if (this.taxList.length > 0) {
                                this.taxList.forEach(value =>{
                                    if (value.cd === element.taxRate ||
                                        value.name === element.taxRate ||
                                        `${value.cd}:${value.name}` === element.taxRate) {
                                        rs[i].taxRate = `${value.cd}:${value.name}`;
                                    }
                                });
                            }
                            if (this.requestTypeList.length > 0) {
                                this.requestTypeList.forEach(value =>{
                                    if (value.cd === element.requestType ||
                                        value.name === element.requestType ||
                                        `${value.cd}:${value.name}` === element.requestType) {
                                        rs[i].requestType = `${value.cd}:${value.name}`;
                                    }
                                });
                            }
                        }
                        this.oldData = [];
                        rs.forEach(value=>{
                            const temp = JSON.parse(JSON.stringify(value));
                            this.oldData.push(temp);
                        })
                        this.setCdNameExist(rs);
                        //列を非表示にする[削除]
                        hot.updateSettings({
                            colWidths: [110, 110, 55, 90, 100, 180, 80, 60, 60, 60, 60, 60, 60, 65, 70, 85, 90, 180, 65, 100, 0.1]
                        });

                        //テーブルにデータをロードします
                        hot.loadData(rs || []);
                        this.rowCount = hot.countRows();

                        this.$emit('displayErrorMessage', response);
                        this.$store.commit('setLoading', false);
                    })
                    .catch((e) => {
                        console.log('error', e);
                        this.rowCount = 0;
                        hot.loadData([]);
                        this.$emit('displayErrorMessage', e);
                        this.$store.commit('setLoading', false);
                    });
            } else {
                this.$store.commit('setLoading', true);
                for (let i = 0; i < 10; i++) {
                    this.table.data.push({});
                    this.oldData.push({});
                }
                hot.loadData(this.table.data || []);
                this.rowCount = hot.countRows();
                this.$store.commit('setLoading', false);
            }

        },
        addRow() {
            // this.occurDateFlg = false;
            this.$refs.myTable.$refs.hotTable.hotInstance.alter('insert_row', this.$refs.myTable.$refs.hotTable.hotInstance.countRows(), 1);
        },
        searchMakerAPI: async function (data) {
            let rs = false;
            const API_NAME = 'maker';
            let apiDatas = [];
            if(/\D/.test(data)){
                return false;
            }
            let request = {searchKbn: 1, type: API_NAME, searches:[data]}

            await common.master(request)
            .then((response) => {
                apiDatas = response.data.result.summaryList;
                if(apiDatas.length > 0){
                    rs = true;
                }
            })
            .catch((e) => {
                console.log('error', e);
                this.$emit('displayErrorMessage', e);
            });
            return rs;
        },
        afterChange: async function (changes, source) {
            this.lastChange = changes;

            //ハンドル【請求金額】
            if (changes) {
                //console.log(changes);
                console.log(source);
                const hot = this.$refs.myTable.$refs.hotTable.hotInstance;
                const columnSetting = this.$refs.myTable.$refs.hotTable.hotInstance.getSettings().__proto__.columns;
                let r = changes[0][0]; //row index
                let c = hot.propToCol(changes[0][1]); //col index

                this.setOccurDateDefault();

                if (changes.length > 1) {
                    for (let i = 0; i < changes.length; i++) {
                        const element = changes[i];
                        if (hot.isEmptyRow(element[0])) {
                            columnSetting.forEach((values, index) => {
                                hot.setCellMeta(element[0], index, 'valid', true);
                            })
                        }
                    }
                    hot.render()
                } else {
                    if (hot.isEmptyRow(r)) {
                        columnSetting.forEach((values, index) => {
                            hot.setCellMeta(r, index, 'valid', true);
                        })
                    }
                    hot.render()
                }

                //Undisable [登録]
                if (hot.countEmptyRows() < hot.getSourceData().length) {
                    this.isDisabled = false;
                } else {
                    this.isDisabled = true;
                }
                
                //ハンドル商品メーカーCD
                const requestMaker = hot.getDataAtCell(r, MessageError.SPECIAL.requestMaker.colNo);
                const itemMaker = hot.getDataAtCell(r, MessageError.SPECIAL.itemMaker.colNo);
                const listName = this.$refs.myTable.listName;
                const maker = this.$refs.myTable.getNameExist(listName, 'maker');
                let existAPI = false;
                if (requestMaker && !itemMaker && c === MessageError.SPECIAL.requestMaker.colNo) {
                    if (!maker.find(o => o[0] === requestMaker)) {
                        existAPI = await this.searchMakerAPI(requestMaker)
                        if (existAPI) {
                            hot.setDataAtCell(r, MessageError.SPECIAL.itemMaker.colNo, requestMaker);
                        }
                    } else {
                        hot.setDataAtCell(r, MessageError.SPECIAL.itemMaker.colNo, requestMaker);
                    }
                }

                // //ハンドル発生日
                // const occurDate = hot.getDataAtCell(r, MessageError.SPECIAL.occurDate.colNo);
                // if (!occurDate && c !== MessageError.SPECIAL.occurDate.colNo && !hot.isEmptyRow(r) && this.occurDateFlg) {
                //     const dateAPI = this.yearMonthList[0].yearMonthCd; //YYYYMM
                //     const lastDayOfMonth = moment(dateAPI + '01', 'YYYYMMDD').add(1, 'months').subtract(1, 'days').format('YYYY/MM/DD');
                //     hot.setDataAtCell(r, MessageError.SPECIAL.occurDate.colNo, lastDayOfMonth);
                // }
                // this.occurDateFlg = true;

                //ハンドル請求金額
                //一括ペーストもあるのでループする 行の配列、列の配列を作成する
                let rowarraytmp = [];
                let colarraytmp = [];
                for (let i = 0; i < changes.length; i++) {
                    rowarraytmp.push(changes[i][0]);
                    colarraytmp.push(hot.propToCol(changes[i][1]));
                }
                const rowarray = Array.from(new Set(rowarraytmp))
                const colarray = Array.from(new Set(colarraytmp))

                //列に再計算対象があるか
                let isCalc = false;
                const arrCalc = [
                    MessageError.SPECIAL.capacity1.colNo,
                    MessageError.SPECIAL.tatene.colNo,
                    MessageError.SPECIAL.bowlNumber.colNo,
                    MessageError.SPECIAL.noka.colNo,
                    MessageError.SPECIAL.nokaPercent.colNo,
                    MessageError.SPECIAL.tatenePercent.colNo,
                    MessageError.SPECIAL.conditionAt.colNo
                ];
                for (let i = 0; i < colarray.length; i++) {
                    c = colarray[i]; //col index
                    if (arrCalc.includes(c)) {
                        isCalc= true;
                        break;
                    }
                }

                //行ごとに再計算
                for (let i = 0; i < rowarray.length; i++) {
                    r = rowarray[i]; //row index

                    if (hot.getDataAtCell(r, MessageError.SPECIAL.tatenePercent.colNo) ||
                        hot.getDataAtCell(r, MessageError.SPECIAL.nokaPercent.colNo) ||
                        hot.getDataAtCell(r, MessageError.SPECIAL.conditionAt.colNo)) {
                        
                        if (isCalc) {
                            const capacity1 = hot.getDataAtCell(r, MessageError.SPECIAL.capacity1.colNo) ?
                                parseInt(hot.getDataAtCell(r, MessageError.SPECIAL.capacity1.colNo)) : 0;
                            const bowlNumber = hot.getDataAtCell(r, MessageError.SPECIAL.bowlNumber.colNo) ?
                                parseInt(hot.getDataAtCell(r, MessageError.SPECIAL.bowlNumber.colNo)) : 0;
                            const conditionAt = hot.getDataAtCell(r, MessageError.SPECIAL.conditionAt.colNo) ?
                                parseFloat(hot.getDataAtCell(r, MessageError.SPECIAL.conditionAt.colNo).toFixed(2)) : 0;
                            const tatene = hot.getDataAtCell(r, MessageError.SPECIAL.tatene.colNo) ?
                                parseFloat(hot.getDataAtCell(r, MessageError.SPECIAL.tatene.colNo).toFixed(2)) : 0;
                            const tatenePercent = hot.getDataAtCell(r, MessageError.SPECIAL.tatenePercent.colNo) ?
                                parseFloat(hot.getDataAtCell(r, MessageError.SPECIAL.tatenePercent.colNo).toFixed(2)) : 0;
                            const noka = hot.getDataAtCell(r, MessageError.SPECIAL.noka.colNo) ?
                                parseFloat(hot.getDataAtCell(r, MessageError.SPECIAL.noka.colNo).toFixed(2)) : 0;
                            const nokaPercent = hot.getDataAtCell(r, MessageError.SPECIAL.nokaPercent.colNo) ?
                                parseFloat(hot.getDataAtCell(r, MessageError.SPECIAL.nokaPercent.colNo).toFixed(2)) : 0;
                            //20210628 それぞれで切り上げ
                            const rs = Math.ceil(capacity1*bowlNumber*conditionAt) +
                                Math.ceil(capacity1*bowlNumber*tatene*tatenePercent/100) +
                                Math.ceil(capacity1*bowlNumber*noka*nokaPercent/100);
                             
                            //データを設定し、readOnlyをtrueに設定します
                            hot.setDataAtCell(r, MessageError.SPECIAL.billingAmount.colNo, rs);
                            hot.setCellMeta(r, MessageError.SPECIAL.billingAmount.colNo, 'readOnly', true);
                        }
                    } else {
                        //readOnlyをfalseに設定します
                        hot.setCellMeta(r, MessageError.SPECIAL.billingAmount.colNo, 'readOnly', false);
                    }
                }
            }
        },
        afterOnCellMouseDown: function (e, coords) {
            //削除行を処理する
            this.colIndexDel = this.$refs.myTable.$refs.hotTable.hotInstance.countCols();
            if (coords.col === this.colIndexDel - 1) {
                this.$refs.myTable.$refs.hotTable.hotInstance.alter("remove_row", coords.row);
            }
            
            //最終行をクリックすると行追加が行われる。
            const MAX_ROW = 999;
            var n = this.$refs.myTable.$refs.hotTable.hotInstance.countRows() - 1;
            var isEdit = this.moveFromSearch;
            if (coords.row === n++ && coords.col !== this.colIndexDel - 1 && n < MAX_ROW - 1 && !isEdit && this.lastChange) {
                this.occurDateFlg = false;
                this.$refs.myTable.$refs.hotTable.hotInstance.alter('insert_row', n, 1);
            }
            if(this.commentEror){
                this.$refs.myTable.$refs.hotTable.hotInstance.deselectCell();
            }
            this.lastChange = null;
        },
        cancel() {
            this.$emit('showConfirmModal', {
                type: DialogType.INFO,
                title: '確認',
                message: '入力内容を破棄します。よろしいですか？',
                confirm: () => { 
                    const oldData = new Array();
                    this.oldData.forEach(value=>{
                        const data = JSON.parse(JSON.stringify(value));
                        oldData.push(data);
                    })
                    this.$refs.myTable.$refs.hotTable.hotInstance.loadData(oldData);
                    this.isDisabled = true;
                }
            });
        },
        cells(row, cols, prop) {
            const cellProperties = {};
            switch (prop) {
                case 'taxRate':
                    {
                        if (this.taxList.length != undefined && this.taxList.length > 0) {
                            cellProperties.source = this.taxList.map(value => value.cd + ':' + value.name);
                        }else{
                            cellProperties.readOnly = true;
                        }
                    }
                    break;
                case 'requestType':
                    {
                        if (this.requestTypeList.length != undefined && this.requestTypeList.length > 0) {
                            cellProperties.source = this.requestTypeList.map(value => value.cd + ':' + value.name);
                        }else{
                            cellProperties.readOnly = true;
                        }
                    }
                    break;
                case 'billingAmount':
                    {
                        const hot = this.$refs.myTable.$refs.hotTable.hotInstance;
                        const nokaPercent = hot.getDataAtCell(row, MessageError.SPECIAL.nokaPercent.colNo);
                        const tatenePercent = hot.getDataAtCell(row, MessageError.SPECIAL.tatenePercent.colNo);
                        const conditionAt = hot.getDataAtCell(row, MessageError.SPECIAL.conditionAt.colNo);
                        if (nokaPercent || tatenePercent || conditionAt) {
                            cellProperties.readOnly = true;
                        }
                    }
                    break;
            }
            return cellProperties;
        },
        regist() {
            const hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            let dataCheck = hot.getSourceData();

            //SET 商品メーカーCD
            for (let i = 0; i < dataCheck.length; i++) {
                if (hot.isEmptyRow(i)) {
                    continue;
                }
                const element = dataCheck[i];
                const requestMakerCd = element.requestMakerCd;
                const itemMakerCd = element.itemMakerCd;
                if (requestMakerCd && !itemMakerCd) {
                    dataCheck[i].itemMakerCd = requestMakerCd;
                }
            }

            if (this.checkValidate()) {
                return;
            }

            this.$store.commit('setLoading', true);
            let data = hot.getSourceData();
            let dataTable = JSON.parse(JSON.stringify(data));
            dataTable = dataTable.filter(item => item.requestMakerCd);
            if (dataTable.length === 0) {
                //Disabled [登録]
                this.isDisabled = true;
                this.$store.commit('setLoading', false);
                return;
            }
            console.log(dataTable)
            dataTable.forEach(item => {
                if (item.delete) {
                    delete item.delete;
                }

                delete item.customer;
                item.occurDate = item.occurDate ? moment(item.occurDate).format('YYYYMMDD') : null;
                item.tatene = item.tatene ? parseFloat(item.tatene.toFixed(2)) : null;
                item.noka = item.noka ? parseFloat(item.noka.toFixed(2)) : null;
                item.nokaPercent = item.nokaPercent ? parseFloat(item.nokaPercent.toFixed(2)) : null;
                item.tatenePercent = item.tatenePercent ? parseFloat(item.tatenePercent.toFixed(2)) : null;
                item.conditionAt = item.conditionAt ? parseFloat(item.conditionAt.toFixed(2)) : null;
                item.taxRate = item.taxRate.split(':')[0] || '';
                item.requestType = item.requestType.split(':')[0] || '';
            })
            const request = {
                registerList: dataTable
            };
            console.log(request);
            special.register(request)
                .then((response) => {
                    console.log(response);
                    this.$emit('showSimpleModal', {
                        type: DialogType.SUCCESS,
                        title: '確認',
                        message: response.data.message,
                        closeFunc: () => {
                            if (response.data.statusCd === 0) {
                                if (this.moveFromSearch) {
                                    this.endEdit = true;
                                    this.$router.push({ path: '/tkd_search', query: {move_from_edit: true} });
                                } else {
                                    let data = [];
                                    for (let i = 0; i < 10; i++) {
                                        data.push({});
                                    }
                                    hot.loadData(data);
                                    this.isDisabled = true;
                                }
                            }
                        }
                    });
                    this.$store.commit('setLoading', false);
                })
                .catch((e) => {
                    console.log('error', e);
                    this.$emit('displayErrorMessage', e);
                    this.$store.commit('setLoading', false);
                });
        },
        /**
         * 
         */
        checkValidate() {
            let msgError = '';
            const hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            const columnSetting = this.$refs.myTable.$refs.hotTable.hotInstance.getSettings().__proto__.columns;
            const registerList = hot.getSourceData();
            console.log(registerList);

            const listName = this.$refs.myTable.listName;
            const maker = this.$refs.myTable.getNameExist(listName, 'maker');
            const branch = this.$refs.myTable.getNameExist(listName, 'branch');
            const center = this.$refs.myTable.getNameExist(listName, 'center');
            const item = this.$refs.myTable.getNameExist(listName, 'item');
            const tanto = this.$refs.myTable.getNameExist(listName, 'tanto');
            const customer = this.$refs.myTable.getNameExist(listName, 'customer');

            for (let i = 0; i < registerList.length; i++) {
                if (hot.isEmptyRow(i)) {
                    columnSetting.forEach((values, index) => {
                        hot.setCellMeta(i, index, 'validator',(value, callback) => {callback(true)})
                        hot.setCellMeta(i, index, 'valid', true);
                    })
                    continue;
                }
                const element = registerList[i];
                let inputCnt = 0;
                //請求先メーカーCD
                const requestMakerCd = element.requestMakerCd;
                if (!requestMakerCd) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.requestMaker.colNo,
                        MessageError.SPECIAL.requestMaker.colName, null, 'NULL') + '<br>';
                } else if (/\D/.test(requestMakerCd) ||
                        requestMakerCd.length > MessageError.SPECIAL.requestMaker.maxLength) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.requestMaker.colNo,
                        MessageError.SPECIAL.requestMaker.colName,
                        MessageError.SPECIAL.requestMaker.maxLength, 'NUMBER') + '<br>';
                } else if (!maker.find(o => o[0] === requestMakerCd)) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.requestMaker.colNo,
                        MessageError.SPECIAL.requestMaker.colName, null, 'SEARCH') + '<br>';
                }

                //商品メーカーCD
                const itemMakerCd = element.itemMakerCd;
                if (!itemMakerCd) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.itemMaker.colNo,
                        MessageError.SPECIAL.itemMaker.colName, null, 'NULL') + '<br>';
                } else if (/\D/.test(itemMakerCd) ||
                    itemMakerCd.length > MessageError.SPECIAL.itemMaker.maxLength) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.itemMaker.colNo,
                        MessageError.SPECIAL.itemMaker.colName,
                        MessageError.SPECIAL.itemMaker.maxLength, 'NUMBER') + '<br>';
                } else if (!maker.find(o => o[0] === itemMakerCd)){
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.itemMaker.colNo,
                        MessageError.SPECIAL.itemMaker.colName, null, 'SEARCH') + '<br>';
                }

                //支店
                const departmentCd = element.departmentCd;
                if (!departmentCd) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.department.colNo,
                        MessageError.SPECIAL.department.colName, null, 'NULL') + '<br>';
                } else if (/\D/.test(departmentCd) ||
                    departmentCd.length > MessageError.SPECIAL.department.maxLength) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.department.colNo,
                        MessageError.SPECIAL.department.colName,
                        MessageError.SPECIAL.department.maxLength, 'NUMBER') + '<br>';
                } else if (!branch.find(o => o[0] === departmentCd)){
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.department.colNo,
                        MessageError.SPECIAL.department.colName, null, 'SEARCH') + '<br>';
                }

                //センター
                const warehouseCd = element.warehouseCd;
                if (!warehouseCd) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.warehouse.colNo,
                        MessageError.SPECIAL.warehouse.colName, null, 'NULL') + '<br>';
                } else if (/\D/.test(warehouseCd) ||
                        warehouseCd.length > MessageError.SPECIAL.warehouse.maxLength) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.warehouse.colNo,
                        MessageError.SPECIAL.warehouse.colName,
                        MessageError.SPECIAL.warehouse.maxLength, 'NUMBER') + '<br>';
                } else if (!center.find(o => o[0] === departmentCd && o[1] === warehouseCd)){
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.warehouse.colNo,
                        MessageError.SPECIAL.warehouse.colName, null, 'SEARCH') + '<br>';
                }

                //発生日
                const occurDate = element.occurDate;
                if (!occurDate) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.occurDate.colNo,
                        MessageError.SPECIAL.occurDate.colName, null, 'NULL') + '<br>';
                }

                //得意先
                const customerCd = element.customerCd ? element.customerCd : '';
                if (!customerCd) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.customer.colNo,
                        MessageError.SPECIAL.customer.colName, null, 'NULL') + '<br>';
                } else if (customerCd.length > 0 && /\D/.test(customerCd) ||
                            customerCd.length > MessageError.SPECIAL.customer.maxLength) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.customer.colNo,
                        MessageError.SPECIAL.customer.colName,
                        MessageError.SPECIAL.customer.maxLength, 'NUMBER') + '<br>';
                } else if (customerCd.length > 0 && !customer.find(o => o[0] === customerCd)){
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.customer.colNo,
                        MessageError.SPECIAL.customer.colName, null, 'SEARCH') + '<br>';
                }

                //担当
                const tantoUserCd = element.tantoUserCd;
                if (!tantoUserCd) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.tantoUser.colNo,
                        MessageError.SPECIAL.tantoUser.colName, null, 'NULL') + '<br>';
                } else if (/\D/.test(tantoUserCd) || tantoUserCd.length > MessageError.SPECIAL.tantoUser.maxLength) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.tantoUser.colNo,
                        MessageError.SPECIAL.tantoUser.colName,
                        MessageError.SPECIAL.tantoUser.maxLength, 'NUMBER') + '<br>';
                } else if (!tanto.find(o => o[0] === tantoUserCd)){
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.tantoUser.colNo,
                        MessageError.SPECIAL.tantoUser.colName, null, 'SEARCH') + '<br>';
                }

                //商品CD
                const itemCd = element.itemCd ? element.itemCd : '';
                if (itemCd.length > 0 && /\D/.test(itemCd) ||
                        itemCd.length > MessageError.SPECIAL.item.maxLength) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.item.colNo,
                        MessageError.SPECIAL.item.colName,
                        MessageError.SPECIAL.item.maxLength, 'NUMBER') + '<br>';
                } else if (itemCd.length > 0 && !item.find(o => o[0] === itemCd)){
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.item.colNo,
                        MessageError.SPECIAL.item.colName, null, 'SEARCH') + '<br>';
                }
                else if (itemCd.length == 0) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.item.colNo,
                        MessageError.SPECIAL.item.colName, null, 'NULL') + '<br>';
                }

                //容量1
                const capacity1 = element.capacity1 ? element.capacity1 : '';
                if (capacity1.length > 0 && /\D/.test(capacity1) ||
                        capacity1.length > MessageError.SPECIAL.capacity1.maxLength) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.capacity1.colNo,
                        MessageError.SPECIAL.capacity1.colName,
                        MessageError.SPECIAL.capacity1.maxLength, 'NUMBER') + '<br>';
                }

                //建値
                const tatene = element.tatene;
                if (tatene) {
                    if (tatene.toFixed(2) < 0 ||
                            tatene.toFixed().toString().length > MessageError.SPECIAL.tatene.maxLength) {
                        msgError += this.getMessageError(i+1,
                            MessageError.SPECIAL.tatene.colNo,
                            MessageError.SPECIAL.tatene.colName,
                            MessageError.SPECIAL.tatene.maxLength, 'NUMBER') + '<br>';
                    }
                }

                //ボール
                const bowlNumber = element.bowlNumber;
                if (bowlNumber) {
                    if (bowlNumber.toFixed(2) < 0 ||
                            bowlNumber.toFixed().toString().length > MessageError.SPECIAL.bowlNumber.maxLength) {
                        msgError += this.getMessageError(i+1,
                            MessageError.SPECIAL.bowlNumber.colNo,
                            MessageError.SPECIAL.bowlNumber.colName,
                            MessageError.SPECIAL.bowlNumber.maxLength, 'NUMBER') + '<br>';
                    }
                }

                //納価
                const noka = element.noka;
                if (noka) {
                    if (noka.toFixed(2) < 0 ||
                            noka.toFixed().toString().length > MessageError.SPECIAL.noka.maxLength) {
                        msgError += this.getMessageError(i+1,
                            MessageError.SPECIAL.noka.colNo,
                            MessageError.SPECIAL.noka.colName,
                            MessageError.SPECIAL.noka.maxLength, 'NUMBER') + '<br>';
                    }
                }

                //納価%
                const nokaPercent = element.nokaPercent;
                if (nokaPercent) {
                    if (nokaPercent.toFixed(2) < 0 ||
                            nokaPercent.toFixed().toString().length > MessageError.SPECIAL.nokaPercent.maxLength) {
                        msgError += this.getMessageError(i+1,
                            MessageError.SPECIAL.nokaPercent.colNo,
                            MessageError.SPECIAL.nokaPercent.colName,
                            MessageError.SPECIAL.nokaPercent.maxLength, 'NUMBER') + '<br>';
                    } else {
                        //納価%、建値%の中で2つ以上入力されているとエラー
                        inputCnt++;
                    }
                }

                //建値%
                const tatenePercent = element.tatenePercent;
                if (tatenePercent) {
                    //納価%、建値%の中で2つ以上入力されているとエラー
                    inputCnt++;
                }

                //条件＠
                const conditionAt = element.conditionAt;
                if (conditionAt) {
                    if (conditionAt.toFixed(2) < 0 ||
                            conditionAt.toFixed().toString().length > MessageError.SPECIAL.conditionAt.maxLength) {
                        msgError += this.getMessageError(i+1,
                            MessageError.SPECIAL.conditionAt.colNo,
                            MessageError.SPECIAL.conditionAt.colName,
                            MessageError.SPECIAL.conditionAt.maxLength, 'NUMBER') + '<br>';
                    }
                }

                //請求金額
                const billingAmount = element.billingAmount;
                if (!billingAmount) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.billingAmount.colNo,
                        MessageError.SPECIAL.billingAmount.colName,
                        MessageError.SPECIAL.billingAmount.maxLength, 'NULL') + '<br>';
                } else if ( billingAmount.toFixed(2) < 0 ||
                        billingAmount.toFixed().toString().length > MessageError.SPECIAL.billingAmount.maxLength) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.billingAmount.colNo,
                        MessageError.SPECIAL.billingAmount.colName,
                        MessageError.SPECIAL.billingAmount.maxLength, 'NUMBER') + '<br>';
                }

                //コメント
                const comment = element.comment;
                if (comment) {
                    if (!this.regexSingleChar(comment)) {
                        msgError += this.getMessageError(i+1,
                            MessageError.SPECIAL.comment.colNo,
                            MessageError.SPECIAL.comment.colName,
                            null, 'BYTE') + '<br>';
                    } else if (comment.length > MessageError.SPECIAL.comment.maxLength) {
                        msgError += this.getMessageError(i+1,
                            MessageError.SPECIAL.comment.colNo,
                            MessageError.SPECIAL.comment.colName,
                            MessageError.SPECIAL.comment.maxLength, 'LENGTH') + '<br>';
                    }
                }

                //税率
                const taxRate = element.taxRate;
                if (!taxRate) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.taxRate.colNo,
                        MessageError.SPECIAL.taxRate.colName,
                        MessageError.SPECIAL.taxRate.maxLength, 'NULL') + '<br>';
                }

                //種別
                const requestType = element.requestType;
                if (!requestType) {
                    msgError += this.getMessageError(i+1,
                        MessageError.SPECIAL.requestType.colNo,
                        MessageError.SPECIAL.requestType.colName,
                        MessageError.SPECIAL.requestType.maxLength, 'NULL') + '<br>';
                }

                //納価%、建値%の中で2つ以上入力されているとエラー
                if (inputCnt > 1) {
                    msgError += this.getMessageError(i+1, null, null, null, 'INPUT') + '<br>';
                }

                hot.validateRows([i]);
            }
            hot.render();

            if (msgError.length > 0) {
                this.$emit('showSimpleModal', {
                    type: DialogType.NOTICE,
                    title: 'Webからのメッセージ',
                    message: msgError
                });
                return true
            }
            return false;
        },
        getMessageError(row, col, colName, length, typeError) {
            var msg = '';
            switch (typeError) {
                case 'NULL':
                    msg = row + '行目　' + colName + MessageError.NULL;
                    break;
                case 'SEARCH':
                    msg = row + '行目　' + colName + MessageError.SEARCH;
                    break;
                case 'LENGTH':
                    msg = row + '行目　' + colName + 'は' + length + MessageError.LENGTH;
                    break;
                case 'NUMBER':
                    msg = row + '行目　' + colName + 'は' + length + MessageError.NUMBER;
                    break;
                case 'INPUT':
                    msg = row + '行目　' + MessageError.INPUT;
                    break;
                case 'BYTE':
                    msg = row + '行目　' + colName + MessageError.BYTE;
                    break;
                default:
                    break;
            }

            switch (colName) {
                case MessageError.SPECIAL.requestMaker.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validRequestMaker);
                    break;
                case MessageError.SPECIAL.itemMaker.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validItemMaker);
                    break;
                case MessageError.SPECIAL.department.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validDepartment);
                    break;
                case MessageError.SPECIAL.occurDate.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validOccurDate);
                    break;
                case MessageError.SPECIAL.warehouse.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validWarehouse);
                    break;
                case MessageError.SPECIAL.customer.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validCustomer);
                    break;
                case MessageError.SPECIAL.tantoUser.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validTantoUser);
                    break;
                case MessageError.SPECIAL.item.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validItem);
                    break;
                case MessageError.SPECIAL.capacity1.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validCapacity1);
                    break;
                case MessageError.SPECIAL.tatene.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validTatene);
                    break;
                case MessageError.SPECIAL.bowlNumber.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validBowlNumber);
                    break;

                // case MessageError.SPECIAL.tatenteAmount.colName:
                //     this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validItem);
                //     break;
                case MessageError.SPECIAL.noka.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validNoka);
                    break;
                case MessageError.SPECIAL.nokaPercent.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validNokaPercent);
                    break;
                // case MessageError.SPECIAL.tatenePercent.colName:
                //     this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validTantoUser);
                //     break;
                case MessageError.SPECIAL.conditionAt.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validConditionAt);
                    break;
                case MessageError.SPECIAL.billingAmount.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validBillingAmount);
                    break;
                case MessageError.SPECIAL.comment.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validComment);
                    break;
                case MessageError.SPECIAL.taxRate.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validTaxRate);
                    break;
                case MessageError.SPECIAL.requestType.colName:
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(row-1, col, 'validator', this.validRequestType);
                    break;
                default:
                    break;
            }

            return msg;
        },

        //エラーを確認してください
        //請求先メーカーCD
        validRequestMaker: function (value, callback) {
            const listName = this.$refs.myTable.listName;
            const maker = this.$refs.myTable.getNameExist(listName, 'maker');

            if (!value || /\D/.test(value) ||
                    value.length > MessageError.SPECIAL.requestMaker.maxLength ||
                    !maker.find(o => o[0] === value)) {
                callback(false)
            } else {
                callback(true)
            }
        },
        //商品メーカーCD
        validItemMaker: function (value, callback) {
            const listName = this.$refs.myTable.listName;
            const maker = this.$refs.myTable.getNameExist(listName, 'maker');

            if (!value || /\D/.test(value) ||
                    value.length > MessageError.SPECIAL.itemMaker.maxLength ||
                    !maker.find(o => o[0] === value)) {
                callback(false)
            } else {
                callback(true)
            }
        },
        //支店
        validDepartment: function (value, callback) {
            const listName = this.$refs.myTable.listName;
            const branch = this.$refs.myTable.getNameExist(listName, 'branch');
            this.tmpDepartment = value;

            if (!value || /\D/.test(value) ||
                    value.length > MessageError.SPECIAL.department.maxLength ||
                    !branch.find(o => o[0] === value)) {
                callback(false)
            } else {
                callback(true)
            }
        },
        //センター
        validWarehouse: function (value, callback) {
            const listName = this.$refs.myTable.listName;
            const center = this.$refs.myTable.getNameExist(listName, 'center');
            if (!value || /\D/.test(value) ||
                value.length > MessageError.SPECIAL.warehouse.maxLength ||
                !center.find(o => o[0] === this.tmpDepartment && o[1] === value)) {
                this.tmpDepartment = '';
                callback(false)
            } else {
                callback(true)
            }
        },
        //発生日
        validOccurDate: function (value, callback) {
            if (!value) {
                callback(false)
            } else {
                callback(true)
            }
        },
        //得意先
        validCustomer: function (value, callback) {
            const listName = this.$refs.myTable.listName;
            const customer = this.$refs.myTable.getNameExist(listName, 'customer');

            if (!value || /\D/.test(value) ||
                value.length > MessageError.SPECIAL.customer.maxLength ||
                !customer.find(o => o[0] === value)) {
                callback(false)
            } else {
                callback(true)
            }
        },
        //担当
        validTantoUser: function (value, callback) {
            const listName = this.$refs.myTable.listName;
            const tanto = this.$refs.myTable.getNameExist(listName, 'tanto');

            if (!value || (/\D/.test(value) ||
                value.length > MessageError.SPECIAL.tantoUser.maxLength ||
                !tanto.find(o => o[0] === value))) {
                callback(false)
            } else {
                callback(true)
            }
        },
        //商品CD
        validItem: function (value, callback) {
            const listName = this.$refs.myTable.listName;
            const item = this.$refs.myTable.getNameExist(listName, 'item');
            if (value === null) {
                callback(false)
            }
            else if (value && (/\D/.test(value) ||
                value.length > MessageError.SPECIAL.item.maxLength ||
                !item.find(o => o[0] === value))) {
                callback(false)
            } else {
                callback(true)
            }

        },
        //容量1
        validCapacity1: function (value, callback) {
            if (value && (/\D/.test(value) ||
                    value.toString().length > MessageError.SPECIAL.capacity1.maxLength)) {
                callback(false)
            } else {
                callback(true)
            }
        },
        //建値
        validTatene: function (value, callback) {
            if (value) {
                if (parseFloat(value).toFixed(2) < 0 ||
                    parseInt(value).toString().length > MessageError.SPECIAL.tatene.maxLength) {
                    callback(false)
                } else {
                    callback(true)
                }
            }
        },
        //ボール
        validBowlNumber: function (value, callback) {
            if (value) {
                if (parseFloat(value).toFixed(2) < 0 ||
                    parseInt(value).toString().length > MessageError.SPECIAL.bowlNumber.maxLength) {
                    callback(false)
                } else {
                    callback(true)
                }
            }
        },
        //納価
        validNoka: function (value, callback) {
            if (value) {
                if (parseFloat(value).toFixed(2) < 0 ||
                    parseInt(value).toString().length > MessageError.SPECIAL.noka.maxLength) {
                    callback(false)
                } else {
                    callback(true)
                }
            }
        },
        //納価%
        validNokaPercent: function (value, callback) {
            if (value) {
                if (parseFloat(value).toFixed(2) < 0 ||
                    parseInt(value).toString().length > MessageError.SPECIAL.nokaPercent.maxLength) {
                    callback(false)
                } else {
                    callback(true)
                }
            }
        },
        //条件＠
        validConditionAt: function (value, callback) {
            if (value) {
                if (parseFloat(value).toFixed(2) < 0 ||
                    parseInt(value).toString().length > MessageError.SPECIAL.conditionAt.maxLength) {
                    callback(false)
                } else {
                    callback(true)
                }
            }
        },
        //請求金額
        validBillingAmount: function (value, callback) {
            if (value) {
                if (parseFloat(value).toFixed(2) < 0 ||
                    parseInt(value).toString().length > MessageError.SPECIAL.billingAmount.maxLength) {
                    callback(false)
                } else {
                    callback(true)
                }
            } else {
                callback(false)
            }
        },
        //コメント
        validComment: function (value, callback) {
            if (value) {
                if (!this.regexSingleChar(value) || value.length > MessageError.SPECIAL.comment.maxLength) {
                    callback(false)
                } else {
                    callback(true)
                }
            }
        },
        //税率
        validTaxRate: function (value, callback) {
            if (value) {
                callback(true)
            } else {
                callback(false)
            }
        },
        //種別
        validRequestType: function (value, callback) {
            if (value) {
                callback(true)
            } else {
                callback(false)
            }
        },
        setCdNameExist(data){
            data.forEach(element => {
                if(!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName,'maker', element.requestMakerCd)){
                    this.$refs.myTable.listName.push({ ['maker']: [element.requestMakerCd ? element.requestMakerCd : '', element.requestMakerName ? ":" + element.requestMakerName : ''] });
                }
                if(!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName,'maker', element.itemMakerCd)){
                    this.$refs.myTable.listName.push({ ['maker']: [element.itemMakerCd ? element.itemMakerCd : '', element.makerName ? ":" + element.makerName : ''] });
                }
                if(!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName,'branch', element.departmentCd)){
                    this.$refs.myTable.listName.push({ ['branch']: [element.departmentCd ? element.departmentCd : '', element.departmentName ? ":" + element.departmentName : ''] });
                }
                if(!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName,'center', element.departmentCd, element.warehouseCd)){
                    this.$refs.myTable.listName.push({ ['center']: [element.departmentCd ? element.departmentCd : '',
                                                                    element.warehouseCd ? element.warehouseCd : '',
                                                                    element.warehouseName ? ":" + element.warehouseName : ''] });
                }
                if(!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName,'customer', element.customerCd)){
                    this.$refs.myTable.listName.push({ ['customer']: [element.customerCd ? element.customerCd : '', element.customerName ? ":" + element.customerName : ''] });
                }
                if(!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName,'tanto', element.tantoUserCd)){
                    this.$refs.myTable.listName.push({ ['tanto']: [element.tantoUserCd ? element.tantoUserCd : '', element.tantoUserName ? ":" + element.tantoUserName : ''] });
                }
                if(!this.$refs.myTable.checkCodeExist(this.$refs.myTable.listName,'item', element.itemCd)){
                    this.$refs.myTable.listName.push({ ['item']: [element.itemCd ? element.itemCd : '', element.itemName ? ":" + element.itemName : ''] });
                }
            });
        },
        //半角のみ
        isContainsMultiBytes(value){
            return /[\uD800-\uDFFF]/.test(value);
        },
        deleteButton(instance, td){
            Handsontable.renderers.HtmlRenderer.apply(this, arguments);
            td.innerHTML = '<button style="border: none; background: none; height: 10px;">✖</button>'
        },
        beforeChangeRender: function (changes) {
            //コメント変更時の半角チェック
            for (var i = 0; i < changes.length; i++) {
                var row = changes[i][0];
                var col = changes[i][1];
                var colName = this.$refs.myTable.$refs.hotTable.hotInstance.colToProp(col);
                if(colName == 'comment') {
                    if (!this.regexSingleChar(changes[i][3])) {
                        this.setFocusOut2(row,col);
                        break;
                    }
                }
            }

        },
        afterBeginEditing: function (row, col) {
            var colName = this.$refs.myTable.$refs.hotTable.hotInstance.colToProp(col);
            var self = this;
            switch (colName) {
                case 'requestMakerCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, MessageError.SPECIAL.requestMaker.maxLength);
                }
                break;
                case 'itemMakerCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, MessageError.SPECIAL.itemMaker.maxLength);
                } 
                break;
                case 'departmentCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, MessageError.SPECIAL.department.maxLength);
                } break;
                case 'warehouseCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, MessageError.SPECIAL.warehouse.maxLength);
                } break;
                case 'occurDate': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexDate(value, row, col)) {
                            boolean = true;
                        }
                        return boolean;
                    });
                } break;
                case 'customerCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, MessageError.SPECIAL.customer.maxLength);
                } break;
                case 'tantoUserCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, MessageError.SPECIAL.tantoUser.maxLength);
                } break;
                case 'itemCd': {
                    this.setInputFilter2(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, row, col, MessageError.SPECIAL.item.maxLength);
                } break;
                case 'capacity1': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 5)) {
                            boolean = true;
                            return boolean;
                        }
                        return boolean;
                    });
                } break;
                case 'tatene': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 8)) {
                            boolean = true;
                            return boolean;
                        }
                        if (self.regexFloat(value, 8, 2)) {
                            boolean = true;
                        }
                        return boolean;
                    });
                } break;
                case 'bowlNumber': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 9)) {
                            boolean = true;
                            return boolean;
                        }
                        return boolean;
                    });
                } break;
                case 'tateneAmount': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 9)) {
                            boolean = true;
                            return boolean;
                        }
                        if (self.regexFloat(value, 9, 2)) {
                            boolean = true;
                        }
                        return boolean;
                    });
                } break;
                case 'noka': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 8)) {
                            boolean = true;
                            return boolean;
                        }
                        if (self.regexFloat(value, 8, 2)) {
                            boolean = true;
                        }
                        return boolean;
                    });
                } break;
                case 'nokaPercent':
                case 'tatenePercent': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 4)) {
                            boolean = true;
                            return boolean;
                        }
                        if (self.regexFloat(value, 4, 2)) {
                            boolean = true;
                        }
                        return boolean;
                    });
                } break;
                case 'conditionAt': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 9)) {
                            boolean = true;
                            return boolean;
                        }
                        if (self.regexFloat(value, 9, 2)) {
                            boolean = true;
                        }
                        return boolean;
                    });
                } break;
                case 'billingAmount': {
                    this.setInputFilter(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA, function (value) {
                        var boolean = false;
                        if (self.regexLength(value, 9)) {
                            boolean = true;
                            return boolean;
                        }
                        return boolean;
                    });
                } break;
                case 'comment': {
                    this.setFocusOut(this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA,row,col);
                } break;
            }
            this.isDisabled = true;
        },
        setInputFilter(textbox, inputFilter) {
            textbox.oldValue = textbox.value;
            textbox.oninput = function () {
                if (inputFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.oldValue != undefined) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd + 1);
                } else {
                    this.value = '';
                }
            };
        },
        setInputFilter2(textbox, row, col, length) {
            var self = this;
            textbox.onblur = function () {
                var rex = /[\uFF10-\uFF19]/g;
                var str = this.value;
                str = str.replace(rex, function(ch) {
                    return String.fromCharCode(ch.charCodeAt(0) - 65248);
                });
                self.$refs.myTable.$refs.hotTable.hotInstance.setDataAtCell(row, col, str)
                this.onblur = ()=>{}

            };
            textbox.oninput = function () {
                if (this.value.length > length) {
                    this.value = this.value.substring(0,length);
                    return false;
                }
            };
        },
        setFocusOut(textbox,row, col) {
            var self = this;
            textbox.oldValue = textbox.value;
            textbox.onblur = function () {
                if (!self.regexSingleChar(this.value)) {
                    self.commentEror = true;
                    self.commentErorRow = row;
                    self.commentErorCol = col;
                    const customBordersPlugin = self.$refs.myTable.$refs.hotTable.hotInstance.getPlugin('customBorders');
                    customBordersPlugin.setBorders([[row, col, row, col]], {left: {width: 2, color: '#4B89FF'}, top: {width: 2, color: '#4B89FF'}, right: {width: 2, color: '#4B89FF'},bottom: {width: 2, color: '#4B89FF'}});
                    self.$refs.myTable.$refs.hotTable.hotInstance.updateSettings({outsideClickDeselects:false}, false);
                    setTimeout(
                        function(){
                            self.$refs.myTable.$refs.hotTable.hotInstance.deselectCell();
                            self.$emit('showSimpleModal', {
                            type: DialogType.NOTICE,
                            title: 'Webからのメッセージ',
                            message: 'コメントは半角で入力してください',
                            closeFunc:()=>{
                                    textbox.onblur = ()=>{};
                                    customBordersPlugin.setBorders([[row, col, row, col]], {left: {width: 1, color: '#CCC'}, top: {width: 1, color: '#CCC'}, right: {width: 1, color: '#CCC'},bottom: {width: 1, color: '#CCC'}});
                                    self.$refs.myTable.$refs.hotTable.hotInstance.selectCell(row,col);
                                    self.commentEror = false;
                                    self.$refs.myTable.$refs.hotTable.hotInstance.updateSettings({outsideClickDeselects:true}, false);
                                }
                            });
                        },100
                    )
                }
            };
            textbox.oninput = function(){};
        },
        setFocusOut2(row, col) {
            var self = this;
            self.commentEror = true;
            self.commentErorRow = row;
            self.commentErorCol = col;
            const customBordersPlugin = self.$refs.myTable.$refs.hotTable.hotInstance.getPlugin('customBorders');
            customBordersPlugin.setBorders([[row, col, row, col]], {left: {width: 2, color: '#4B89FF'}, top: {width: 2, color: '#4B89FF'}, right: {width: 2, color: '#4B89FF'},bottom: {width: 2, color: '#4B89FF'}});
            self.$refs.myTable.$refs.hotTable.hotInstance.updateSettings({outsideClickDeselects:false}, false);
            setTimeout(
                function(){
                    self.$refs.myTable.$refs.hotTable.hotInstance.deselectCell();
                    self.$emit('showSimpleModal', {
                    type: DialogType.NOTICE,
                    title: 'Webからのメッセージ',
                    message: 'コメントは半角で入力してください',
                    closeFunc:()=>{
                            this.onblur = ()=>{};
                            customBordersPlugin.setBorders([[row, col, row, col]], {left: {width: 1, color: '#CCC'}, top: {width: 1, color: '#CCC'}, right: {width: 1, color: '#CCC'},bottom: {width: 1, color: '#CCC'}});
                            self.$refs.myTable.$refs.hotTable.hotInstance.selectCell(row,col);
                            self.commentEror = false;
                            self.$refs.myTable.$refs.hotTable.hotInstance.updateSettings({outsideClickDeselects:true}, false);
                        }
                    });
                },100
            )
        },
        setRegex(col, value,values){
            var colName = this.$refs.myTable.$refs.hotTable.hotInstance.colToProp(col);
            var self = this;
            var boolean = false;
            switch (colName) {
                case 'requestMakerCd': {
                    values[3] = this.setFormatValue(value, MessageError.SPECIAL.requestMaker.maxLength);
                    break;
                }
                case 'itemMakerCd': {
                    values[3] = this.setFormatValue(value, MessageError.SPECIAL.itemMaker.maxLength);
                    break;
                }
                case 'departmentCd': {
                    values[3] = this.setFormatValue(value, MessageError.SPECIAL.department.maxLength);
                    break;
                }
                case 'warehouseCd': {
                    values[3] = this.setFormatValue(value, MessageError.SPECIAL.warehouse.maxLength);
                    break;
                }
                case 'occurDate': {
                    if (self.regexDate(value, 0, col)) {
                        boolean = true;
                    }
                    if (!boolean) {
                        values[3] = ''
                    }
                    break;
                }
                case 'customerCd': {
                    values[3] = this.setFormatValue(value, MessageError.SPECIAL.customer.maxLength);
                    break;
                }
                case 'tantoUserCd': {
                    values[3] = this.setFormatValue(value, MessageError.SPECIAL.tantoUser.maxLength);
                    break;
                }
                case 'itemCd': {
                    values[3] = this.setFormatValue(value, MessageError.SPECIAL.item.maxLength);
                    break;
                }
                case 'capacity1': {
                    if (self.regexLength(value, 5)) {
                        boolean = true;
                    }
                    if (!boolean) {
                        values[3] = ''
                    }
                    break;
                }
                case 'tatene': {
                    if (self.regexLength(value, 8)) {
                        boolean = true;
                    }
                    if (self.regexFloat(value, 8, 2)) {
                        boolean = true;
                    }
                    if (!boolean) {
                        values[3] = ''
                    }
                    break;
                }
                case 'bowlNumber': {
                    if (self.regexLength(value, 9)) {
                        boolean = true;
                    }
                    if (!boolean) {
                        values[3] = ''
                    }
                    break;
                }
                case 'tateneAmount': {
                    if (self.regexLength(value, 9)) {
                        boolean = true;
                    }
                    if (self.regexFloat(value, 9, 2)) {
                        boolean = true;
                    }
                    if (!boolean) {
                        values[3] = ''
                    }
                    break;
                }
                case 'noka': {
                    if (self.regexLength(value, 8)) {
                        boolean = true;
                    }
                    if (self.regexFloat(value, 8, 2)) {
                        boolean = true;
                    }
                    if (!boolean) {
                        values[3] = ''
                    }
                    break;
                }
                case 'nokaPercent':
                case 'tatenePercent': {
                    if (self.regexLength(value, 4)) {
                        boolean = true;
                    }
                    if (self.regexFloat(value, 4, 2)) {
                        boolean = true;
                    }
                    if (!boolean) {
                        values[3] = ''
                    }
                    break;
                }
                case 'conditionAt': {
                    if (self.regexLength(value, 9)) {
                        boolean = true;
                    }
                    if (self.regexFloat(value, 9, 2)) {
                        boolean = true;
                    }
                    if (!boolean) {
                        values[3] = ''
                    }
                    break;
                }
                case 'billingAmount': {
                    if (self.regexLength(value, 9)) {
                        boolean = true;
                    }
                    if (!boolean) {
                        values[3] = ''
                    }
                    break;
                }
                default:{
                    break;
                }
            }
        },
        regexDate(value, row, col) {
            const n = value.length;
            let rs = false;
            switch (n) {
                case 0:
                    rs = true
                    break;
                case 1:
                    rs = /^\d{1}$/.test(value)
                    break;
                case 2:
                    rs = /^\d{2}$/.test(value)
                    break;
                case 3:
                    rs = /^\d{3}$/.test(value)
                    break;
                case 4:
                    rs = /^\d{4}$/.test(value)
                    break;
                case 5:
                    rs = /^\d{4}(\/|[0-1])$/.test(value)
                    break;
                case 6:
                    rs = /^\d{4}(\/[0-1]|(0[1-9]|1[0-2]))$/.test(value)
                    break;
                case 7:
                    rs = /^\d{4}(\/(0[1-9]|1[0-2]))|((0[1-9]|1[0-2])[0-3])$/.test(value)
                    break;
                case 8:
                    rs = /^\d{4}((\/(0[1-9]|1[0-2])\/)|(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1]))$/.test(value)
                    if (rs && !value.includes('/')) {
                        const hot = this.$refs.myTable.$refs.hotTable.hotInstance;
                        const d = `${value.substring(0,4)}/${value.substring(4,6)}/${value.substring(6,8)}`
                        hot.setDataAtCell(row, col, d)
                    }
                    break;
                case 9:
                    rs = /^\d{4}\/(0[1-9]|1[0-2])\/[0-3]$/.test(value)
                    break;
                case 10:
                    rs = /^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])$/.test(value)
                    break;
                default:
                    this.$refs.myTable.$refs.hotTable.hotInstance.getActiveEditor().TEXTAREA.value = value.substring(0,10);
                    rs = true;
                    break;
            }
            return rs
        },
        regexLength(value, length) {
            var regex = new RegExp("^[0-9]{0," + length + "}?$")
            return regex.test(value)
        },
        regexIntergerPositive(value) {
            return /^[0-9]?$/.test(value)
        },
        regexFloat(value, original, decimal) {
            var regex = new RegExp("^([0-9]{0," + original + "})?[.,]{1,1}?([0-9]{1," + decimal + "})?$")
            return regex.test(value)
        },
        regexSingleChar(str) {
            var boolean = false;
            if(str.length == 0){
                return true;
            }
            for(var i = 0; i < str.length; i++){
                var c = str.charCodeAt(i);
                // Shift_JIS: 0x0 ～ 0x80, 0xa0 , 0xa1 ～ 0xdf , 0xfd ～ 0xff
                // Unicode : 0x0 ～ 0x80, 0xf8f0, 0xff61 ～ 0xff9f, 0xf8f1 ～ 0xf8f3
                if ((c >= 0x0 && c < 0x81) || (c == 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
                    boolean = true;
                }else{
                    boolean = false;
                    return boolean;
                }
            }
            return boolean;
        },
        setOccurDateDefault() {
            var hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            var dataTable = hot.getSourceData();
            var columnSetting = hot.getSettings().__proto__.columns;
            for (let i = 0; i < dataTable.length; i++) {
                if (!hot.isEmptyRow(i)) {
                    //Undisable [登録]
                    this.isDisabled = false;

                    //ハンドル発生日
                    const occurDate = dataTable[i].occurDate;
                    if (!occurDate) {
                        const dateAPI = this.yearMonthList[0].yearMonthCd; //YYYYMM
                        const lastDayOfMonth = moment(dateAPI + '01', 'YYYYMMDD').add(1, 'months').subtract(1, 'days').format('YYYY/MM/DD');
                        dataTable[i].occurDate = lastDayOfMonth;
                        hot.setCellMeta(i, hot.propToCol('occurDate'), 'valid', true);
                    }
                }else {
                    columnSetting.forEach((values, index) => {
                        hot.setCellMeta(i, index, 'valid',true);
                    })
                }
            }
            if(hot.countEmptyRows() == dataTable.length){
                //Undisable [登録]
                this.isDisabled = true;
            }
            hot.render();
        },
        setFormatValue(value, length){
            var rex = /[\uFF10-\uFF19]/g;
            var str = value;
            str = str.replace(rex, function(ch) {
                return String.fromCharCode(ch.charCodeAt(0) - 65248);
            });
            if (str.length > length) {
                str = str.substring(0,length);
            }
           return str;
        }
    },
}

import HotTable from '@handsontable/vue';
import Handsontable from "handsontable";
import { common } from '../../../api';
import searchType from '../../../data/constant/search-type';
import moment from 'moment';
export default {
    name: "Handsontable",
    components: {
        HotTable
    },
    props: {
        tableSettings: {},
    },
    data: function () {
        return {
            searchModalVal: {},
            modalId: '',
            isSearchModalVisible: false,
            vendorList: [],
            centerList: [],
            customerList: [],
            userList: [],
            rowSelected: 0,
            colSelected: 0,
            rowChange: 9999,
            colChange: 0,
            apiName: '',
            listName: [],
            dataChange: '',
            cancelNestedCalls: true,
            hotSettings: {
                colHeaders: true,
                rowHeaders: true,
                dropdownMenu: true,
                allowInsertRow: false,
                allowRemoveColumn: false,
                data: [],
            },
            searches: {
                maker: [],
                tanto: [],
                item: [],
                customer: [],
            }
        };
    },
    created() {
        this.tableSettings.beforePaste = this.beforePaste;
        this.tableSettings.beforeKeyDown = this.beforeKeyDown;
        this.tableSettings.beforeChange = this.beforeChange;
        Object.assign(this.hotSettings, this.tableSettings);
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            this.$refs.hotTable.hotInstance.render()
        },

        percentageRenderer(instance, td, row, col, prop, value) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            if (value !== null)
                td.innerHTML = td.innerHTML + '%';
        },
        beforePaste: function (data, coords) {
            const MAX_ROW = 999;
            var rowCount = this.$refs.hotTable.hotInstance.countRows() - 1;
            var startRow = coords[0].startRow;

            var col = coords[0].startCol;
            var rowCell = 0;
            var colCell = 0;
            data.forEach(element => {
                element.forEach(element2 => {
                    if (this.$refs.hotTable.hotInstance.getCellMeta(1, col).__proto__.type == 'dropdown') {
                        var source = this.$refs.hotTable.hotInstance.getCellMeta(1, col).source;
                        data[rowCell][colCell] = this.convertDisplay(source, element2);
                    }
                    if (this.$refs.hotTable.hotInstance.getCellMeta(1, col).__proto__.type == 'date') {
                        data[rowCell][colCell] = element2 && moment(element2).format('YYYY/MM/DD') != 'Invalid date' ? moment(element2).format('YYYY/MM/DD') : "";
                    }
                    col++;
                    colCell++;
                })
                colCell = 0;
                rowCell++;
                col = coords[0].startCol;
            });

            if (this.$parent.moveFromSearch != 'true') {
                if (data.length > rowCount - startRow) {
                    var rowCountTemp = this.$refs.hotTable.hotInstance.countRows();
                    var count = data.length - (rowCount - startRow) + rowCountTemp;
                    if (count > MAX_ROW) {
                        this.$refs.hotTable.hotInstance.alter('insert_row', rowCountTemp + 1, MAX_ROW - rowCountTemp);
                    } else {
                        this.$refs.hotTable.hotInstance.alter('insert_row', rowCountTemp + 1, data.length - (rowCount - startRow));
                    }
                }
            }
        },

        /**
         * get name from API
         * 
         * return :name
         * 
         */
        getName: function (data, apiName, callback) {
            var apiDatas = [];
            var request = { searchKbn: 1, type: apiName, searches: data }

            /*登録済み得意先にしたいときはこちらを使用する
            if(apiName == searchType.CUSTOMER){
                request.type = searchType.REGISTERED_CUSTOMER;
            }
            */
            var self = this;
            self.dataChange = data;
            // empty data
            if (data == '' || data.length == 0) {
                callback(false);
                return false;
            }
            // call api
            common.master(request)
                .then((response) => {
                    apiDatas = response.data.result.summaryList;
                    if (apiDatas.length == 0) {
                        callback(false);
                        return false;
                    }
                    apiDatas.forEach(value => {
                        if (!self.checkCodeExist(self.listName, apiName, value.cd)) {
                            self.listName.push({ [apiName]: [value.cd, ":" + value.name] });
                        }
                    })
                    callback(true);

                    self.$store.commit('setLoading', false);
                })
                .catch((e) => {
                    console.log('error', e);
                    self.$emit('displayErrorMessage', e);
                    self.$store.commit('setLoading', false);
                });
        },

        /**
         * Press F6
         * 
         */
        beforeKeyDown: function (e) {
            var self = this;
            var apiName = "";
            if (e.keyCode === 117) {
                var cellSeleted = self.$refs.hotTable.hotInstance.getSelected()[0];
                apiName = self.$refs.hotTable.hotInstance.getCellMeta(1, cellSeleted[1]).__proto__.api;
                e.preventDefault();
                if (apiName == undefined) {
                    return false;
                }
                self.rowSelected = cellSeleted[0];
                self.colSelected = cellSeleted[1];
                if (self.$refs.hotTable.hotInstance.getCellMeta(self.rowSelected, self.colSelected).readOnly) {
                    return false;
                }

                self.$refs.hotTable.hotInstance.deselectCell();

                // on column 'center' or 'department'
                if (apiName == searchType.CENTER || apiName == searchType.BRANCH) {
                    self.showSearchModal(searchType.MASTER, searchType.BRANCH_CENTER, true);
                    return false;
                }

                //searchModel default
                /*登録済み得意先を使う場合はこちらを使う
                if(apiName == searchType.CUSTOMER){
                    self.showSearchModal(searchType.MASTER,searchType.REGISTERED_CUSTOMER, true);
                    return false;
                }
                */
                //searchModel custom
                self.showSearchModal(searchType.MASTER, apiName, true);
            }
        },

        /**
         * getList cd on table
         * 
         */
        getNameExist(listName, apiName) {
            var listTemp = [];
            for (var temp of listName) {
                if (temp[apiName] != undefined) {
                    listTemp.push(temp[apiName])
                }
            }
            return listTemp;
        },

        /**
         * check cd on table
         * 
         */
        checkCodeExist(listName, apiName, cd, cd1) {
            if (apiName == searchType.CENTER) {
                for (var temp of listName) {
                    if (temp[apiName] != undefined && cd == temp[apiName][0] && cd1 == temp[apiName][1]) {
                        return true
                    }
                }
            } else {
                for (var temp1 of listName) {
                    if (temp1[apiName] != undefined && cd == temp1[apiName][0]) {
                        return true
                    }
                }
            }
            return false;
        },

        /**
         * 検索モーダル表示
         * 
         */
        showSearchModal(modalId, type, isSingle) {
            this.modalId = modalId;
            this.searchModalVal.type = type;
            this.searchModalVal.modalId = modalId;
            this.searchModalVal.isSingle = isSingle;
            var cellData = this.$refs.hotTable.hotInstance.getDataAtCell(this.rowSelected, this.colSelected);
            if (cellData == null) {
                cellData = '';
            }
            cellData = ('' + cellData).split(':')[0];
            if (cellData == '') {
                this.searchModalVal.selectedItemList = [];
            } else {
                this.searchModalVal.selectedItemList = [{ cd: cellData }];
            }
            var apiName = this.$refs.hotTable.hotInstance.getCellMeta(this.rowSelected, this.colSelected).__proto__.api;
            var branchCellData;
            var centerCellData;
            if (apiName == searchType.BRANCH) {
                branchCellData = this.$refs.hotTable.hotInstance.getDataAtCell(this.rowSelected, this.colSelected);
                centerCellData = this.$refs.hotTable.hotInstance.getDataAtCell(this.rowSelected, this.colSelected + 1);
                this.searchModalVal.selectedItemList = [{ cd: branchCellData + '-' + centerCellData }];
            }
            if (apiName == searchType.CENTER) {
                branchCellData = this.$refs.hotTable.hotInstance.getDataAtCell(this.rowSelected, this.colSelected - 1);
                centerCellData = this.$refs.hotTable.hotInstance.getDataAtCell(this.rowSelected, this.colSelected);
                this.searchModalVal.selectedItemList = [{ cd: branchCellData + '-' + centerCellData }];
            }
            //検索モーダル表示
            this.isSearchModalVisible = true;
        },

        /**
         * 検索条件追加
         * @param val
         * */
        addItem(val) {
            var apiName = this.$refs.hotTable.hotInstance.getCellMeta(this.rowSelected, this.colSelected).__proto__.api;
            if (apiName == searchType.CENTER || apiName == searchType.BRANCH) {
                if (apiName == searchType.BRANCH) {
                    if (!this.checkCodeExist(this.listName, apiName, val[0].cd.split('-')[0])) {
                        this.listName.push({ [apiName]: [val[0].cd.split('-')[0], ":" + val[0].name.split(' ')[0]] });
                    }
                    if (!this.checkCodeExist(this.listName, searchType.CENTER, val[0].cd.split('-')[0], val[0].cd.split('-')[1])) {
                        this.listName.push({ [searchType.CENTER]: [val[0].cd.split('-')[0], val[0].cd.split('-')[1], ":" + val[0].name.split(' ')[1]] });
                    }
                    this.$refs.hotTable.hotInstance.setDataAtCell(this.rowSelected, this.colSelected, val[0].cd.split('-')[0]);
                    this.$refs.hotTable.hotInstance.setDataAtCell(this.rowSelected, this.colSelected + 1, val[0].cd.split('-')[1]);
                    this.$refs.hotTable.hotInstance.selectCell(this.rowSelected, this.colSelected);
                    return false;
                }
                if (apiName == searchType.CENTER) {
                    if (!this.checkCodeExist(this.listName, searchType.BRANCH, val[0].cd.split('-')[0])) {
                        this.listName.push({ [searchType.BRANCH]: [val[0].cd.split('-')[0], ":" + val[0].name.split(' ')[0]] });
                    }
                    if (!this.checkCodeExist(this.listName, apiName, val[0].cd.split('-')[0], val[0].cd.split('-')[1])) {
                        this.listName.push({ [apiName]: [val[0].cd.split('-')[0], val[0].cd.split('-')[1], ":" + val[0].name.split(' ')[1]] });
                    }
                    this.$refs.hotTable.hotInstance.setDataAtCell(this.rowSelected, this.colSelected - 1, val[0].cd.split('-')[0]);
                    this.$refs.hotTable.hotInstance.setDataAtCell(this.rowSelected, this.colSelected, val[0].cd.split('-')[1]);
                    this.$refs.hotTable.hotInstance.selectCell(this.rowSelected, this.colSelected);
                    return false;
                }
                return false;
            }
            if (!this.checkCodeExist(this.listName, apiName, val[0].cd)) {
                this.listName.push({ [apiName]: [val[0].cd, ":" + val[0].name] });
            }
            this.$refs.hotTable.hotInstance.setDataAtCell(this.rowSelected, this.colSelected, val[0].cd);
            this.$refs.hotTable.hotInstance.selectCell(this.rowSelected, this.colSelected);
        },

        beforeChange: async function (changes) {
            var self = this;
            self.rowChange = changes[0][0]
            self.colChange = self.$refs.hotTable.hotInstance.propToCol(changes[0][1])
            self.apiName = self.$refs.hotTable.hotInstance.getCellMeta(1, self.colChange).__proto__.api;
            var searches = {
                maker: [],
                tanto: [],
                item: [],
                customer: [],
            }
            var searchesData = {
                maker: [],
                tanto: [],
                item: [],
                customer: [],
            }
            var columnSetting = this.$refs.hotTable.hotInstance.getSettings().__proto__.columns;
            changes.forEach(async (values) => {
                var col = self.$refs.hotTable.hotInstance.propToCol(values[1])
                if (self.$parent.setRegex != undefined) {
                    self.$parent.setRegex(col, values[3],values)
                }
            })
            await columnSetting.forEach((value1, index) => {
                var api = self.$refs.hotTable.hotInstance.getCellMeta(1, index).__proto__.api;
                if (index == columnSetting.length - 1) {
                    for (const [key] of Object.entries(searches)) {
                        if (searchesData[key].length != 0) {
                            self.getName(searches[key].filter((v, i, a) => a.indexOf(v) === i).filter(value => !/\D/.test(value)), key, (render) => {
                                if (render) {
                                    self.$refs.hotTable.hotInstance.render();
                                }
                            }
                            );
                        }
                    }
                }
                if (api == undefined) {
                    return;
                }
                if (api == searchType.CENTER || api == searchType.BRANCH) {
                    return;
                }

                if (searches[api].length > 0) {
                    searches[api].push = changes.filter(value =>
                        value[1] == value1.data
                    ).map(value => {
                        return value[3]
                    });
                } else {
                    searches[api] = changes.filter(value =>
                        value[1] == value1.data
                    ).map(value => {
                        return value[3]
                    });
                }
                var difference1 = searches[api].filter(x =>
                    self.getNameExist(self.listName, api).map(value => {
                        return value[0]
                    }
                    ).indexOf(x) == -1
                );
                var difference2 = difference1.filter(x => self.searches[api].indexOf(x) == -1)
                if (difference2.length > 0) {
                    searchesData[api] = difference2.filter((v, i, a) => a.indexOf(v) === i);
                    self.searches[api].push.apply(self.searches[api], difference2.filter((v, i, a) => a.indexOf(v) === i));
                }
            })
        },

        colRenderer: function (instance, td, row, col, prop, value) {
            var currencyCode = value;
            if (currencyCode == null || currencyCode == '') {
                Handsontable.renderers.TextRenderer.apply(this, arguments);
                return;
            }
            while (td.firstChild) {
                td.removeChild(td.firstChild);
            }
            var name = '';
            var colChange = this.$refs.hotTable.hotInstance.propToCol(prop)
            var apiName = this.$refs.hotTable.hotInstance.getCellMeta(1, colChange).__proto__.api;
            var self = this;
            if (apiName == searchType.CENTER || apiName == searchType.BRANCH) {
                var masterList = self.getStorage(apiName + 'List');

                var dataTemp = [];
                if (apiName == searchType.CENTER) {
                    // console.log(masterList)
                    for (var data1 of masterList) {
                        dataTemp.push({ "cd": Object.values(data1)[0], "cd1": Object.values(data1)[1], "name": Object.values(data1)[2] });
                    }
                } else {
                    for (var data2 of masterList) {
                        dataTemp.push({ "cd": Object.values(data2)[0], "name": Object.values(data2)[1] });
                    }
                }

                if (dataTemp && dataTemp.length != undefined && dataTemp.length != 0) {
                    dataTemp.forEach(el => {
                        if (apiName == searchType.CENTER) {
                            var branchData = self.$refs.hotTable.hotInstance.getDataAtCell(row, col - 1);
                            if (el.cd == branchData && el.cd1 == currencyCode) {
                                if (!self.checkCodeExist(self.listName, apiName, el.cd, el.cd1)) {
                                    self.listName.push({ [apiName]: [el.cd, el.cd1, ":" + el.name] });
                                }
                                return false;
                            }
                        } else {
                            if (el.cd == currencyCode) {
                                if (!self.checkCodeExist(self.listName, apiName, el.cd)) {
                                    self.listName.push({ [apiName]: [el.cd, ":" + el.name] });
                                }
                                return false;
                            }
                        }

                    });
                }
            }

            var listTemp = this.getNameExist(this.listName, apiName);
            if (listTemp.length != 0) {
                for (var temp of listTemp) {
                    if (apiName == searchType.CENTER) {
                        var branchData = self.$refs.hotTable.hotInstance.getDataAtCell(row, col - 1);
                        if (temp[0] == branchData && temp[1] == currencyCode) {
                            name = temp[2];
                        }
                    } else {
                        if (temp[0] == currencyCode) {
                            name = temp[1];
                        }
                    }

                }
            }

            if (name != '') {
                if (this.$refs.hotTable.hotInstance.getCellMeta(row, col).readOnly) {
                    Handsontable.renderers.TextRenderer.apply(this, arguments);
                }
                while (td.firstChild) {
                    td.removeChild(td.firstChild);
                }
                var flagElement = document.createElement('DIV');
                flagElement.className = 'text-nowrap htLeft';
                flagElement.innerText = currencyCode + name;
                td.className += ' text-nowrap htLeft';
                td.appendChild(flagElement);
            } else {
                Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
        },
        convertDisplay(source, invalue) {
            var data = '';
            if (source.length > 0) {
                source.forEach(value => {
                    if (value.split(':')[0] === invalue ||
                        value.split(':')[1] === invalue ||
                        value === invalue) {
                        data = value;
                    }
                });
            }
            return data;
        }
    },
};
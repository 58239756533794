const SUCCESS = 1
const INFO = 2
const NOTICE = 3
const WARNING = 4

export default {
  SUCCESS: SUCCESS,
  INFO: INFO,
  NOTICE: NOTICE,
  WARNING: WARNING
}
import MessageError from '../data/constant/message-error'
import DialogType from '../data/constant/dialog-type'
export default {
    data: function () {
        return {
            msgError: '',
            self: {},
        }
    },
    methods: {
        validator(self, validatetList, callback) {
            var boolean = false;
            this.self = self;
            var hot = self.$refs.myTable.$refs.hotTable.hotInstance;
            var columnSetting = self.$refs.myTable.$refs.hotTable.hotInstance.getSettings().__proto__.columns;
            const registerList = hot.getSourceData();
            for (let i = 0; i < registerList.length; i++) {
                if (hot.isEmptyRow(i)) {
                    columnSetting.forEach((values, index) => {
                        hot.setCellMeta(i, index, 'validator',(value, callback) => {callback(true)})
                    })
                    continue;
                }

                columnSetting.forEach((values, index) => {
                    var validate = MessageError[self.type][columnSetting[index].data];
                    if (validatetList != undefined) {
                        validatetList.forEach(value1 => {
                            if (registerList[i].registerPattern && registerList[i].registerPattern.split(':')[0] == value1.cd && value1[values.data.toLowerCase()] == 1) {
                                if (!hot.getCellMeta(i, index).readOnly) {
                                    if (hot.colToProp(index) == 'profitAmount') {
                                        hot.setCellMeta(i, index, 'validator', (value, callback) => {
                                            this.validateActionProfitAmount(i + 1, index, validate.colName, value != null ? value : '', callback, true)
                                        }
                                        );
                                    } else {
                                        hot.setCellMeta(i, index, 'validator', (value, callback) => {
                                            this.validateAction(i + 1, index, validate.colName, value != null ? value : '', callback, true)
                                        }
                                        );
                                    }
                                    return false;
                                }
                                return false;
                            }
                        })
                    }
                    if (hot.colToProp(index) == 'comment' && registerList[i].comment) {
                        hot.setCellMeta(i, index, 'validator', (value, callback) => {
                            this.validateComment(i + 1, index, validate.colName, value != null ? value : '', callback)
                        })
                    }

                    if (validate != undefined) {
                        if (Object.prototype.hasOwnProperty.call(validate, "validator")) {
                            var validator = validate.validator;
                            if (!hot.getCellMeta(i, index).readOnly) {
                                hot.setCellMeta(i, index, 'validator', (value, callback) => {
                                    this.validateAction(i + 1, index, validate.colName, value ? value : '', callback, validator.null, validator.maxLength)
                                }
                                );
                            }
                        }
                    }
                })

            }
            this.msgError = '';
            hot.validateCells((valid) => {
                if (!valid) {
                    if (this.msgError.length > 0) {
                        self.$emit('showSimpleModal', {
                            type: DialogType.NOTICE,
                            title: 'エラー',
                            message: this.msgError
                        });
                    }
                    boolean = true;
                    callback(boolean);
                }
                callback(boolean);
            });
        },
        validateAction(row, col, colName, value, callback, isnull, maxLength) {
            var isValidate = true;
            if (isnull) {
                if (!this.empty('' + value)) {
                    isValidate = false;
                    this.msgError = row + '行目　' + colName + MessageError.NULL + '<br>' + this.msgError;
                }
            }
            if (maxLength) {
                var regex = new RegExp("^[0-9]{0," + maxLength + "}?$")
                if (!regex.test(value)) {
                    isValidate = false;
                    this.msgError = row + '行目　' + colName + 'は' + maxLength + MessageError.LENGTH + '<br>' + this.msgError;
                }
            }
            var apiName = this.self.$refs.myTable.$refs.hotTable.hotInstance.getCellMeta(row - 1, col).__proto__.api;
            if (value && apiName != undefined) {
                var listName = this.self.$refs.myTable.listName;
                var master = this.self.$refs.myTable.getNameExist(listName, apiName);
                var departmentCd = this.self.$refs.myTable.$refs.hotTable.hotInstance.getDataAtCell(row - 1, col - 1);
                if (apiName == 'center') {
                    if (!master.find(o => o[0] === departmentCd && o[1] === value)) {
                        isValidate = false;
                        this.msgError = row + '行目　' + colName + MessageError.SEARCH + '<br>' + this.msgError;
                    }
                } else {
                    if (!master.find(o => o[0] === value)) {
                        isValidate = false;
                        this.msgError = row + '行目　' + colName + MessageError.SEARCH + '<br>' + this.msgError;
                    }
                }
            }
            if (isValidate) {
                callback(true);
            } else {
                callback(false);
            }
        },
        validateActionProfitAmount(row, col, colName, value, callback, isnull) {
            var hot = this.self.$refs.myTable.$refs.hotTable.hotInstance;
            var isValidate = true;
            if (isnull) {
                if (!this.empty('' + value)) {
                    isValidate = false;
                    this.msgError = row + '行目　' + colName + MessageError.NULL + '<br>' + this.msgError;
                }
            }
            if(isValidate){
                var minusOfParttern = this.self.getMinus(hot.getDataAtCell(row - 1, 0).split(':')[0])
                if (minusOfParttern == 1) {
                    if (value > -1) {
                        isValidate = false;
                        this.msgError = row + '行目　選択されたパターンの黒修正金額はマイナスの数値を入力してください' + '<br>' + this.msgError;
                    }
                } else if (minusOfParttern == 0) {
                    if (value < 1) {
                        isValidate = false;
                        this.msgError = row + '行目　選択されたパターンの黒修正金額はプラスの数値を入力してください' + '<br>' + this.msgError;
                    }
                } else {
                    if (value == 0) {
                        isValidate = false;
                        this.msgError = row + '行目　選択されたパターンの黒修正金額に0以外を入力してください' + '<br>' + this.msgError;
                    }
                }
            }
            if (isValidate) {
                callback(true);
            } else {
                callback(false);
            }
        },
        validateComment(row, col, colName, value, callback) {
            var isValidate = true;
            if (!this.self.regexSingleChar(value)) {
                    isValidate = false;
                    this.msgError = row + '行目　' + colName + MessageError.BYTE + '<br>' + this.msgError;
            }
            if (isValidate) {
                callback(true);
            } else {
                callback(false);
            }
        },
        empty(value) {
            if (value == '' || value == null || value == undefined) {
                return false;
            }
            return true;
        }
    }
}
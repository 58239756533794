export default {
  methods: {
    /**
     * 指定のオブジェクトから、指定のパスまで掘り下げて値を取得する
     *
     * @param {*} object オブジェクト
     * @param {path} path パスの文字列。引数 object のトップレベルプロパティから
     *                      ピリオド区切りで記す。配列の添字もピリオドで記す。
     *                      ex. 'items.0.options.name'
     * @return {*} 連想配列の値。取得できなかった場合は undefined が返される
     */
    getProperty(object, path) {
      let lookup = Object.assign({}, object);
      const keys = `${path}`.split('.');
      const length = keys.length;
      for(let index = 0; index < length; index++) {
        if(lookup[keys[index]] == null) {
          return index === length - 1 ? lookup[keys[index]] : undefined;
        }
        lookup = lookup[keys[index]];
      }
      return lookup;
    },
    /**
     * ローカルストレージを取得する
     */
    getStorage(storageKey, removeAfter) {
      const obj = JSON.parse(localStorage.getItem(storageKey)) || {};

      if (removeAfter) {
        this.removeStorage(storageKey);
      }

      return obj.data || {};
    },
    /**
     * ローカルストレージを削除する
     */
    removeStorage(storageKey) {
      localStorage.removeItem(storageKey);
    },
    /**
     * ローカルストレージをセットする
     */
    setStorage(storageKey, obj, limit) {
      localStorage.setItem(storageKey, JSON.stringify({
        data: obj,
        create: new Date(),
        limit: limit,
      }));
    },
    /**
     * ファイルダウンロード
     * @param response
     */
    downloadFile(response) {
      const contentDisposition = 'content-disposition';

      const headers = response.headers;
      if (headers[contentDisposition]) {
        const fileName = this.getFileName(headers[contentDisposition]);
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveOrOpenBlob(response.body, fileName);
        } else {
          const aTag = document.createElement('a');
          aTag.download = fileName;
          aTag.target = '_blank';
          aTag.href = window.URL.createObjectURL(response.data);
          document.body.appendChild(aTag);
          aTag.click();
          document.body.removeChild(aTag);
        }
      }
    },
    /**
     * Content-Dispositionからファイル名を取り出す
     * @param contentDisposition
     * @returns {*}
     */
    getFileName(contentDisposition) {
      const matchUtfResult = contentDisposition.match(/(?:.*; *)?filename\*=UTF-?8''([^;]+)(?:;.*)?/i);
      const matchResult = contentDisposition.match(/(?:.*; *)?filename=([^;]+)(?:;.*)?/i);
      if (matchUtfResult) {
        return decodeURIComponent(matchUtfResult[1]);
      } else if (matchResult) {
        return matchResult[1];
      } else {
        return 'unknown_file';
      }
    }
  }
}
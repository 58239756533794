var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isSearchModalVisible
        ? _c("search-modal", {
            attrs: { val: _vm.searchModalVal },
            on: {
              callParent: _vm.addItem,
              displayErrorMessage: function($event) {
                return _vm.$parent.$emit("displayErrorMessage", $event)
              },
              showSimpleModal: function($event) {
                return _vm.$parent.$emit("showSimpleModal", $event)
              },
              close: function($event) {
                _vm.isSearchModalVisible = false
              }
            }
          })
        : _vm._e(),
      _c("hot-table", { ref: "hotTable", attrs: { settings: _vm.hotSettings } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import DialogType from '../../data/constant/dialog-type'
import SearchType from '../../data/constant/search-type'
import HandsontableWrapper from "../../components/table/Handsontable/Handsontable.vue";
import Handsontable from 'handsontable';
import {common, commonBefore, uploadCheckFile}  from '../../api/index';
const VueSimpleSuggest = require('vue-simple-suggest/dist/cjs');
import 'vue-simple-suggest/dist/styles.css'
export default {
  name: 'UploadCheckFile',
  title: 'チェック表アップロード',
  components: {
    HandsontableWrapper,
    VueSimpleSuggest
  },
  data: function() {
    return {
      lastInput: "",
      EXPORT_USER: SearchType.EXPORT_USER,
      REGISTRATION_USER: SearchType.REGISTRATION_USER,
      VENDOR: SearchType.VENDOR,
      BEFORE_CUSTOMER: SearchType.BEFORE_CUSTOMER,

      modalId: '',
      isSearchModalVisible: false,
      searchModalVal: {},
      yearMonthList: [],
      vendorList: [],
      exportUserList: [],
      registrationUserList: [],
      vendorName: '',
      registrationUserName: '',
      customerCd: '',
      customerName: '',
      customerList: [],
      
      canSearch: false,
      firstInit: false,
      isDrag: null,
      exportUserName: '',
      form: {
        yearMonth: '',
        registrationUserCd: '',
        vendorCd: '',
        exportUserCd: '',
      },
      rowCount: 0,
      tableSettings: {
        colHeaders: ['出力者', '出力日時', '企業', 'ファイル名', '登録者', '登録日時', 'ステータス','ID'],
        columns: [
          {data: "exportUserName", type: "text", className: "htLeft", readOnly: true},
          {data: "exportDate", type: "text", className: "htLeft", readOnly: true},
          {data: "vendorName", type: "text", className: "htLeft", readOnly: true},
          {data: "fileName", type: "text", className: "htLeft", readOnly: true},
          {data: "registrationUserName", type: "text", className: "htLeft", readOnly: true},
          {data: "registrationDate", type: "text", className: "htLeft", readOnly: true},
          {data: "statusName", type: "text", className: "htLeft", renderer: this.messageRenderer, readOnly: true},
          {data: "fileid", type: "text", className: "htLeft", readOnly: true},
        ],
        colWidths: [120, 120, 158, 268, 120, 120, 120,90],
      },
      suggestList: {
        customerList: [],
        registrationUserList: [],
        exportUserList: [],
        vendorList: []
      }

    }
  },
  created() {
    this.$store.commit('setLoading', true);
    this.firstInit = true;
    console.log(this.firstInit);
    this.yearMonthList = this.getStorage('yearMonthList') || [];
    this.form.yearMonth = this.yearMonthList.length > 0 ? this.yearMonthList[0].yearMonthCd : '';
    this.form.exportUserCd = this.$store.state.userId;
    this.exportUserName = this.$store.state.userName;
    this.exportUserList = [{'cd':this.form.exportUserCd, 'name': this.exportUserName}]
    this.$store.commit('setLoading', false);
  },
  methods: {
    suggestSearch(value, searchType) {

      const name = searchType === SearchType.VENDOR ? 'vendorName' : searchType == SearchType.REGISTRATION_USER ? 'registrationUserName' : searchType == SearchType.EXPORT_USER ? 'exportUserName' : 'customerName';
      const list = searchType === SearchType.VENDOR ? 'vendorList' : searchType == SearchType.REGISTRATION_USER ? 'registrationUserList' : searchType == SearchType.EXPORT_USER ? 'exportUserList' : 'customerList';
      const cd = searchType === SearchType.VENDOR ? 'vendorCd' : searchType == SearchType.REGISTRATION_USER ? 'registrationUserCd' : searchType == SearchType.EXPORT_USER ? 'exportUserCd' : 'customerCd';
      const search = searchType === SearchType.VENDOR ? 'vendor' : searchType == SearchType.REGISTRATION_USER ? 'systemUser' : searchType == SearchType.EXPORT_USER ? 'systemUser' : 'customer';
      const resultList = searchType === SearchType.VENDOR ? 'vendorList' : searchType == SearchType.REGISTRATION_USER ? 'userList' : searchType == SearchType.EXPORT_USER ? 'userList' : 'customerList';
      const resultCd = searchType === SearchType.VENDOR ? 'vendorCd' : searchType == SearchType.REGISTRATION_USER ? 'userCd' : searchType == SearchType.EXPORT_USER ? 'userCd' : 'customerCd';
      const resultName = searchType === SearchType.VENDOR ? 'vendorName' : searchType == SearchType.REGISTRATION_USER ? 'userName' : searchType == SearchType.EXPORT_USER ? 'userName' : 'customerName';
      const suggest = searchType === SearchType.VENDOR ? 'suggestVendor' : searchType == SearchType.REGISTRATION_USER ? 'suggestRegistrationUser' : searchType == SearchType.EXPORT_USER ? 'suggestExportUser' : 'suggestCustomer';

      if(this.isSearchModalVisible){
          return;
      }

      if (!value) {
          this[name] = '';
          this[list] = [];
          this.$refs[suggest].suggestions = [];
          return;
      }
      this[list] = [];
      this[name] = "";

      var request;
      switch (searchType) {
        case SearchType.VENDOR:
          request = {
            vendorCd: value,
            vendorName: ''
          };
          break;
        case SearchType.EXPORT_USER:
          request = {
            userCd: value,
            userName: ''
          };
          break;
        case SearchType.REGISTRATION_USER:
          request = {
            userCd: value,
            userName: ''
          };
          break;
        case SearchType.BEFORE_CUSTOMER:
          request = {
            customerCd: value,
            customerName: ''
          };
          break;
      }
      this.lastInput = value;

      var self = this;
      if(searchType == SearchType.BEFORE_CUSTOMER){
        commonBefore[search](request)
          .then((response) => {
              if (!self[cd]) {
                  self[name] = '';
                  self[list] = [];
                  self.$refs[suggest].suggestions = [];
                  return;
              }
              if(this.lastInput == request[resultCd]){
                self.suggestList[list] = response.data.result[resultList].slice(0,11).map((value) => { return { cd: value[resultCd], name: value[resultName] } });
                self.$refs[suggest].suggestions = response.data.result[resultList].length > 0 ? response.data.result[resultList].slice(0,11).map(value => value[resultCd]) : [];
                self.$refs[suggest].input.click();
              }
          })
          .catch((e) => {
              console.log('error', e);
          });
      }else{
        common[search](request)
        .then((response) => {
            if (!self.form[cd]) {
                self[name] = '';
                self[list] = [];
                self.$refs[suggest].suggestions = [];
                return;
            }
            if(this.lastInput == request[resultCd]){
              self.$refs[suggest].suggestions = []
              self.suggestList[list] = response.data.result[resultList].slice(0,11).map((value) => { return { cd: value[resultCd], name: value[resultName] } });
              self.$refs[suggest].suggestions = response.data.result[resultList].length > 0 ? response.data.result[resultList].slice(0,11).map(value => value[resultCd]) : [];
              self.$refs[suggest].input.click();  
            }
        })
        .catch((e) => {
            console.log('error', e);
        });
      }
    },
    selectSuggest(searchType) {
        const name = searchType === SearchType.VENDOR ? 'vendorName' : searchType == SearchType.REGISTRATION_USER ? 'registrationUserName' : searchType == SearchType.EXPORT_USER ? 'exportUserName' : 'customerName';
        const list = searchType === SearchType.VENDOR ? 'vendorList' : searchType == SearchType.REGISTRATION_USER ? 'registrationUserList' : searchType == SearchType.EXPORT_USER ? 'exportUserList' : 'customerList';
        const cd = searchType === SearchType.VENDOR ? 'vendorCd' : searchType == SearchType.REGISTRATION_USER ? 'registrationUserCd' : searchType == SearchType.EXPORT_USER ? 'exportUserCd' : 'customerCd';
        const suggest = searchType === SearchType.VENDOR ? 'suggestVendor' : searchType == SearchType.REGISTRATION_USER ? 'suggestRegistrationUser' : searchType == SearchType.EXPORT_USER ? 'suggestExportUser' : 'suggestCustomer';

        if(searchType == SearchType.BEFORE_CUSTOMER){
          this[cd] = this.$refs[suggest].selected;
        }else{
          this.form[cd] = this.$refs[suggest].selected;
        }
        var nameTemp = this.suggestList[list].filter(value =>
            value.cd === this.$refs[suggest].selected)
        this[name] = nameTemp[0].name;
        this[list] = this.suggestList[list].length > 0 ? this.suggestList[list].filter(value =>
            value.cd === this.$refs[suggest].selected).map(value => {
            return {
                cd: value.cd,
                name: value.name
            }
        }) : [];

    },
    onblur(value1,searchType){
      const name = searchType === SearchType.VENDOR ? 'vendorName' : searchType == SearchType.REGISTRATION_USER ? 'registrationUserName' : searchType == SearchType.EXPORT_USER ? 'exportUserName' : 'customerName';
      const list = searchType === SearchType.VENDOR ? 'vendorList' : searchType == SearchType.REGISTRATION_USER ? 'registrationUserList' : searchType == SearchType.EXPORT_USER ? 'exportUserList' : 'customerList';
      var nameTemp = this.suggestList[list].filter(value =>
          value.cd === value1)
      this[name] = nameTemp.length == 0 ? "" : nameTemp[0].name
      this[list] = name.length > 0 ? this.suggestList[list].filter(value =>
          value.cd === value1).map(value => {
          return {
              cd: value.cd,
              name: value.name
          }
      }) : [];
    },
    // searchSystemUser(value, searchType) {
    //   console.log(this.firstInit)
    //   //画面遷移した時は実行しない
    //   if (this.firstInit){
    //     this.firstInit = false;
    //     return;
    //   }
    //   /*
    //   if (!this.canSearch)
    //     return;
    //   */
      

    //   const userName = searchType === SearchType.EXPORT_USER ? 'exportUserName' : 'registrationUserName';
    //   const userList = searchType === SearchType.EXPORT_USER ? 'exportUserList' : 'registrationUserList';
    //   const userCd = searchType === SearchType.EXPORT_USER ? 'exportUserCd' : 'registrationUserCd'

    //   if (!value) {
    //     this[userName] = '';
    //     this[userList] = [];
    //     return;
    //   }

    //   const request = {
    //     userCd: value,
    //     useName: ''
    //   };
    //   common.systemUser(request)
    //     .then((response) => {
    //       console.log(response);
    //       if (!this.form[userCd]) {
    //         this[userName] = '';
    //         this[userList] = [];
    //         return;
    //       }
    //       this[userName] = response.data.result.userList.length > 0 ?
    //         response.data.result.userList[0].userName : '';
    //       this[userList] = response.data.result.userList.length > 0 ?
    //         response.data.result.userList.slice(0,1).map(value => {
    //           return {
    //             cd: value.userCd,
    //             name: value.userName
    //           }
    //         }) : [];

    //       this.$emit('displayErrorMessage', response);
    //     })
    //     .catch((e) => {
    //       console.log('error', e);
    //       this[userName] = '';
    //       this[userList] = [];
    //       this.$emit('displayErrorMessage', e);
    //     });
    // },
    /**
     * 検索モーダル表示
     * @param modalId
     * @param isSingle
     * */
    showSearchModal(modalId, isSingle) {
      this.modalId = modalId;

      this.searchModalVal.modalId = modalId;
      this.searchModalVal.isSingle = isSingle;
      switch (modalId) {
        case SearchType.VENDOR:
          this.searchModalVal.selectedItemList = this.vendorList;
          break;
        case SearchType.EXPORT_USER:
          this.searchModalVal.selectedItemList = this.exportUserList;
          break;
        case SearchType.REGISTRATION_USER:
          this.searchModalVal.selectedItemList = this.registrationUserList;
          break;
        case SearchType.BEFORE_CUSTOMER:
          this.searchModalVal.selectedItemList = this.customerList;
          break;
      }
      //検索モーダル表示
      this.isSearchModalVisible = true;
    },
    /**
     * 検索条件追加
     * @param val
     * */
    addItem (val) {
      this.canSearch = false;
      switch (this.modalId) {
        case SearchType.VENDOR:
          this.vendorList = val;
          this.suggestList.vendorList = val;
          this.form.vendorCd = this.vendorList.length > 0 ? this.vendorList[0].cd : '';
          this.vendorName = this.vendorList.length > 0 ? this.vendorList[0].name : '';
          break;
        case SearchType.EXPORT_USER:
          this.exportUserList = val;
          this.suggestList.exportUserList = val;
          this.form.exportUserCd = this.exportUserList.length > 0 ? this.exportUserList[0].cd : '';
          this.exportUserName = this.exportUserList.length > 0 ? this.exportUserList[0].name : '';
          break;
        case SearchType.REGISTRATION_USER:
          this.registrationUserList = val;
          this.suggestList.registrationUserList = val;
          this.form.registrationUserCd = this.registrationUserList.length > 0 ? this.registrationUserList[0].cd : '';
          this.registrationUserName = this.registrationUserList.length > 0 ? this.registrationUserList[0].name : '';
          break;
        case SearchType.BEFORE_CUSTOMER:
          this.customerList = val;
          this.suggestList.customerList = val;
          this.customerCd = this.customerList.length > 0 ? this.customerList[0].cd : '';
          this.customerName = this.customerList.length > 0 ? this.customerList[0].name : '';
          break;
      }

      this.$nextTick(() => this.canSearch = true);
    },

    /**
     * 検索
     * */
    search() {

      this.$store.commit('setLoading', true);

      uploadCheckFile.search(this.form)
        .then((response) => {
          console.log(response);
          this.$refs.myTable.$refs.hotTable.hotInstance.loadData(response.data.result.fileList || []);
          this.rowCount = this.$refs.myTable.$refs.hotTable.hotInstance.countRows();

          // response.data.result.fileList.forEach((value, index) => {
          //   value.index = index + 1;
          // });
          // for (let i = 6; i < 1000; i++) {
          //   response.data.result.fileList.push({});
          // }
          this.$emit('displayErrorMessage', response);
          this.$store.commit('setLoading', false);
        })
        .catch((e) => {
          console.log('error', e);
          this.rowCount = 0;
          this.$refs.myTable.$refs.hotTable.hotInstance.loadData([]);
          this.$emit('displayErrorMessage', e);
          this.$store.commit('setLoading', false);
        });
    },
    /**
     * クリア
     */
    clear() {
      this.form.vendorCd = '';
      this.form.registrationUserCd = '';
      this.form.yearMonth = this.yearMonthList.length > 0 ? this.yearMonthList[0].yearMonthCd : '';
      this.form.exportUserCd = this.$store.state.userId;
    },

    /**
     * ドラッグ時にクラスを付与
     *
     * @param event
     * @param key
     * @param status
     * @returns {boolean}
     */
    checkDrag(event, key, status) {
      if (status && [...event.dataTransfer.types].includes('text/plain')) {
        //ファイルではなく、html要素をドラッグしてきた時は処理を中止
        return false
      }
      this.isDrag = status ? key : null;
    },

    /**
     * ドロップまたはファイル選択時にアップロードする
     *
     * @param event
     */
    onDrop(event) {
      this.isDrag = null;
      let fileList = event.target.files ? event.target.files : event.dataTransfer.files;
      // ファイルが無い時は処理を中止
      if(fileList.length === 0){
        return;
      }
      // ファイル数チェック
      // 1ファイル以上はエラーにする
      if (fileList.length > 1) {
        this.$emit('showSimpleModal', {
          type: DialogType.NOTICE,
          title: 'エラー',
          message: 'ファイルは一つずつ選択してください。'
        });
        return;
      }

      let messageList = [];
      const file =  fileList[0];
      // ファイルサイズチェック
      if (file.size === 0 || file.size > 20971520) {
        messageList.push('0MBを超え20MB以下のファイルを選択してください。');
      }
      // 拡張子チェック
      if (!file.name.toUpperCase().match(/\.(XLSX)$/i)) {
        messageList.push('XLSXファイルを選択してください。');
      }
      //エラーがある場合、モーダルで表示
      if (messageList.length > 0) {
        this.$emit('showSimpleModal', {
          type: DialogType.NOTICE,
          title: 'エラー',
          message: messageList.join('<br/>')
        });
        return;
      }

      this.$store.commit('setLoading', true);
      uploadCheckFile.getPresignedUrl({fileName: file.name})
        .then((response) => {
          console.log(response);
          this.upload(file, response.data.result.presignedUrl, response.data.result.fileId);
          event.target.value = null;
        })
        .catch((e) => {
          console.log('error', e);
          event.target.value = null;
          this.$emit('displayErrorMessage', e);
          this.$store.commit('setLoading', false);
        });
    },

    /**
     * アップロード
     *
     * @param file
     * @param url
     * @param fileId
     */
    upload(file, url, fileId) {
      uploadCheckFile.upload(file, url)
        .then((response) => {
          console.log(response);
          this.getFileInfo(fileId)
        })
        .catch((e) => {
          console.log('error', e);
          this.$emit('displayErrorMessage', e);
          this.$store.commit('setLoading', false);
        });
    },

    /**
     * ファイル情報取得
     *
     * @param fileId
     */
    getFileInfo(fileId) {
      uploadCheckFile.getFileInfo({fileId})
        .then((response) => {
          console.log(response);
          if (response.data.statusCd === 0 && !response.data.message) {
            setTimeout(() => this.getFileInfo(fileId), 1000);
          } else {
            this.$emit('displayErrorMessage', response);
            this.$store.commit('setLoading', false);
          }
        })
        .catch((e) => {
          console.log('error', e);
          this.$emit('displayErrorMessage', e);
          this.$store.commit('setLoading', false);
        });
    },

    messageRenderer(instance, td, row) {
      Handsontable.renderers.TextRenderer.apply(this, arguments);
      const table = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();

      //ステータスにカーソルを合わせた時に、ツールチップでmessageを表示する
      if (table[row]['message']) {
        return td.innerHTML = `<div title="${table[row]['message']}" class="text-primary">${td.innerHTML}</div>`;
      }
    }
  },
}
const VENDOR = 'vendor';
const CENTER = 'center';
const BRANCH = 'branch';
const CUSTOMER = 'customer';
const USER = 'user';
const EXPORT_USER = 'exportUser';
const REGISTRATION_USER = 'registrationUser';
const BEFORE_CUSTOMER = 'beforeCustomer';
const MAKER = 'maker';
const MASTER = 'master';
const BRANCH_CENTER = 'branch-center';
const TANTO = 'tanto';
const ITEM = 'item';
const REGISTERED_CUSTOMER = 'registeredCustomer';

export default {
  VENDOR: VENDOR,
  CENTER: CENTER,
  CUSTOMER: CUSTOMER,
  USER: USER,
  EXPORT_USER: EXPORT_USER,
  REGISTRATION_USER: REGISTRATION_USER,
  BEFORE_CUSTOMER: BEFORE_CUSTOMER,
  MAKER: MAKER,
  MASTER: MASTER,
  BRANCH: BRANCH,
  BRANCH_CENTER: BRANCH_CENTER,
  TANTO: TANTO,
  ITEM: ITEM,
  REGISTERED_CUSTOMER:REGISTERED_CUSTOMER,
}
import DialogType from '../../data/constant/dialog-type'
import SearchType from '../../data/constant/search-type'
import {exportCheckFile}  from '../../api/index';
import Ajv from 'ajv';
import HandsontableWrapper from "../../components/table/Handsontable/Handsontable.vue";
import ExportCheckFileModal from "../ExportCheckFileModal/ExportCheckFileModal.vue";
import ExportCheckFileDuplicateModal from "../ExportCheckFileDuplicateModal/ExportCheckFileDuplicateModal.vue";

//20211220 SSO対応 検索条件から名称セット
//20211224 SSO対応 クエリパラメータに担当者がある場合の検索、検索用URLの取得に対応
export default {
  name: 'ExportCheckFile',
  title: 'チェック表出力',

  components: {
    HandsontableWrapper,
    ExportCheckFileModal,
    ExportCheckFileDuplicateModal,
  },

  data: function() {
    return {
      VENDOR: SearchType.VENDOR,
      CENTER: SearchType.CENTER,
      CUSTOMER: SearchType.CUSTOMER,
      USER: SearchType.USER,
      modalId: '',
      isSearchModalVisible: false,
      searchModalVal: {},
      // confirmModalVal: {},
      isExportModalVisible: false,
      isDuplicateModalVisible: false,
      duplicateFileList: {},
      footerVal: {
        rightBtn: {
          text: 'ファイル出力',
          type: 'success',
        },
      },
      yearMonthList: [],
      vendorList: [],
      centerList: [],
      customerList: [],
      userList: [],

      form: {
        yearMonth: '',
        outType: '1'
      },
      searchCondition: {},
      rowCount: 0,
      tableSettings: {
        colHeaders: ['メーカー', '総売上', '総売上', '売買差益<br>額', '売買差益<br>額', '売買差益<br>率',
          '売買差益<br>率', '修正額', '修正後粗利', '粗利', '補填率', '集約', '前回<br>条件', '個別<br>出力', '特定列<br>非表示'],
        colWidths: [158, 72, 72, 72, 72, 72, 72, 80, 80, 80, 80, 48, 48, 48, 48],
        cells: this.cells,
        beforeChange: this.beforeChange,
        beforeOnCellMouseDown: this.beforeOnCellMouseDown,
      },
      exportSetting: {
        fileType: '1',
        inputFlg: false
      },
      isSearchUrlClick: false,
      isSearch: false
    }
  },
  created() {
    this.$store.commit('setLoading', true);
    this.yearMonthList = this.getStorage('yearMonthList') || [];
    this.form.yearMonth = this.yearMonthList.length > 0 ? this.yearMonthList[0].yearMonthCd : '';
    this.$store.commit('setLoading', false);
  },

  mounted() {
    const setting = {
      columns: [
        {data: "makerName", type: "text", className: "htLeft", readOnly: true, columnSorting: true},
        {data: "totalSales", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "totalSalesBefore", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "tradeMarginAmount", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "tradeMarginAmountBefore", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "tradeMarginRate", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "tradeMarginRateBefore", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "modifyingAmount", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "grossProfit", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "grossProfitBefore", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "inputRate", type: "numeric", className: "htRight", renderer: this.$refs.myTable.percentageRenderer, readOnly: true},
        {data: "aggregationFlg", type: "checkbox"},
        {data: "previousConditionFlg", type: "checkbox"},
        {data: "eachFileFlg", type: "checkbox"},
        {data: "displaySpecificArrayFlg", type: "checkbox"},
      ]};
    this.$refs.myTable.$refs.hotTable.hotInstance.updateSettings(setting, false);

    let hasParameter = false;
    if (this.$route.query.ym) {
      this.form.yearMonth = this.$route.query.ym;
    }
    if (this.$route.query.tantosha) {
      this.userList = this.$route.query.tantosha.split(",");
      this.userList = this.userList.map(value => { return { cd: value } });
      hasParameter = true;
    }
    if (this.$route.query.corp) {
      this.vendorList = this.$route.query.corp.split(",");
      this.vendorList = this.vendorList.map(value => { return { cd: value } });
      hasParameter = true;
    }
    if (this.$route.query.center) {
      this.centerList = this.$route.query.center.split(",");
      this.centerList = this.centerList.map(value => { return { cd: value } });
      hasParameter = true;
    }
    if (this.$route.query.customer) {
      this.customerList = this.$route.query.customer.split(",");
      this.customerList = this.customerList.map(value => { return { cd: value } });
    }
    if (hasParameter) {
      this.isSearch = true
      //this.search();
    }
  },

  /* *
   * 20211224 クエリパラメータがある場合はupdatedで検索
   * */
  updated() {
    if (this.isSearch) {
      this.search();
      this.isSearch = false
    } 
  },

  methods: {
    /**
     * 検索モーダル表示
     * */
    showSearchModal(modalId, isSingle) {
      this.modalId = modalId;

      this.searchModalVal.modalId = modalId;
      this.searchModalVal.isSingle = isSingle;
      switch (modalId) {
        case SearchType.VENDOR:
          this.searchModalVal.selectedItemList = this.vendorList;
          this.searchModalVal.userCd = this.userList.map(value => value.cd).join(',');
          break;
        case SearchType.CENTER:
          this.searchModalVal.selectedItemList = this.centerList;
          break;
        case SearchType.CUSTOMER:
          this.searchModalVal.selectedItemList = this.customerList;
          break;
        case SearchType.USER:
          this.searchModalVal.selectedItemList = this.userList;
          break;
      }
      //検索モーダル表示
      this.isSearchModalVisible = true;
    },
    /**
     * 検索
     * */
    search() {
      
      this.form.vendorCd = this.vendorList.map(value => value.cd);
      this.form.centerList = this.centerList.map(value => {
        const cdList = value.cd.split('-');
        return {
          departmentCd: cdList[0],
          centerCd: cdList[1]
        }
      });
      this.form.customerList = this.customerList.map(value => {
        const cdList = value.cd.split('-');
        return {
          customerHeadCd: cdList[0],
          customerBranchCd: cdList[1]
        }
      });
      this.form.userCd = this.userList.map(value => value.cd);

      const ajv = new Ajv({ allErrors: true });
      require('ajv-errors')(ajv);

      // 検証スキーマを定義
      const schema = {
        anyOf: [
          {
            properties: {
              vendorCd: {
                "type": "array",
                "minItems": 1
              }
            },
          },
          {
            properties: {
              userCd: {
                "type": "array",
                "minItems": 1
              }
            }
          },
          {
            properties: {
              centerList: {
                "type": "array",
                "minItems": 1
              }
            }
          },
        ],
        errorMessage: '担当者、企業、センター　いずれかの検索条件を指定してください。'
      };

      // バリデーション関数を作成
      const validate = ajv.compile(schema);

      // バリデーションを実行
      const valid = validate(this.form);

      // エラーの場合ダイアログでメッセージ表示
      if (!valid) {
        this.$emit('showSimpleModal', {
          type: DialogType.NOTICE,
          title: '確認',
          message: validate.errors.map(value => value.message).join('\n')
        });
        return;
      }

      // outTypeによって検索結果の項目名を変更する
      const colHeaders = this.$refs.myTable.$refs.hotTable.hotInstance.getSettings().colHeaders;
      if (this.form.outType === '1') {
        colHeaders.splice(1, 1, '総売上<br>今月');
        colHeaders.splice(2, 1, '総売上<br>前月');
        colHeaders.splice(3, 1, '売買差益<br>額今月');
        colHeaders.splice(4, 1, '売買差益<br>額前月');
        colHeaders.splice(5, 1, '売買差益<br>率今月');
        colHeaders.splice(6, 1, '売買差益<br>率前月');
        colHeaders.splice(9, 1, '前月粗利');
      } else {
        colHeaders.splice(1, 1, '総売上<br>今期');
        colHeaders.splice(2, 1, '総売上<br>前年');
        colHeaders.splice(3, 1, '売買差益<br>額今期');
        colHeaders.splice(4, 1, '売買差益<br>額前年');
        colHeaders.splice(5, 1, '売買差益<br>率今期');
        colHeaders.splice(6, 1, '売買差益<br>率前年');
        colHeaders.splice(9, 1, '前年粗利');
      }

      // 検索条件を保存する
      this.searchCondition = JSON.parse(JSON.stringify(this.form));

      this.$store.commit('setLoading', true);

      exportCheckFile.search(this.form)
        .then((response) => {
          console.log(response);
          this.$refs.myTable.$refs.hotTable.hotInstance.loadData(response.data.result.makerList || []);
          this.rowCount = this.$refs.myTable.$refs.hotTable.hotInstance.countRows();

          //集計の次から行番号を表示
          const setting = {rowHeaders: [null, ...[...Array(response.data.result.makerList.length).keys()].map(i => ++i)]};
          this.$refs.myTable.$refs.hotTable.hotInstance.updateSettings(setting, false);

          // for (let i = 6; i < 1000; i++) {
          //   this.table.data.push({
          //     index: i,
          //     makerCd: '5',
          //     makerName: '味覚糖' + i,
          //     totalSales: 50000,
          //     totalSalesBefore: 10000,
          //     tradeMarginAmount: 3100,
          //     tradeMarginAmountBefore: 1200,
          //     tradeMarginRate: 5.66,
          //     tradeMarginRateBefore: 6.34,
          //     modifyingAmount: 270,
          //     grossProfit: 2200,
          //     grossProfitBefore: 3000,
          //     inputRate: 27.14,
          //     aggregationFlg: true,
          //     previousConditionFlg: false,
          //     summaryFlg: false,
          //     eachFileFlg: false,
          //     displaySpecificArrayFlg: false
          //   });
          // }

          //20211220 SSO対応 検索条件名称セット
          this.vendorList = response.data.result.vendorList;
          this.centerList = response.data.result.centerList;
          this.customerList = response.data.result.customerList;
          this.userList = response.data.result.userList;

          this.$emit('displayErrorMessage', response);
          this.$store.commit('setLoading', false);
        })
        .catch((e) => {
          console.log('error', e);
          this.rowCount = 0;
          this.$emit('displayErrorMessage', e);
          this.$store.commit('setLoading', false);
        });
    },

    /**
     * 出力モーダル表示
     */
    showExportModal() {
      if (!this.$refs.myTable.$refs.hotTable.hotInstance.countRows()) {
        this.$emit('showSimpleModal', {
          type: DialogType.NOTICE,
          title: 'Webからのメッセージ',
          message: '検索結果がありません。'
        });
        return;
      }

      this.isExportModalVisible = true;
    },

    /**
     * 重複チェック
     * 重複データが存在する場合は、重複チェックモーダルを表示する
     * 重複データが存在しない場合は、ファイル出力処理を実行する
     */
    checkDuplicate() {
      this.isExportModalVisible = false;
      this.$store.commit('setLoading', true);

      if (this.exportSetting.inputFlg === true) {
        exportCheckFile.checkDuplicate(this.searchCondition)
          .then((response) => {
            console.log('response', response);
            this.$store.commit('setLoading', false);
            if (response.data.result.fileList.length > 0) {
              this.duplicateFileList = response.data.result.fileList;
              this.isDuplicateModalVisible = true;
            } else {
              this.exportFile()
            }
            this.$emit('displayErrorMessage', response);
          })
          .catch((e) => {
            console.log('error', e);
            this.$emit('displayErrorMessage', e);
            this.$store.commit('setLoading', false);
          })
      } else {
        this.exportFile()
      }
    },

    /**
     * チェック表出力
     */
    exportFile() {
      const tableData = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();
      const request = Object.assign(this.searchCondition, {fileList: tableData}, this.exportSetting);

      this.$store.commit('setLoading', true);
      this.isExportModalVisible = false;
      this.isDuplicateModalVisible = false;

      exportCheckFile.create(request)
        .then((response) => {
          console.log('response', response);
          this.$emit('displayErrorMessage', response);

          if (response.data.result.fileId) {
            this.confirmExportStatus(response.data.result);
          } else {
            this.$store.commit('setLoading', false);
          }
        })
        .catch((e) => {
          console.log('error', e);
          this.$emit('displayErrorMessage', e);
          this.$store.commit('setLoading', false);
        })
    },

    /**
     * チェック表作成状況確認
     * @param request
     */
    confirmExportStatus(request) {
      exportCheckFile.confirm(request)
        .then((response) => {
          console.log('response', response);
          if (response.data.statusCd === 0) {
            if (response.data.result.fileUrl) {
              this.download(response.data.result.fileUrl)
            } else {
              setTimeout(() => this.confirmExportStatus(request), 1000);
            }
          } else {
            this.$emit('displayErrorMessage', response);
            this.$store.commit('setLoading', false);
          }
        })
        .catch((e) => {
          console.log('error', e);
          this.$emit('displayErrorMessage', e);
          this.$store.commit('setLoading', false);
        })
    },

    /**
     * チェック表ダウンロード
     * @param fileUrl
     */
    download(fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.click();
      this.$store.commit('setLoading', false);
    },

    /**
     * 検索条件追加
     * @param val
     * */
    addItem(val) {
      switch (this.modalId) {
        case SearchType.VENDOR:
          this.vendorList = val;
          break;
        case SearchType.CENTER:
          this.centerList = val;
          break;
        case SearchType.CUSTOMER:
          this.customerList = val;
          break;
        case SearchType.USER:
          this.userList = val;
          break;
      }
    },

    /**
     * 検索条件クリア
     * @param value
     * */
    clear(value) {
      switch (value) {
        case SearchType.VENDOR:
          this.vendorList = [];
          break;
        case SearchType.CENTER:
          this.centerList = [];
          break;
        case SearchType.CUSTOMER:
          this.customerList = [];
          break;
        case SearchType.USER:
          this.userList = [];
          break;
      }

      if (value) return;

      this.form.yearMonth = this.yearMonthList.length > 0 ? this.yearMonthList[0].yearMonthCd : '';
      this.form.outType = '1';
      this.vendorList = [];
      this.centerList = [];
      this.customerList = [];
      this.userList = [];
    },

    cells(row, col, prop) {
      if (!this.$refs.myTable.$refs.hotTable.hotInstance) return;

      const columns = this.$refs.myTable.$refs.hotTable.hotInstance.getSettings().columns;
      if (columns.some(value => value.data === prop && value.type === 'checkbox')) {
        const table = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();
        if (table[row] && (!(prop in table[row]) || table[row][prop] === null)) {
          return {type: 'text', readOnly: true};
        }
      }
    },

    beforeChange(changes) {
      changes.forEach((element) => {
        const row = element[0];
        const prop = element[1];
        const col = this.$refs.myTable.$refs.hotTable.hotInstance.propToCol(prop);
        const oldVal = element[2];
        const newVal = element[3];
        if (typeof newVal === 'string' && this.$refs.myTable.$refs.hotTable.hotInstance.getCellMeta(row, col).type === 'checkbox') {
          switch (newVal) {
            case '':
            case 'false':
              element[3] = false;
              break;
            case 'true':
              element[3] = true;
              break;
            default:
              element[3] = oldVal;
          }
        }
      })
    },

    beforeOnCellMouseDown(e, coords) {
      // チェックボックス列のヘッダをクリックした時に、チェックボックスを全選択、または全解除する
      if(coords.row < 0 && this.$refs.myTable.$refs.hotTable.hotInstance.getCellMeta(coords.row, coords.col).type === 'checkbox') {
        const table = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();
        const prop = this.$refs.myTable.$refs.hotTable.hotInstance.colToProp(coords.col);
        if (table.some(value => value[prop] === false)) {
          table.filter(value => value[prop] === false).forEach(value => value[prop] = true)
        } else {
          table.filter(value => value[prop] === true).forEach(value => value[prop] = false)
        }
        this.$refs.myTable.$refs.hotTable.hotInstance.render();
      }
    },

    // 検索用URLをクリップボードにコピー
    createSearchUrl() {
      // URLを作成
      let url = window.location.protocol + '//' + window.location.host + '/export'

      // パラメータ追加処理
      // 担当者
      if (this.userList.length != 0) {
        let userQuery = '?tantosha='
        for (let i=0; i<this.userList.length; i++) {
          userQuery = userQuery + this.userList[i].cd + ','
          if (i == this.userList.length-1) {
            userQuery = userQuery.slice(0, -1)
          }
        }
        url = url + userQuery
      }
      // // 企業
      if (this.vendorList.length != 0) {
        let corpQuery = 'corp='
        for (let i=0; i<this.vendorList.length; i++) {
          corpQuery = corpQuery + this.vendorList[i].cd + ','
          if (i == this.vendorList.length-1) {
            corpQuery = corpQuery.slice(0, -1)
          }
        }
        if (url.match(/\?/)) {
          url = url + '&' + corpQuery
        } else {
          url = url + '?' + corpQuery
        }
      }
      // // センター
      if (this.centerList.length != 0) {
        let centerQuery = 'center='
        for (let i=0; i<this.centerList.length; i++) {
          centerQuery = centerQuery + this.centerList[i].cd + ','
          if (i == this.centerList.length-1) {
            centerQuery = centerQuery.slice(0, -1)
          }
        }
        if (url.match(/\?/)) {
          url = url + '&' + centerQuery
        } else {
          url = url + '?' + centerQuery
        }
      }
      // // 得意先
      if (this.customerList.length != 0) {
        let customerQuery = 'customer='
        for (let i=0; i<this.customerList.length; i++) {
          customerQuery = customerQuery + this.customerList[i].cd + ','
          if (i == this.customerList.length-1) {
            customerQuery = customerQuery.slice(0, -1)
          }
        }
        if (url.match(/\?/)) {
          url = url + '&' + customerQuery
        }
      }
      
      // クリップボードにコピー
      navigator.clipboard.writeText(url)

      // 吹き出しを表示
      this.isSearchUrlClick = true
      setTimeout(() => { this.isSearchUrlClick = false;}, 2000);

      // フォーカスを外す
      document.activeElement.blur()
    },
  },

}
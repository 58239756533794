import "@babel/polyfill";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import SearchModal from './components/modal/SearchModal/SearchModal'
import ConfirmModal from './components/modal/ConfirmModal/ConfirmModal'
import SimpleModal from './components/modal/SimpleModal/SimpleModal'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as filters from './plugins/filters';
import ToggleButton from 'vue-js-toggle-button'
import VueProgressBar from 'vue-progressbar'
import utils from './mixins/utils'
import title from './mixins/title'
import ProgressBar from './components/ProgressBar/ProgressBar'


Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.mixin(title);
Vue.mixin(utils);

Vue.use(BootstrapVue);

Vue.component('search-modal', SearchModal);
Vue.component('confirm-modal', ConfirmModal);
Vue.component('simple-modal', SimpleModal);
Vue.component('nav-header', Header);
Vue.component('nav-footer', Footer);

library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueAxios, axios);
Vue.use(ToggleButton);

Vue.component('progress-bar', ProgressBar);
Vue.use(VueProgressBar, {
  color: 'rgb(33, 150, 243)',
  thickness: '4px',
  autoFinish: false
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import ExportCheckFile from '../views/ExportCheckFile/ExportCheckFile.vue'
import UploadCheckFile from '../views/UploadCheckFile/UploadCheckFile.vue'
import RegisterAdms from '../views/RegisterAdms/RegisterAdms.vue'
import SpecialConversion from '../views/SpecialConversion/SpecialConversion.vue'
import SpecialConfirmation from '../views/SpecialConfirmation/SpecialConfirmation.vue'
import ErrorComponent from '../views/Error/Error.vue'
import SpecialSearch from '../views/SpecialSearch/SpecialSearch.vue'
import ProfitSearch from '../views/ProfitSearch/ProfitSearch.vue'
import ProfitRegist from '../views/ProfitRegist/ProfitRegist.vue'
import SpecialEdit from '../views/SpecialEdit/SpecialEdit.vue'
import authGuard from '../plugins/auth-guard'
import '../css/style.css'
Vue.use(VueRouter);
Vue.use(BootstrapVue);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

const routes = [
  {
    path: '/',
    redirect: {name: 'ExportCheckFile'}
  },
  {
    path: '/export',
    name: 'ExportCheckFile',
    component: ExportCheckFile,
    beforeEnter: authGuard
  },
  {
    path: '/upload',
    name: 'UploadCheckFile',
    component: UploadCheckFile,
    beforeEnter: authGuard
  },
  {
    path: '/register',
    name: 'RegisterADMS',
    component: RegisterAdms,
    beforeEnter: authGuard
  },
  {
    path: '/conversion',
    name: 'SpecialConversion',
    component: SpecialConversion,
    beforeEnter: authGuard
  },
  {
    path: '/tkd_search',
    name: 'SpecialSearch',
    component: SpecialSearch,
    beforeEnter: authGuard
  },
  {
    path: '/tkd_edit',
    name: 'SpecialEdit',
    component: SpecialEdit,
    beforeEnter: authGuard
  },
  {
    path: '/billing_search',
    name: 'SpecialConfirmation',
    component: SpecialConfirmation,
    beforeEnter: authGuard
  },
  {
    path: '/profit_search',
    name: 'ProfitSearch',
    component: ProfitSearch,
    beforeEnter: authGuard
  },
  {
    path: '/profit_regist',
    name: 'ProfitRegist',
    component: ProfitRegist,
    beforeEnter: authGuard
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorComponent
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

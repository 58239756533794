import {common, registerAdms}  from '../../api/index';
import HandsontableWrapper from "../../components/table/Handsontable/Handsontable.vue";
import SearchType from '../../data/constant/search-type'
import DialogType from "../../data/constant/dialog-type";

export default {
  name: 'ExportCheckFile',
  title: '特伝アップロード',
  components: {
    HandsontableWrapper
  },
  data: function() {
    return {
      REGISTRATION_USER: SearchType.REGISTRATION_USER,
      MAKER: SearchType.MAKER,
      modalId: '',
      makerList: [],
      makerName: '',
      isSearchModalVisible: false,
      searchModalVal: {},
      registrationUserList: [],
      registrationUserName: '',

      canSearch: true,
      confirmModalVal: {},
      footerVal: {
        rightBtn: {
          text: '登録',
          type: 'danger',
        },
      },
      form: {
        userCd: '',
        makerCd: ''
      },
      searchUserCd: '',
      rowCount: 0,
      tableSettings: {
        colHeaders: ['ファイル登録者', 'メーカー', '支店', '企業', '得意先', '担当', '請求額', '最終額', '最終率', 'まとめ切り'],
        colWidths: [126, 126, 126, 126, 126, 126, 100, 100, 64, 82],
        cells: this.cells,
        beforeChange: function(changes) {
          changes.forEach((element) => {
            const prop = element[1];
            const newVal = element[3];
            if (prop === 'registerTypeName' && (newVal === null || newVal === ''))
              element[3] = element[2];
          })
        },
      },

      registerTypeList: [],
      fileList: [],
    }
  },
  created() {
    this.form.userCd = this.$store.state.userId;
  },
  mounted() {
    const setting = {
      columns: [
        {data: "registerName", type: "text", className: "htLeft", readOnly: true},
        {data: "makerName", type: "text", className: "htLeft", readOnly: true},
        {data: "branchName", type: "text", className: "htLeft", readOnly: true},
        {data: "vendorName", type: "text", className: "htLeft", readOnly: true},
        {data: "customerName", type: "text", className: "htLeft", readOnly: true},
        {data: "userName", type: "text", className: "htLeft", readOnly: true},
        {data: "billingAmount", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "finalMarginAmount", type: "numeric", numericFormat: {pattern: '0,0'}, readOnly: true},
        {data: "finalMarginRate", type: "numeric", className: "htRight", renderer: this.$refs.myTable.percentageRenderer, readOnly: true},
        {data: "registerTypeName", type: "dropdown", className: "htLeft", strict: true, allowInvalid: false},
      ]};
    this.$refs.myTable.$refs.hotTable.hotInstance.updateSettings(setting, false);

    this.search();
  },
  methods: {
    showSearchModal(modalId, isSingle) {
      this.modalId = modalId;

      this.searchModalVal.modalId = modalId;
      this.searchModalVal.isSingle = isSingle;
      this.searchModalVal.selectedItemList = this.registrationUserList;
      //検索モーダル表示
      this.isSearchModalVisible = true;
    },

    /**
     * 検索条件追加
     * @param val
     * */
    addItem(val) {
      this.canSearch = false;

      switch (this.modalId) {
        case SearchType.REGISTRATION_USER:
          this.registrationUserList = val;
          this.form.userCd = this.registrationUserList.length > 0 ? this.registrationUserList[0].cd : '';
          this.registrationUserName = this.registrationUserList.length > 0 ? this.registrationUserList[0].name : '';
          break;
        case SearchType.MAKER:
          this.makerList = val;
          this.form.makerCd = this.makerList.length > 0 ? this.makerList[0].cd : '';
          this.makerName = this.makerList.length > 0 ? this.makerList[0].name : '';
          break;
      }

      this.$nextTick(() => this.canSearch = true);
    },

    search() {
      this.searchUserCd = this.form.userCd;
      this.$store.commit('setLoading', true);
      registerAdms.search(this.form)
        .then((response) => {
          console.log(response);
          this.registerTypeList = response.data.result.registerTypeList || [];
          this.fileList = response.data.result.fileList || [];
          this.$refs.myTable.$refs.hotTable.hotInstance.loadData(response.data.result.summaryList || []);
          this.rowCount = this.$refs.myTable.$refs.hotTable.hotInstance.countRows();

          this.$emit('displayErrorMessage', response);
          this.$store.commit('setLoading', false);
        })
        .catch((e) => {
          console.log('error', e);
          this.rowCount = 0;
          this.registerTypeList = [];
          this.fileList = [];
          this.$refs.myTable.$refs.hotTable.hotInstance.loadData([]);
          this.$emit('displayErrorMessage', e);
          this.$store.commit('setLoading', false);
        });
    },

    clear() {
      this.form.userCd = this.$store.state.userId;
      this.form.makerCd = '';
    },

    updateRegisterType(registerTypeCd) {
      if (!this.$refs.myTable.$refs.hotTable.hotInstance.countRows()) {
        this.$emit('showSimpleModal', {
          type: DialogType.NOTICE,
          title: 'エラー',
          message: '検索結果がありません。'
        });
        return;
      }

      this.$emit('showConfirmModal', {
        type: DialogType.INFO,
        title: '確認',
        message: 'まとめ切り設定を一括で設定します。よろしいですか？',
        confirm: () => {
          const registerTypeName = this.registerTypeList.find(registerType => registerType.registerTypeCd === registerTypeCd).registerTypeName;
          const tableData = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();
          tableData.forEach((value) => value.registerTypeName = registerTypeName);
          this.$refs.myTable.$refs.hotTable.hotInstance.render();
        }
      });
    },

    register() {
      if (!this.$refs.myTable.$refs.hotTable.hotInstance.countRows()) {
        this.$emit('showSimpleModal', {
          type: DialogType.NOTICE,
          title: 'エラー',
          message: '検索結果がありません。'
        });
        return;
      }
      if (!this.searchUserCd) {
        this.$emit('showSimpleModal', {
          type: DialogType.NOTICE,
          title: 'エラー',
          message: '検索時に登録者の指定がない場合は登録できません。'
        });
        return;
      }
      const tableData = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();

      tableData.forEach((value) =>
        value.registerTypeCd = this.registerTypeList.find(registerType =>
          registerType.registerTypeName === value.registerTypeName).registerTypeCd);

      const request = {
        registerList: tableData,
        fileList: this.fileList
      };
      this.$store.commit('setLoading', true);
      registerAdms.register(request)
        .then((response) => {
          console.log(response);
          this.$emit('displayErrorMessage', response);
          this.$store.commit('setLoading', false);
        })
        .catch((e) => {
          console.log('error', e);
          this.$emit('displayErrorMessage', e);
          this.$store.commit('setLoading', false);
        });
    },

    cells(row, cols, prop) {
      switch (prop) {
        case 'registerTypeName': {
          const cellProperties = {};
          cellProperties.source = this.registerTypeList.map(value => value.registerTypeName);
          return cellProperties;
        }
      }
    }
  },
  watch: {
    'form.userCd': function (value) {
      if (!this.canSearch)
        return;

      if (!value) {
        this.registrationUserName = '';
        this.registrationUserList = [];
        return;
      }

      const request = {
        userCd: value,
        useName: ''
      };
      common.systemUser(request)
        .then((response) => {
          console.log(response);
          if (!this.form.userCd) {
            this.registrationUserName = '';
            this.registrationUserList = [];
            return;
          }
          this.registrationUserName = response.data.result.userList.length > 0 ?
            response.data.result.userList[0].userName : '';
          this.registrationUserList = response.data.result.userList.length > 0 ?
            response.data.result.userList.slice(0,1).map(value => {
              return {
                cd: value.userCd,
                name: value.userName
              }
            }) : [];
          this.$emit('displayErrorMessage', response);
        })
        .catch((e) => {
          console.log('error', e);
          this.registrationUserName = '';
          this.registrationUserList = [];
          this.$emit('displayErrorMessage', e);
        });
    },

    'form.makerCd': function (value) {
      if (!this.canSearch)
        return;

      if (!value) {
        this.makerName = '';
        this.makerList = [];
        return;
      }

      const request = {
        makerCd: value,
        makerName: ''
      };
      common.maker(request)
        .then((response) => {
          console.log(response);
          if (!this.form.makerCd) {
            this.makerName = '';
            this.makerList = [];
            return;
          }
          this.makerName = response.data.result.makerList.length > 0 ?
            response.data.result.makerList[0].makerName : '';
          this.makerList = response.data.result.makerList.length > 0 ?
            response.data.result.makerList.slice(0,1).map(value => {
              return {
                cd: value.makerCd,
                name: value.makerName
              }
            }) : [];
          this.$emit('displayErrorMessage', response);
        })
        .catch((e) => {
          console.log('error', e);
          this.makerName = '';
          this.makerList = [];
          this.$emit('displayErrorMessage', e);
        });
    },
  },
}
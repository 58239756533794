/**
 * 数字の3桁ごとにカンマで区切る
 *
 * @param value
 * @returns {*}
 */export function addComma (value) {
  if (! value) { return value; }
  return value.toString().replace( /([0-9]+?)(?=(?:[0-9]{3})+$)/g , '$1,' );
}
/**
 * 文字列を省略して末尾を三点リーダー（…）にする
 *
 * @param value
 * @param length
 * @param omission
 * @returns {*}
 */
export function truncate (value, length, omission) {
  if (! value) { return value; }
  const truncatedLength = length ? parseInt(length, 10) : 20;
  const truncatedOmmision = omission ? omission.toString() : '...';

  if(value.length <= truncatedLength) {
    return value;
  }
  else {
    return value.substring(0, truncatedLength) + truncatedOmmision;
  }
}
/**
 *
 * 改行コードを</br>タグに置換する
 *
 * @param value
 * @returns {*}
 */
export function replaceLineBreaks (value) {
  if (! value) { return value; }
  return value.replace(/\\r\\n/g, '</br>').replace(/\\n/g, '</br>');
}
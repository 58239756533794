export default {
  name: 'ConfirmModal',
  props: {
    val: Object
  },
  mounted () {
    this.$refs.focus.focus();
  },
  methods: {
    confirm() {
      this.$emit('close');
      this.$emit('callParent', null)
    }
  },
  computed: {
    replacedMessage: {
      get: function() {
        return this.$options.filters.replaceLineBreaks(this.val.message);
      },
      set: function(val) {
        this.val.message = val;
      },
    },
  }
}
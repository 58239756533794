var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-2" }, [
                      _c("h3", { staticStyle: { "margin-bottom": "0" } }, [
                        _c(
                          "span",
                          { staticClass: "badge badge-info text-light" },
                          [
                            _c("font-awesome-icon", { attrs: { icon: "info" } })
                          ],
                          1
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "col-8 text-center",
                        staticStyle: { "margin-top": "8px" }
                      },
                      [
                        _c("span", { staticStyle: { "line-height": "25px" } }, [
                          _vm._v("出力")
                        ])
                      ]
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "modal-body text-left",
                  staticStyle: {
                    "max-height": "calc(100vh - 180px)",
                    "overflow-y": "auto"
                  }
                },
                [
                  _c("p", [
                    _vm._v(
                      "出力済みの条件入力用ファイルと対象データに重複があります。"
                    )
                  ]),
                  _vm._l(_vm.duplicateFileList, function(value, i) {
                    return _c("div", { key: i, staticClass: "file-list" }, [
                      _c("div", [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("出力担当: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(value.exportUserName))])
                      ]),
                      _c("div", [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("出力日時: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(value.exportDate))])
                      ]),
                      _c("div", [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("企業: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(value.vendorName))])
                      ]),
                      _c("div", [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("センター: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(value.centerName))])
                      ]),
                      _c("div", [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("得意先: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(value.customerName))])
                      ]),
                      _c("div", [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("担当者: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(value.userName))]),
                        _c("br")
                      ])
                    ])
                  }),
                  _c("p", [
                    _vm._v(
                      "ファイルを出力すると、上記ファイルの取込はできなくなります。"
                    ),
                    _c("br"),
                    _vm._v("よろしいですか？")
                  ])
                ],
                2
              ),
              _c("div", { staticClass: "modal-footer" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-4 offset-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-block",
                          on: {
                            click: function($event) {
                              return _vm.$emit("callParent")
                            }
                          }
                        },
                        [_vm._v(" 出力 ")]
                      )
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn page-link text-dark btn-block",
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [_vm._v(" キャンセル ")]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { common, commonBefore } from '../../../api';
import SearchType from '../../../data/constant/search-type';
import DialogType from '../../../data/constant/dialog-type'
const VueSimpleSuggest = require('vue-simple-suggest/dist/cjs');
import 'vue-simple-suggest/dist/styles.css'

export default {
  name: 'SearchModal',
  components: {
    VueSimpleSuggest
  },
  data: function () {
    return {
      suggestionList: [],
      suggestionNameList: [],

      VENDOR: SearchType.VENDOR,
      CENTER: SearchType.CENTER,
      CUSTOMER: SearchType.CUSTOMER,
      USER: SearchType.USER,
      EXPORT_USER: SearchType.EXPORT_USER,
      REGISTRATION_USER: SearchType.REGISTRATION_USER,
      BEFORE_CUSTOMER: SearchType.BEFORE_CUSTOMER,
      MAKER: SearchType.MAKER,
      MASTER: SearchType.MASTER,
      REGISTERED_CUSTOMER: SearchType.REGISTERED_CUSTOMER,
      BRANCH_CENTER: SearchType.BRANCH_CENTER,
      TANTO: SearchType.TANTO,
      ITEM: SearchType.ITEM,
      searchValue: "",
      itemList: []
    }
  },
  props: {
    val: {}
  },
  mounted() {
    switch (this.val.modalId) {
      // 企業検索
      case SearchType.VENDOR:
        this.$refs.suggest.setText(this.$store.state.vendor);
        this.suggestionList = this.getStorage('vendorList');
        this.suggestionNameList = this.suggestionList.map(value => value.vendorName);
        this.itemList = this.$store.state.vendorList;
        break;
      // センター検索
      case SearchType.CENTER:
        this.$refs.suggest.setText(this.$store.state.center);
        this.itemList = this.$store.state.centerList;
        break;
      // 得意先検索
      case SearchType.CUSTOMER:
        this.$refs.suggest.setText(this.$store.state.customer);
        this.itemList = this.$store.state.customerList;
        break;
      // 現行得意先検索
      case SearchType.BEFORE_CUSTOMER:
        this.$refs.suggest.setText(this.$store.state.beforeCustomer);
        this.itemList = this.$store.state.beforeCustomerList;
        break;
      // 担当者検索
      case SearchType.USER:
        this.$refs.suggest.setText(this.$store.state.user);
        this.suggestionList = this.getStorage('userList');
        this.suggestionNameList = this.suggestionList.map(value => value.userName);
        this.itemList = this.$store.state.userList;
        break;
      // システム利用ユーザー検索
      case SearchType.EXPORT_USER:
        this.$refs.suggest.setText(this.$store.state.exportUser);
        this.itemList = this.$store.state.exportUserList;
        break;
      case SearchType.REGISTRATION_USER:
        this.$refs.suggest.setText(this.$store.state.registrationUser);
        this.itemList = this.$store.state.registrationUserList;
        break;
      //メーカー検索
      case SearchType.MAKER:
        this.$refs.suggest.setText(this.$store.state.maker);
        this.itemList = this.$store.state.makerList;
        break;
      //主人検索
      case SearchType.MASTER:
        this.$refs.suggest.setText(this.$store.state.master[this.val.type] == undefined ? '' : this.$store.state.master[this.val.type]);
        this.itemList = this.$store.state.masterList[this.val.type] == undefined ? [] : this.$store.state.masterList[this.val.type];
        break;
    }
    this.changeSelectedItem();
    this.$refs.focus.focus();
  },
  methods: {
    selectSuggest() {
      switch (this.val.modalId) {
        // 企業検索
        case SearchType.VENDOR:
          this.itemList = this.suggestionList.filter(value =>
            value.vendorName === this.$refs.suggest.selected).map(value => {
              return {
                cd: value.vendorCd,
                name: value.vendorName,
                selected: true
              }
            });
          break;
        // 担当者検索
        case SearchType.USER:
          this.itemList = this.suggestionList.filter(value =>
            value.userName === this.$refs.suggest.selected).map(value => {
              return {
                cd: value.userCd,
                name: value.userName,
                selected: true
              }
            });
          break;
      }
    },

    trigger(event) {
      // Enterキー押下で検索を実行する
      // 日本語入力中のEnterキーは無効
      // サジェスト表示中は無効
      if (event.keyCode !== 13 || this.$refs.suggest.listShown) return;

      this.search()
    },
    /**
     * 検索
     */
    search() {

      let request = {};

      switch (this.val.modalId) {
        // 企業検索
        case SearchType.VENDOR:
          this.$store.commit('setLoading', true);
          request = {
            vendorCd: '',
            vendorName: this.searchValue
          };
          if ('userCd' in this.val) {
            request.userCd = this.val.userCd
          }
          common.vendor(request)
            .then((response) => {
              console.log(response);
              this.itemList = response.data.result.vendorList.map(value => {
                return {
                  cd: value.vendorCd,
                  name: value.vendorName,
                  selected: false
                }
              });
              this.changeSelectedItem();
              this.$emit('displayErrorMessage', response);
              this.$store.commit('setLoading', false);
            })
            .catch((e) => {
              console.log('error', e);
              this.$emit('displayErrorMessage', e);
              this.$store.commit('setLoading', false);
            });
          break;

        // センター検索
        case SearchType.CENTER:
          this.$store.commit('setLoading', true);
          request = {
            centerName: this.searchValue
          };
          common.center(request)
            .then((response) => {
              console.log(response);
              this.itemList = response.data.result.centerList.map(value => {
                return {
                  cd: value.departmentCd + '-' + value.centerCd,
                  name: value.centerName,
                  selected: false
                }
              });
              this.changeSelectedItem();
              this.$emit('displayErrorMessage', response);
              this.$store.commit('setLoading', false);
            })
            .catch((e) => {
              console.log('error', e);
              this.$emit('displayErrorMessage', e);
              this.$store.commit('setLoading', false);
            });
          break;

        // 得意先検索
        case SearchType.CUSTOMER:
          if (this.searchValue === '') {
            this.$emit('showSimpleModal', {
              type: DialogType.NOTICE,
              title: 'Webからのメッセージ',
              message: '検索ワードを入力してください'
            });
            return;
          }
          this.$store.commit('setLoading', true);
          request = {
            customerName: this.searchValue
          };
          common.customer(request)
            .then((response) => {
              console.log(response);
              this.itemList = response.data.result.customerList.map(value => {
                return {
                  cd: value.customerHeadCd + '-' + value.customerBranchCd,
                  name: value.customerName,
                  selected: false
                }
              });
              this.changeSelectedItem();
              this.$emit('displayErrorMessage', request);
              this.$store.commit('setLoading', false);
            })
            .catch((e) => {
              console.log('error', e);
              this.$emit('displayErrorMessage', e);
              this.$store.commit('setLoading', false);
            });
          break;

        // 現行得意先検索
        case SearchType.BEFORE_CUSTOMER:
          if (this.searchValue === '') {
            this.$emit('showSimpleModal', {
              type: DialogType.NOTICE,
              title: 'Webからのメッセージ',
              message: '検索ワードを入力してください'
            });
            return;
          }
          this.$store.commit('setLoading', true);
          request = {
            customerCd: '',
            customerName: this.searchValue
          };
          commonBefore.customer(request)
            .then((response) => {
              console.log(response);
              this.itemList = response.data.result.customerList.map(value => {
                return {
                  cd: value.customerCd,
                  name: value.customerName,
                  selected: false
                }
              });
              this.changeSelectedItem();
              this.$emit('displayErrorMessage', request);
              this.$store.commit('setLoading', false);
            })
            .catch((e) => {
              console.log('error', e);
              this.$emit('displayErrorMessage', e);
              this.$store.commit('setLoading', false);
            });
          break;

        // 担当者検索
        case SearchType.USER:
          this.$store.commit('setLoading', true);
          request = {
            userCd: '',
            userName: this.searchValue
          };
          common.user(request)
            .then((response) => {
              console.log(response);
              this.itemList = response.data.result.userList.map(value => {
                return {
                  cd: value.userCd,
                  name: value.userName,
                  selected: false
                }
              });
              this.changeSelectedItem();
              this.$emit('displayErrorMessage', request);
              this.$store.commit('setLoading', false);
            })
            .catch((e) => {
              console.log('error', e);
              this.$emit('displayErrorMessage', e);
              this.$store.commit('setLoading', false);
            });
          break;

        // システム利用ユーザー検索
        case SearchType.EXPORT_USER:
        case SearchType.REGISTRATION_USER:
          this.$store.commit('setLoading', true);
          request = {
            userCd: '',
            userName: this.searchValue
          };
          common.systemUser(request)
            .then((response) => {
              console.log(response);
              this.itemList = response.data.result.userList.map(value => {
                return {
                  cd: value.userCd,
                  name: value.userName,
                  selected: false
                }
              });
              this.changeSelectedItem();
              this.$emit('displayErrorMessage', request);
              this.$store.commit('setLoading', false);
            })
            .catch((e) => {
              console.log('error', e);
              this.$emit('displayErrorMessage', e);
              this.$store.commit('setLoading', false);
            });
          break;

        //メーカー検索
        case SearchType.MAKER:
          this.$store.commit('setLoading', true);
          request = {
            makerCd: '',
            makerName: this.searchValue
          };
          common.maker(request)
            .then((response) => {
              console.log(response);
              this.itemList = response.data.result.makerList.map(value => {
                return {
                  cd: value.makerCd,
                  name: value.makerName,
                  selected: false
                }
              });
              this.changeSelectedItem();
              this.$emit('displayErrorMessage', request);
              this.$store.commit('setLoading', false);
            })
            .catch((e) => {
              console.log('error', e);
              this.$emit('displayErrorMessage', e);
              this.$store.commit('setLoading', false);
            });
          break;

        //マスタ検索
        case SearchType.MASTER:
          this.$store.commit('setLoading', true);
          request = {
            searchKbn: 2,
            type: this.val.type,
            searches: [this.searchValue],
          };
          if (this.val.type == SearchType.BRANCH_CENTER) {
            var self = this
            self.itemList = self.getStorage('centerList').map(value => {
              return {
                cd: value.departmentCd + '-' + value.warehouseCd,
                name: self.getStorage('branchList').filter(value1 => value1.departmentCd === value.departmentCd)[0].departmentName.trim() + ' ' + value.warehouseName.trim(),
                selected: false
              }
            })
            var temp = self.itemList.filter(value =>
              value.cd.indexOf(this.searchValue) >= 0 || value.name.indexOf(this.searchValue) >= 0
            )
            self.itemList = temp;
            if (this.searchValue == '-') {
              self.itemList = []
            }
            self.changeSelectedItem();
            self.$store.commit('setLoading', false);
          } else {
            if (this.val.type == this.ITEM || this.val.type == this.CUSTOMER) {
              if (this.searchValue === '') {
                this.$emit('showSimpleModal', {
                  type: DialogType.NOTICE,
                  title: 'Webからのメッセージ',
                  message: '検索ワードを入力してください'
                });
                this.$store.commit('setLoading', false);
                return;
              }
            }
            common.master(request)
              .then((response) => {
                console.log(response);
                this.itemList = response.data.result.summaryList.map(value => {
                  return {
                    cd: value.cd,
                    name: value.name,
                    selected: false
                  }
                });
                this.changeSelectedItem();
                this.$emit('displayErrorMessage', request);
                this.$store.commit('setLoading', false);
              })
              .catch((e) => {
                console.log('error', e);
                this.$emit('displayErrorMessage', e);
                this.$store.commit('setLoading', false);
              });
          }
          break;
      }
    },
    /**
     * 選択アイテム変更
     * 検索結果に選択済みのアイテムがあれば、ステータスを選択済みにする
     */
    changeSelectedItem() {
      const selectedCdList = this.val.selectedItemList.map(value => value.cd);
      this.itemList.filter(value => selectedCdList.indexOf(value.cd) >= 0)
        .map(value => value.selected = true);
    },
    /**
     * クリア
     */
    clear() {
      this.$refs.suggest.setText('');
    },
    /**
     * 行選択
     * @param i
     */
    selectRow(i) {
      this.itemList[i].selected = !this.itemList[i].selected;
      if (this.val.isSingle && this.itemList[i].selected) {
        this.itemList.filter((value, index) => value.selected === true && index !== i).forEach((value) => value.selected = false);
      }
    },
    //全選択、全解除
    selectAll(isSelect) {
      this.itemList.forEach(item => item.selected = isSelect);
    },
    /**
     * 選択
     */
    select() {
      const cdList = this.itemList.map(value => value.cd);
      this.val.selectedItemList = this.val.selectedItemList.filter(value => cdList.indexOf(value.cd) < 0);

      if (this.val.isSingle) {
        if (!(this.val.selectedItemList.length > 0 && this.itemList.filter(value => value.selected === true).length === 0)) {
          this.val.selectedItemList = this.itemList.filter(value => value.selected === true);
        }
      } else {
        Array.prototype.push.apply(this.val.selectedItemList, this.itemList.filter(value => value.selected === true));
        // CD昇順でソート
        this.val.selectedItemList.sort((a, b) => a.cd - b.cd);
      }

      this.close();
      this.$emit('callParent', this.val.selectedItemList)
    },
    /**
     * 閉じる
     */
    close() {
      this.itemList = this.itemList.map(value => {
        return {
          cd: value.cd,
          name: value.name,
          selected: false
        }
      });
      switch (this.val.modalId) {
        // 企業検索
        case SearchType.VENDOR:
          this.$store.commit('setVendor', this.searchValue);
          this.$store.commit('setVendorList', this.itemList);
          break;
        // センター検索
        case SearchType.CENTER:
          this.$store.commit('setCenter', this.searchValue);
          this.$store.commit('setCenterList', this.itemList);
          break;
        // 得意先検索
        case SearchType.CUSTOMER:
          this.$store.commit('setCustomer', this.searchValue);
          this.$store.commit('setCustomerList', this.itemList);
          break;
        // 現行得意先検索
        case SearchType.BEFORE_CUSTOMER:
          this.$store.commit('setBeforeCustomer', this.searchValue);
          this.$store.commit('setBeforeCustomerList', this.itemList);
          break;
        // 担当者検索
        case SearchType.USER:
          this.$store.commit('setUser', this.searchValue);
          this.$store.commit('setUserList', this.itemList);
          break;
        // システム利用ユーザー検索
        case SearchType.EXPORT_USER:
          this.$store.commit('setExportUser', this.searchValue);
          this.$store.commit('setExportUserList', this.itemList);
          break;
        case SearchType.REGISTRATION_USER:
          this.$store.commit('setRegistrationUser', this.searchValue);
          this.$store.commit('setRegistrationUserList', this.itemList);
          break;
        //メーカー検索
        case SearchType.MAKER:
          this.$store.commit('setMaker', this.searchValue);
          this.$store.commit('setMakerList', this.itemList);
          break;
        //メーカー検索
        case SearchType.MASTER:
          this.$store.commit('setMaster', [this.searchValue, this.val.type]);
          this.$store.commit('setMasterList', [this.itemList, this.val.type]);
          if (this.$parent.rowSelected != undefined && this.$parent.colSelected != undefined) {
            this.$parent.$refs.hotTable.hotInstance.selectCell(this.$parent.rowSelected, this.$parent.colSelected);
          }
          break;
      }

      this.$emit('close');
    }
  }
}
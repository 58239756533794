import SearchType from '../../data/constant/search-type'
import { common, special } from '../../api/index';
import moment from 'moment';
import HandsontableWrapper from "../../components/table/Handsontable/Handsontable.vue";
import DialogType from '../../data/constant/dialog-type';
import MessageError from '../../data/constant/message-error';
const VueSimpleSuggest = require('vue-simple-suggest/dist/cjs');
import 'vue-simple-suggest/dist/styles.css'
export default {
    name: 'SpecialSearch',
    title: '特伝検索',

    components: {
        HandsontableWrapper,
        VueSimpleSuggest
    },

    data: function () {
        return {
            REGISTRATION_USER: SearchType.REGISTRATION_USER,
            REGISTERED_CUSTOMER: SearchType.REGISTERED_CUSTOMER,
            MAKER: SearchType.MAKER,
            CENTER: SearchType.CENTER,
            CUSTOMER: SearchType.CUSTOMER,
            USER: SearchType.USER,
            MASTER: SearchType.MASTER,
            TANTO: SearchType.TANTO,
            modalId: '',
            isSearchModalVisible: false,
            searchModalVal: {},
            isExportModalVisible: false,
            isDuplicateModalVisible: false,
            footerVal: {
                rightBtnL1: {
                    text: '削除',
                    type: 'danger',
                    isDisabled: true,
                },
                rightBtn: {
                    text: '編集',
                    type: 'success',
                    isDisabled: true,
                },
            },
            table: { data: [] },
            yearMonthList: [],
            registrationUserList: [],
            registrationUserName: '',
            makerList: [],
            centerList: [],
            customerList: [],
            userList: [],
            form: {
                yearMonth: '',
                registrationUserCd: '',
            },
            rowCount: 0,
            tableSettings: {
                colHeaders: ['', '登録者', '請求先', 'メーカー', '支店', 'センター', '発生日', '得意先', '担当者',
                    '商品名', '入数', 'ボール数', '単価', '条件%', '条件@', '請求金額', 'コメント', '税率', '種別', '作成種別', 'ID'],
                colWidths: [30, 72, 85, 65, 85, 90, 80, 80, 90, 100, 60, 60, 60, 60, 60, 85, 65, 60, 60, 65, 70],
                cells: this.cells,
                afterChange: this.afterChange,
                manualColumnResize: true,
                manualRowResize: true,
                rowHeaders: true,
                copyPaste: {rowsLimit: 10000,},
            },
            exportSetting: {
                fileType: '1',
                inputFlg: false
            },
            isHide: false,
            canSearch: false,
            hiddenCheckBox: [],
            edit: false,
            moveFromEdit: this.$route.query.move_from_edit,
            suggestList: [],
            lastInput: "",
        }
    },
    created() {
        this.$store.commit('setLoading', true);
        this.yearMonthList = this.getStorage('lastYearMonthList') || [];
        const conditionSearch = this.$store.state.conditionSearchTkd || [];
        if (conditionSearch.length > 0) {
            this.form.yearMonth = conditionSearch[0].yearMonth;
            this.form.registrationUserCd = conditionSearch[0].registrationUserCd;
            this.registrationUserName = conditionSearch[0].registrationUserName;
            this.makerList = conditionSearch[0].makerList;
            this.centerList = conditionSearch[0].centerList;
            this.customerList = conditionSearch[0].customerList;
            this.userList = conditionSearch[0].userList;
            this.registrationUserList = conditionSearch[0].registrationUserList;
        } else {
            this.form.registrationUserCd = this.$store.state.userId;
            this.registrationUserName = this.$store.state.userName;
            this.registrationUserList = [{ 'cd': this.form.registrationUserCd, 'name': this.registrationUserName }]
            this.form.yearMonth = this.yearMonthList.length > 0 ? this.yearMonthList[0].yearMonthCd : '';
        }
        this.$store.commit('setLoading', false);
    },

    beforeRouteLeave(to, form, next) {
        var conditionSearch = {};
        conditionSearch['makerList'] = this.makerList;
        conditionSearch['centerList'] = this.centerList;
        conditionSearch['customerList'] = this.customerList;
        conditionSearch['userList'] = this.userList;
        conditionSearch['yearMonth'] = this.form.yearMonth;
        conditionSearch['registrationUserCd'] = this.form.registrationUserCd;
        conditionSearch['registrationUserName'] = this.registrationUserName;
        conditionSearch['registrationUserList'] = this.registrationUserList;
        this.$store.commit('setConditionSearchTkd', [JSON.parse(JSON.stringify(conditionSearch))]);
        next();
    },

    mounted() {
        const setting = {
            columns: [
                { data: "editFlg", type: "checkbox", className: "htCenter" },
                { data: "registerUserName", type: "text", className: "htLeft text-nowrap", readOnly: true, columnSorting: true },
                { data: "requestName", type: "text", className: "htLeft text-nowrap", readOnly: true },
                { data: "makerName", type: "text", className: "htLeft text-nowrap", readOnly: true, columnSorting: true },
                { data: "departmentName", type: "text", className: "htLeft text-nowrap", readOnly: true },
                { data: "warehouseName", type: "text", className: "htLeft text-nowrap", readOnly: true },
                { data: "occurDate", type: "text", className: "text-nowrap", readOnly: true },
                { data: "customerName", type: "text", className: "htLeft text-nowrap", readOnly: true },
                { data: "tantoUserName", type: "text", className: "htLeft text-nowrap", readOnly: true },
                { data: "itemName", type: "text", className: "htLeft text-nowrap", readOnly: true },
                { data: "irisu", type: "numeric", className: "htRight text-nowrap", readOnly: true, numericFormat: { pattern: '0,0' } },
                { data: "bowlNumber", type: "numeric", className: "htRight text-nowrap", readOnly: true, numericFormat: { pattern: '0,0' } },
                { data: "bid", type: "numeric", className: "htRight text-nowrap", readOnly: true, renderer: this.handleTextColor, numericFormat: { pattern: '0,0.00' } },
                { data: "conditionPercent", type: "numeric", className: "htRight text-nowrap", readOnly: true, numericFormat: { pattern: '0,0.00' } },
                { data: "conditionAt", type: "numeric", className: "htRight text-nowrap", readOnly: true, numericFormat: { pattern: '0,0.00' } },
                { data: "billingAmount", type: "numeric", className: "htRight text-nowrap", numericFormat: { pattern: '0,0' }, readOnly: true },
                { data: "comment", type: "text", className: "htLeft text-nowrap", readOnly: true },
                { data: "taxRate", type: "text", className: "htLeft text-nowrap", readOnly: true },
                { data: "requestType", type: "text", className: "htLeft text-nowrap", readOnly: true },
                { data: "registType", type: "text", className: "htLeft text-nowrap", readOnly: true },
                { data: "tokudenId", type: "text", className: "htLeft text-nowrap", readOnly: true },
            ]
        };
        this.$refs.myTable.$refs.hotTable.hotInstance.updateSettings(setting, false);
        if (this.moveFromEdit) {
            this.search();
        }
    },

    methods: {
        // searchSystemUser(value) {
        //     if (!this.canSearch) {
        //         this.canSearch = true;
        //         return;
        //     }

        //     const userName = 'registrationUserName';
        //     const userList = 'registrationUserList';
        //     const userCd = 'registrationUserCd';

        //     if (!value) {
        //         this[userName] = '';
        //         this[userList] = [];
        //         return;
        //     }

        //     const request = {
        //         userCd: value,
        //         useName: ''
        //     };
        //     common.systemUser(request)
        //         .then((response) => {
        //             console.log(response);
        //             if (!this.form[userCd]) {
        //                 this[userName] = '';
        //                 this[userList] = [];
        //                 return;
        //             }
        //             this[userName] = response.data.result.userList.length > 0 ?
        //                 response.data.result.userList[0].userName : '';
        //             this[userList] = response.data.result.userList.length > 0 ?
        //                 response.data.result.userList.slice(0, 1).map(value => {
        //                     return {
        //                         cd: value.userCd,
        //                         name: value.userName
        //                     }
        //                 }) : [];

        //             this.$emit('displayErrorMessage', response);
        //         })
        //         .catch((e) => {
        //             console.log('error', e);
        //             this[userName] = '';
        //             this[userList] = [];
        //             this.$emit('displayErrorMessage', e);
        //         });
        // },
        suggestSearch(value) {
            const userName = 'registrationUserName';
            const userList = 'registrationUserList';
            const userCd = 'registrationUserCd';

            if (!value) {
                this[userName] = '';
                this[userList] = [];
                this.$refs.suggest.suggestions = [];
                return;
            }
            this.lastInput = value;
            const request = {
                userCd: value,
                useName: ''
            };
            this.registrationUserList = [];
            this.registrationUserName =  "";
            common.systemUser(request)
                .then((response) => {
                    if (!this.form[userCd]) {
                        this[userName] = '';
                        this[userList] = [];
                        this.$refs.suggest.suggestions = [];
                        return;
                    }
                    if(this.lastInput == request.userCd){
                        this.suggestList = response.data.result.userList.map(value => { return { cd: value.userCd, name: value.userName } });
                        this.$refs.suggest.suggestions = response.data.result.userList.map(value => value.userCd);
                        this.$refs.suggest.input.click();
                    }
                })
                .catch((e) => {
                    console.log('error', e);
                });
        },
        selectSuggest() {
            this.form.registrationUserCd = this.$refs.suggest.selected;
            var name = this.suggestList.filter(value =>
                value.cd === this.$refs.suggest.selected)
            this.registrationUserName = name[0].name;
            this['registrationUserList'] = this.suggestList.length > 0 ? this.suggestList.filter(value =>
                value.cd === this.$refs.suggest.selected).map(value => {
                return {
                    cd: value.cd,
                    name: value.name
                }
            }) : [];

        },
        onblur(value1){
            var name = this.suggestList.filter(value =>
                value.cd === value1)
            this.registrationUserName = name.length == 0 ? "" : name[0].name
            this['registrationUserList'] = name.length > 0 ? this.suggestList.filter(value =>
                value.cd === value1).map(value => {
                return {
                    cd: value.cd,
                    name: value.name
                }
            }) : [];
        },
        /**
         * 検索モーダル表示
         * */
        showSearchModal(modalId, isSingle) {

            this.modalId = modalId;

            this.searchModalVal.modalId = modalId;
            this.searchModalVal.isSingle = isSingle;
            switch (modalId) {
                case SearchType.CENTER:
                    this.searchModalVal.selectedItemList = this.centerList;
                    break;
                case SearchType.CUSTOMER:
                    this.searchModalVal.selectedItemList = this.customerList;
                    break;
                case SearchType.USER:
                    this.searchModalVal.selectedItemList = this.userList;
                    break;
                case SearchType.MAKER:
                    this.searchModalVal.selectedItemList = this.makerList;
                    break;
                case SearchType.REGISTRATION_USER:
                    this.searchModalVal.selectedItemList = this.registrationUserList;
                    break;
            }
            //検索モーダル表示
            this.isSearchModalVisible = true;
        },
        /**
         * マスタ検索モーダル表示
         * 
         */
        showMasterSearchModal(modalId, type, isSingle) {
            this.modalId = modalId;
            this.searchModalVal.type = type;
            this.searchModalVal.modalId = modalId;
            this.searchModalVal.isSingle = isSingle;

            switch (type) {
                /*             
                case SearchType.CENTER:
                    this.searchModalVal.selectedItemList = this.centerList;
                    break;
                */
                case SearchType.REGISTERED_CUSTOMER:
                    this.searchModalVal.selectedItemList = this.customerList;
                    break;
                case SearchType.TANTO:
                    this.searchModalVal.selectedItemList = this.userList;
                    break;
                /*
                case SearchType.MAKER:
                    this.searchModalVal.selectedItemList = this.makerList;
                    break;
                case SearchType.REGISTRATION_USER:
                    this.searchModalVal.selectedItemList = this.registrationUserList;
                    break;
                */
            }

            //検索モーダル表示
            this.isSearchModalVisible = true;
        },

        /**
         * 検索
         * */
        search() {

            this.form.requestCd = this.makerList.map(value => {
                const cdList = value.cd.split('-');
                return cdList[0]
            });

            this.form.centerList = this.centerList.map(value => {
                const cdList = value.cd.split('-');
                return {
                    departmentCd: cdList[0],
                    centerCd: cdList[1]
                }
            });
            this.form.customerList = this.customerList.map(value => {
                var cd = value.cd;
                return {
                    customerHeadCd: cd.substring(0, 5),
                    customerBranchCd: cd.substring(5, 8)
                }
            });
            this.form.tantoUserCd = this.userList.map(value => {
                const cdList = value.cd.split('-');
                return cdList[0]
            });
            console.log(this.form);

            if (this.form.registrationUserCd.length === 0 && this.form.requestCd.length === 0 &&
                this.form.centerList.length === 0 && this.form.customerList.length === 0 &&
                this.form.tantoUserCd.length === 0) {
                this.$emit('showSimpleModal', {
                    type: DialogType.NOTICE,
                    title: 'エラー',
                    message: '登録者、請求先、センター、得意先、担当者　' + MessageError.MASTER,
                });
                return;
            }
            this.$store.commit('setLoading', true);
            console.log(this.$store.state.conditionSearchTkd)
            special.search(this.form)
                .then((response) => {
                    var rs = response.data.result.responseList;
                    this.hiddenCheckBox = [];
                    for (let i = 0; i < response.data.result.responseList.length; i++) {
                        const element = response.data.result.responseList[i];
                        rs[i].occurDate = element.occurDate ? moment(element.occurDate).format('YYYY/MM/DD') : '';
                        if (!rs[i].editFlg) {
                            rs[i].editFlg = null;
                        }
                        this.hiddenCheckBox.push(rs[i].editFlg);
                    }
                    console.log(response);
                    this.$refs.myTable.$refs.hotTable.hotInstance.loadData(rs || []);
                    this.rowCount = this.$refs.myTable.$refs.hotTable.hotInstance.countRows();
                    let disableFlg = false;
                    for (let j = 0; j < this.hiddenCheckBox.length; j++) {
                        if (!this.hiddenCheckBox[j]) {
                            this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(j, 0, 'className', 'htCenter hidden');
                            this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(j, 0, 'readOnly', true);
                            this.$refs.myTable.$refs.hotTable.hotInstance.render();
                        }
                        if (disableFlg) {
                            continue;
                        }
                        if (this.hiddenCheckBox[j]) {
                            disableFlg = true;
                        }
                    }
                    if (disableFlg) {
                        this.footerVal.rightBtn.isDisabled = false;
                        this.footerVal.rightBtnL1.isDisabled = false;
                    } else {
                        this.footerVal.rightBtn.isDisabled = true;
                        this.footerVal.rightBtnL1.isDisabled = true;
                    }
                    this.$emit('displayErrorMessage', response);
                    this.$store.commit('setLoading', false);
                })
                .catch((e) => {
                    console.log('error', e);
                    this.rowCount = 0;
                    this.registerTypeList = [];
                    this.fileList = [];
                    this.$refs.myTable.$refs.hotTable.hotInstance.loadData([]);
                    this.$emit('displayErrorMessage', e);
                    this.$store.commit('setLoading', false);
                });
        },

        /**
         * 隠しボタンを処理する
         * @param {*} changes 
         */
        afterChange: function (changes) {
            if (changes) {
                const hot = this.$refs.myTable.$refs.hotTable.hotInstance;
                // let r = changes[0][0]; //row index
                let c = hot.propToCol(changes[0][1]); //col index

                const data = hot.getSourceData();
                if (c === 0 && data.find(value => value.editFlg === true)) {
                    this.footerVal.rightBtn.isDisabled = false;
                    this.footerVal.rightBtnL1.isDisabled = false;
                } else {
                    this.footerVal.rightBtn.isDisabled = true;
                    this.footerVal.rightBtnL1.isDisabled = true;
                }
            }
        },

        /**
         * 検索条件追加
         * @param val
         * */
        addItem(val) {
            this.canSearch = false;
            switch (this.modalId) {
                case SearchType.REGISTRATION_USER:
                    this.registrationUserList = val;
                    this.form.registrationUserCd = this.registrationUserList.length > 0 ? this.registrationUserList[0].cd : '';
                    this.registrationUserName = this.registrationUserList.length > 0 ? this.registrationUserList[0].name : '';
                    break;
                case SearchType.MAKER:
                    this.makerList = val;
                    // this.form.requestCd = this.makerList.length > 0 ? this.makerList[0].cd : '';
                    break;
                case SearchType.CENTER:
                    this.centerList = val;
                    break;
                case SearchType.CUSTOMER:
                    this.customerList = val;
                    break;
                case SearchType.USER:
                    this.userList = val;
                    // this.form.tantoUserCd = this.userList.length > 0 ? this.userList[0].cd : '';
                    // this.userName = this.userList.length > 0 ? this.userList[0].name : '';
                    break;
                case SearchType.MASTER:
                    switch (this.searchModalVal.type) {
                        case SearchType.TANTO:
                            this.userList = val;
                            break;
                        case SearchType.REGISTERED_CUSTOMER:
                            this.customerList = val;
                            break;
                    }
                    break;
            }
            this.suggestList = val;
            this.$nextTick(() => this.canSearch = true);
        },

        /**
         * 検索条件クリア
         * @param value
         * */
        clear(value) {
            switch (value) {
                case SearchType.CENTER:
                    this.centerList = [];
                    break;
                case SearchType.REGISTERED_CUSTOMER:
                    this.customerList = [];
                    break;
                case SearchType.USER:
                    this.userList = [];
                    break;
                case SearchType.MAKER:
                    this.makerList = [];
                    break;
                case SearchType.REGISTRATION_USER:
                    this.registrationUserList = [];
                    break;
            }

            if (value) return;

            this.form.yearMonth = this.yearMonthList.length > 0 ? this.yearMonthList[0].yearMonthCd : '';
            this.form.outType = '1';
            this.centerList = [];
            this.customerList = [];
            this.userList = [];
            this.makerList = [];
            this.form.registrationUserCd = this.$store.state.userId || '';
            this.registrationUserName = this.$store.state.userName || '';
            this.registrationUserList = [{ 'cd': this.form.registrationUserCd, 'name': this.registrationUserName }];
        },
        moveEdit() {
            var data = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();
            var tokudenIdList = data.filter(value =>
                value.editFlg === true).map(value => {
                    return parseInt(value.tokudenId, 10)
                });
            this.edit = true;
            this.$store.commit('setTokudenIdList', tokudenIdList);
            this.$router.push({ path: '/tkd_edit', query: { move_from_search: true } })
        },
        hideColumns() {
            this.$store.commit('setLoading', true);
            if (!this.isHide) {
                this.$refs.myTable.$refs.hotTable.hotInstance.updateSettings({
                    colWidths: [30, 72, 85, 0.1, 0.1, 0.1, 0.1, 90, 90, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 85, 65, 60, 0.1, 65, 70]
                });

                this.isHide = true;
            }

            this.$store.commit('setLoading', false);
        },
        showColumns() {
            this.$store.commit('setLoading', true);
            if (this.isHide) {
                this.$refs.myTable.$refs.hotTable.hotInstance.updateSettings({
                    colWidths: [30, 72, 85, 65, 85, 90, 80, 80, 90, 100, 60, 60, 60, 60, 60, 85, 65, 60, 60, 75, 70]
                });

                this.isHide = false;
            }

            this.$store.commit('setLoading', false);
        },
        handleTextColor(instance, td, row, col) {
            const hot = this.$refs.myTable.$refs.hotTable.hotInstance;
            td.innerHTML = hot.getSourceData()[row].bid ? ("" + hot.getSourceData()[row].bid).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '';
            if (col === 12) { //単価
                const tateneFlg = hot.getSourceData()[row].tateneFlg;
                td.className = hot.getCellMeta(row, col).__proto__.className;
                switch (tateneFlg) {
                    case false:
                        var tar = hot.getCell(row, col);
                        tar.style.color = '#8b4513';
                        break;
                    default:
                        td.className += ' htDimmed';
                        break;
                }
            }
        },
        selectAll() {
            this.$store.commit('setLoading', true);
            var data = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();
            for (var i in data) {
                if (data[i].editFlg == false) {
                    this.footerVal.rightBtn.isDisabled = false;
                    this.footerVal.rightBtnL1.isDisabled = false;
                    data[i].editFlg = true;
                }
            }
            this.$refs.myTable.$refs.hotTable.hotInstance.loadData(data);
            for (let j = 0; j < this.hiddenCheckBox.length; j++) {
                if (!this.hiddenCheckBox[j]) {
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(j, 0, 'className', 'htCenter hidden');
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(j, 0, 'readOnly', true);
                    this.$refs.myTable.$refs.hotTable.hotInstance.render();
                }
            }
            this.$store.commit('setLoading', false);
        },
        deSelectAll() {
            this.$store.commit('setLoading', true);
            this.footerVal.rightBtn.isDisabled = true;
            this.footerVal.rightBtnL1.isDisabled = true;
            var data = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();
            for (var i in data) {
                if (data[i].editFlg == true)
                    data[i].editFlg = false;
            }
            this.$refs.myTable.$refs.hotTable.hotInstance.loadData(data);
            for (let j = 0; j < this.hiddenCheckBox.length; j++) {
                if (!this.hiddenCheckBox[j]) {
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(j, 0, 'className', 'htCenter hidden');
                    this.$refs.myTable.$refs.hotTable.hotInstance.setCellMeta(j, 0, 'readOnly', true);
                    this.$refs.myTable.$refs.hotTable.hotInstance.render();
                }
            }
            this.$store.commit('setLoading', false);
        },
        delRow() {
            var data = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();
            var tokudenIdList = data.filter(value =>
                value.editFlg === true).map(value => {
                    //return value.tokudenId
                    return parseInt(value.tokudenId, 10)
                });
            if (tokudenIdList.length != 0) {
                this.$emit('showConfirmModal', {
                    type: DialogType.INFO,
                    title: '確認',
                    message: '選択された特伝を削除します。よろしいですか？',
                    confirm: () => {
                        const request = {
                            tokudenId: tokudenIdList
                        };
                        this.$store.commit('setLoading', true);
                        special.delete(request)
                            .then((response) => {
                                this.$store.commit('setLoading', false);
                                if (response.data.statusCd == 0) {
                                    this.$emit('showSimpleModal', {
                                        type: DialogType.SUCCESS,
                                        title: '確認',
                                        message: response.data.message,
                                        closeFunc: () => { this.search() }
                                    });
                                }
                            })
                            .catch((e) => {
                                console.log('error', e);
                                this.$emit('displayErrorMessage', e);
                                this.$store.commit('setLoading', false);
                            });
                    }
                });
            }
            this.$store.commit('setLoading', false);
        }
    },
}
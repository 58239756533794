import { common,  special } from '../../api/index';
import HandsontableWrapper from "../../components/table/Handsontable/Handsontable.vue";
import SearchType from '../../data/constant/search-type'
import DialogType from "../../data/constant/dialog-type";
const VueSimpleSuggest = require('vue-simple-suggest/dist/cjs');
import 'vue-simple-suggest/dist/styles.css'

export default {
    name: 'SpecialConversion',
    title: '特伝変換',
    components: {
        HandsontableWrapper,
        VueSimpleSuggest
    },
    data: function () {
        return {
            REGISTRATION_USER: SearchType.REGISTRATION_USER,
            MAKER: SearchType.MAKER,
            modalId: '',
            makerList: [],
            makerName: '',
            isSearchModalVisible: false,
            searchModalVal: {},
            registrationUserList: [],
            registrationUserName: '',

            canSearch: false,
            confirmModalVal: {},
            footerVal: {
                rightBtn: {
                    text: '特伝変換',
                    type: 'success',
                }
            },

            table: { data: [] },
            form: {
                //userCd: '',
                registrationUserCd: '',
                makerCd: '',
            },
            searchUserCd: '',
            rowCount: 0,
            tableSettings: {
                colHeaders: ['登録者', 'メーカー', '支店', '企業', '請求額', '得意先', '担当者', '最終額', '最終率', 'まとめ切り'],
                colWidths: [126, 126, 126, 126, 100, 126, 126, 100, 64, 100],
                cells: this.cells,
                beforeChange: function (changes) {
                    changes.forEach((element) => {
                        const prop = element[1];
                        const newVal = element[3];
                        if (prop === 'registerTypeName' && (newVal === null || newVal === ''))
                            element[3] = element[2];
                    })
                },
            },

            registerTypeList: [],
            fileList: [],
            suggestList: {
                makerList:[],
                registrationUserList:[]
            },
            lastInput: "",
        }
    },
    created() {
        this.$store.commit('setLoading', true);
        this.form.registrationUserCd = this.$store.state.userId;
        this.registrationUserName = this.$store.state.userName;
        this.registrationUserList = [{'cd':this.form.registrationUserCd, 'name': this.registrationUserName}]
        this.$store.commit('setLoading', false);
        
    },
    mounted() {
        const setting = {
            columns: [
                { data: "registerName", type: "text", className: "htLeft", readOnly: true },
                { data: "makerName", type: "text", className: "htLeft", readOnly: true },
                { data: "branchName", type: "text", className: "htLeft", readOnly: true },
                { data: "vendorName", type: "text", className: "htLeft", readOnly: true },
                { data: "billingAmount", type: "numeric", numericFormat: { pattern: '0,0' }, readOnly: true },
                { data: "customerName", type: "text", className: "htLeft", readOnly: true },
                { data: "userName", type: "text", className: "htLeft", readOnly: true },
                { data: "finalMarginAmount", type: "numeric", numericFormat: { pattern: '0,0' }, readOnly: true },
                { data: "finalMarginRate", type: "numeric", className: "htRight", renderer: this.$refs.myTable.percentageRenderer, readOnly: true },
                { data: "registerTypeName", type: "dropdown", className: "htLeft", strict: true, allowInvalid: false },
            ]
        };
        this.$refs.myTable.$refs.hotTable.hotInstance.updateSettings(setting, false);
        
    },
    methods: {
        suggestSearch(value, searchType) {
            console.log(searchType);
            const name = searchType === SearchType.MAKER ? 'makerName' : 'registrationUserName';
            const list = searchType === SearchType.MAKER ? 'makerList' : 'registrationUserList';
            const cd = searchType === SearchType.MAKER ? 'makerCd' : 'registrationUserCd';
            const search = searchType === SearchType.MAKER ? 'maker' : 'systemUser';
            const resultList = searchType === SearchType.MAKER ? 'makerList' : 'userList';
            const resultCd = searchType === SearchType.MAKER ? 'makerCd' : 'userCd';
            const resultName = searchType === SearchType.MAKER ? 'makerName' : 'userName';
            const suggest = searchType === SearchType.MAKER ? 'suggestMaker' : 'suggestUser';

            if(this.isSearchModalVisible){
                return;
            }

            this.lastInput = value;

            if (!value) {
                this[name] = '';
                this[list] = [];
                this.$refs[suggest].suggestions = [];
                return;
            }
            var request;
            if(searchType === SearchType.REGISTRATION_USER){
                request = {
                    userCd: value,
                    useName: ''
                };
            }else{
                request = {
                    makerCd: value,
                    makerName: ''
                };
            }

            var self = this;
            self[list] = [];
            self[name] =  '';
            common[search](request)
                .then((response) => {
                    if (!self.form[cd]) {
                        self[name] = '';
                        self[list] = [];
                        self.$refs[suggest].suggestions = [];
                        return;
                    }
                    if(this.lastInput == request[resultCd]){
                        self.suggestList[list] = response.data.result[resultList].slice(0,11).map((value) => { return { cd: value[resultCd], name: value[resultName] } });
                        self.$refs[suggest].suggestions = response.data.result[resultList].length > 0 ? response.data.result[resultList].slice(0,11).map(value => value[resultCd]) : [];
                        self.$refs[suggest].input.click();
                    }
                })
                .catch((e) => {
                    console.log('error', e);
                });
        },
        selectSuggest(searchType) {
            const name = searchType === SearchType.MAKER ? 'makerName' : 'registrationUserName';
            const list = searchType === SearchType.MAKER ? 'makerList' : 'registrationUserList';
            const cd = searchType === SearchType.MAKER ? 'makerCd' : 'registrationUserCd';
            const suggest = searchType === SearchType.MAKER ? 'suggestMaker' : 'suggestUser';

            this.form[cd] = this.$refs[suggest].selected;
            var nameTemp = this.suggestList[list].filter(value =>
                value.cd === this.$refs[suggest].selected)
            this[name] = nameTemp[0].name;
            this[list] = this.suggestList[list].length > 0 ? this.suggestList[list].filter(value =>
                value.cd === this.$refs[suggest].selected).map(value => {
                return {
                    cd: value.cd,
                    name: value.name
                }
            }) : [];

        },
        onblur(value1, searchType){
            const name = searchType === SearchType.MAKER ? 'makerName' : 'registrationUserName';
            const list = searchType === SearchType.MAKER ? 'makerList' : 'registrationUserList';
            var nameT = this.suggestList[list].filter(value =>
                value.cd === value1)
            this[name] = nameT.length == 0 ? "" : nameT[0].name;
            this[list] = nameT.length > 0 ? this.suggestList[list].filter(value =>
                value.cd === value1).map(value => {
                return {
                    cd: value.cd,
                    name: value.name
                }
            }) : [];
        },
        showSearchModal(modalId, isSingle) {
            this.modalId = modalId;
            this.searchModalVal.modalId = modalId;
            this.searchModalVal.isSingle = isSingle;
            switch (modalId) {
                case SearchType.REGISTRATION_USER:
                  this.searchModalVal.selectedItemList = this.registrationUserList;
                  break;
                case SearchType.MAKER:
                  this.searchModalVal.selectedItemList = this.makerList;
                  break;
              }
            //検索モーダル表示
            this.isSearchModalVisible = true;
        },

        /**
         * 検索条件追加
         * @param val
         * */
        addItem(val) {
            this.canSearch = false;

            switch (this.modalId) {
                case SearchType.REGISTRATION_USER:
                    this.registrationUserList = val;
                    this.suggestList.registrationUserList = val;
                    //this.form.userCd = this.registrationUserList.length > 0 ? this.registrationUserList[0].cd : '';
                    this.form.registrationUserCd = this.registrationUserList.length > 0 ? this.registrationUserList[0].cd : '';
                    this.registrationUserName = this.registrationUserList.length > 0 ? this.registrationUserList[0].name : '';
                    break;
                case SearchType.MAKER:
                    this.makerList = val;
                    this.suggestList.makerList = val;
                    this.form.makerCd = this.makerList.length > 0 ? this.makerList[0].cd : '';
                    this.makerName = this.makerList.length > 0 ? this.makerList[0].name : '';
                    break;
            }

            this.$nextTick(() => this.canSearch = true);
        },

        search() {
            this.searchUserCd = this.form.registrationUserCd;
            this.$store.commit('setLoading', true);
            //registerAdms.search(this.form)
            special.summaryList(this.form)
                .then((response) => {
                    console.log(response);
                    this.registerTypeList = response.data.result.registerTypeList || [];
                    this.fileList = response.data.result.fileList || [];
                    this.$refs.myTable.$refs.hotTable.hotInstance.loadData(response.data.result.summaryList || []);
                    this.rowCount = this.$refs.myTable.$refs.hotTable.hotInstance.countRows();

                    this.$emit('displayErrorMessage', response);
                    this.$store.commit('setLoading', false);
                })
                .catch((e) => {
                    console.log('error', e);
                    this.rowCount = 0;
                    this.registerTypeList = [];
                    this.fileList = [];
                    this.$refs.myTable.$refs.hotTable.hotInstance.loadData([]);
                    this.$emit('displayErrorMessage', e);
                    this.$store.commit('setLoading', false);
                });
        },

        clear() {
            this.form.registrationUserCd = this.$store.state.userId;
            this.registrationUserName = this.$store.state.userName;
            this.form.makerCd = '';
        },

        updateRegisterType(registerTypeCd) {
            if (!this.$refs.myTable.$refs.hotTable.hotInstance.countRows()) {
                this.$emit('showSimpleModal', {
                    type: DialogType.NOTICE,
                    title: 'Webからのメッセージ',
                    message: '検索結果がありません。'
                });
                return;
            }

            this.$emit('showConfirmModal', {
                type: DialogType.INFO,
                title: '確認',
                message: 'まとめ切り設定を一括で設定します。よろしいですか？',
                confirm: () => {
                    const registerTypeName = this.registerTypeList.find(registerType => registerType.registerTypeCd === registerTypeCd).registerTypeName;
                    const tableData = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();
                    tableData.forEach((value) => value.registerTypeName = registerTypeName);
                    this.$refs.myTable.$refs.hotTable.hotInstance.render();
                }
            });
        },

        convert() {
            if (!this.$refs.myTable.$refs.hotTable.hotInstance.countRows()) {
                this.$emit('showSimpleModal', {
                    type: DialogType.NOTICE,
                    title: 'Webからのメッセージ',
                    message: '検索結果がありません。'
                });
                return;
            }
            if (!this.searchUserCd) {
                this.$emit('showSimpleModal', {
                    type: DialogType.NOTICE,
                    title: 'Webからのメッセージ',
                    message: '検索時に登録者の指定がない場合は登録できません。'
                });
                return;
            }

            //確認ダイアログ
            this.$emit('showConfirmModal', {
                type: DialogType.NOTICE,
                title: '確認',
                message: '指定のまとめ切り設定で特伝に変換します。よろしいですか？<br>変換結果は特伝検索画面で確認してください。',
                confirm: () => {
                    const tableData = this.$refs.myTable.$refs.hotTable.hotInstance.getSourceData();

                    tableData.forEach((value) =>
                        value.registerTypeCd = this.registerTypeList.find(registerType =>
                            registerType.registerTypeName === value.registerTypeName).registerTypeCd);

                    const request = {
                        registerList: tableData,
                        fileList: this.fileList
                    };
                    this.$store.commit('setLoading', true);
                    special.convert(request)
                        .then((response) => {
                            console.log(response);
                            this.$emit('displayErrorMessage', response);
                            this.$store.commit('setLoading', false);
                            this.$refs.myTable.$refs.hotTable.hotInstance.loadData([]);
                        })
                        .catch((e) => {
                            console.log('error', e);
                            this.$emit('displayErrorMessage', e);
                            this.$store.commit('setLoading', false);
                        });
                }
            });
        },

        cells(row, cols, prop) {
            switch (prop) {
                case 'registerTypeName':
                    {
                        const cellProperties = {};
                        cellProperties.source = this.registerTypeList.map(value => value.registerTypeName);
                        return cellProperties;
                    }
            }
        }
    },
}
export default {
  name: 'ProgressBar',
  data: function() {
    return {
      visible: false
    }
  },
  methods: {
    show() {
      this.visible = true;
      this.$Progress.start();
    },
    hide() {
      this.visible = false;
      this.$Progress.finish();
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  watch: {
    loading (value) {
      if (value) {
        this.show();
      } else {
        this.hide()
      }
    }
  }
}
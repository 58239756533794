import axios from 'axios'

//const localEnv = 'local';
export const common = {
    init(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/common/init', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/common/init', request, { withCredentials: true });
    },

    yearMonth() {
        //if (process.env.NODE_ENV === localEnv) return axios.post(process.env.VUE_APP_API_BASE_URL + '/common/year-month', {},{});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/common/year-month', {}, { withCredentials: true });
    },

    vendor(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/common/search-vendor', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/common/search-vendor', request, { withCredentials: true });
    },
    center(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/common/search-center', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/common/search-center', request, { withCredentials: true });
    },
    customer(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/common/search-customer', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/common/search-customer', request, { withCredentials: true });
    },
    user(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/common/search-user', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/common/search-user', request, { withCredentials: true });
    },
    systemUser(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/common/search-system-user', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/common/search-system-user', request, { withCredentials: true });
    },
    maker(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/common/search-maker', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/common/search-maker', request, { withCredentials: true });
    },
    master(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/common/search-master', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/common/search-master', request, { withCredentials: true });
    },
};

export const commonBefore = {
    customer(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/common-before/search-customer', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/common-before/search-customer', request, { withCredentials: true });
    }
};

export const exportCheckFile = {
    search(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/export-check-file/search-maker', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/export-check-file/search-maker', request, { withCredentials: true });
    },
    checkDuplicate(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/export-check-file/check-duplicate', request, { withCredentials: true });
    },
    create(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/export-check-file/create', request, { withCredentials: true });
    },
    confirm(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/export-check-file/confirm-status', request, { withCredentials: true });
    },
    download(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/export-check-file/download', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/export-check-file/download', request, { withCredentials: true, responseType: 'blob' });
    }
};

export const uploadCheckFile = {
    search(request) {
        //if (process.env.NODE_ENV === localEnv) return axios.options(process.env.VUE_APP_API_BASE_URL + '/upload-check-file/search-file-list', {withCredentials: true});
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/upload-check-file/search-file-list', request, { withCredentials: true });
    },
    getPresignedUrl(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/upload-check-file/get-presigned-url', request, { withCredentials: true });
    },
    upload(file, url) {
        return axios.put(url, file, { headers: { "Content-Type": file.type } })
    },
    getFileInfo(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/upload-check-file/get-file-info', request, { withCredentials: true });
    }
};

export const registerAdms = {
    search(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/register-adms/summary-list', request, { withCredentials: true })
    },
    register(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/register-adms/register', request, { withCredentials: true })
    }
};

// Mock
export const special = {
    search(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/tkd/search', request, { withCredentials: true })
    },
    summaryList(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/tkd/convert-summary-list', request, { withCredentials: true })
    },
    convert(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/tkd/convert', request, { withCredentials: true })
    },
    getEditList(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/tkd/edit-list', request, { withCredentials: true })
    },
    register(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/tkd/regist', request, { withCredentials: true })
    },
    searchBilling(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/billing/search', request, { withCredentials: true })
    },
    registHostBilling(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/billing/regist-host', request, { withCredentials: true })
    },
    createTkdFile(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/billing/create-tkd-file', request, { withCredentials: true })
    },
    confirmTkdList(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/billing/check-tkd-file', request, { withCredentials: true })
    },
    delete(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/tkd/delete', request, { withCredentials: true })
    },
};

export const profit = {
    search(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/profit/search', request, { withCredentials: true })
    },
    register(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/profit/regist', request, { withCredentials: true })
    },
    delete(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/profit/delete', request, { withCredentials: true })
    },
    getEditList(request) {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/profit/edit-list', request, { withCredentials: true })
    },
};

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
   userId: '000000',
    userName: '開発ユーザー',
    loading: false,

    //検索ダイアログ
    vendor: '',
    vendorList: [],
    center: '',
    centerList: [],
    customer: '',
    customerList: [],
    beforeCustomer: '',
    beforeCustomerList: [],
    user: '',
    userList: [],
    exportUser: '',
    exportUserList: [],
    registrationUser: '',
    registrationUserList: [],
    maker: '',
    makerList: [],
    request: '',
    requestList: [],
    master:{},
    masterList: {},
    conditionSearchTkd:[],
    conditionSearchBilling:[],
    conditionSearchProfit:[],
    // Pass Data Between Components
    tokudenIdList: [],
    profitIdList: [],
  },
  mutations: {
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUserName(state, userName) {
      state.userName = userName;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setVendor(state, vendor) {
      state.vendor = vendor;
    },
    setVendorList(state, vendorList) {
      state.vendorList = vendorList;
    },
    setCenter(state, center) {
      state.center = center;
    },
    setCenterList(state, centerList) {
      state.centerList = centerList;
    },
    setCustomer(state, customer) {
      state.customer = customer;
    },
    setCustomerList(state, customerList) {
      state.customerList = customerList;
    },
    setBeforeCustomer(state, beforeCustomer) {
      state.beforeCustomer = beforeCustomer;
    },
    setBeforeCustomerList(state, beforeCustomerList) {
      state.beforeCustomerList = beforeCustomerList;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUserList(state, userList) {
      state.userList = userList;
    },
    setExportUser(state, exportUser) {
      state.exportUser = exportUser;
    },
    setExportUserList(state, exportUserList) {
      state.exportUserList = exportUserList;
    },
    setRegistrationUser(state, registrationUser) {
      state.registrationUser = registrationUser;
    },
    setRegistrationUserList(state, registrationUserList) {
      state.registrationUserList = registrationUserList;
    },
    setMaker(state, maker) {
      state.maker = maker;
    },
    setMakerList(state, makerList) {
      state.makerList = makerList;
    },
    setRequest(state, request) {
      state.request = request;
    },
    setRequestList(state, requestList) {
      state.requestList = requestList;
    },
    setTokudenIdList(state, tokudenIdList) {
      state.tokudenIdList = tokudenIdList;
    },
    setProfitIdList(state, profitIdList) {
      state.profitIdList = profitIdList;
    },
    setMaster(state, data) {
      if(data.length != undefined){
        state.master[data[1]] = data[0];
      }else {
        state.master = data;
      }
    },
    setMasterList(state, data) {
      if(data.length != undefined){
        state.masterList[data[1]] = data[0];
      }else {
        state.masterList = data;
      }
    },
    setConditionSearchTkd(state, data) {
      state.conditionSearchTkd = data;
    },
    setConditionSearchBilling(state, data) {
      state.conditionSearchBilling = data;
    },
    setConditionSearchProfit(state, data) {
      state.conditionSearchProfit = data;
    },
  },
  actions: {
  },
  modules: {
  }
})

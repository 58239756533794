var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fixed-bottom" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 text-left" }, [
          _vm.val.leftBtn
            ? _c(
                "button",
                {
                  staticClass: "btn d-inline-block",
                  class: {
                    "btn-primary": _vm.val.leftBtn.type === "primary",
                    "btn-success": _vm.val.leftBtn.type === "success",
                    "btn-danger": _vm.val.leftBtn.type === "danger"
                  },
                  attrs: { type: "button", "data-toggle": "modal" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("leftFunc")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.val.leftBtn.text))]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "col-6 text-right" }, [
          _vm.val.rightBtnL2
            ? _c(
                "button",
                {
                  staticClass: "btn d-inline-block",
                  class: {
                    "btn-primary": _vm.val.rightBtnL2.type === "primary",
                    "btn-success": _vm.val.rightBtnL2.type === "success",
                    "btn-danger": _vm.val.rightBtnL2.type === "danger"
                  },
                  attrs: {
                    disabled: _vm.val.rightBtnL2.isDisabled,
                    type: "button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("rightFuncL2")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.val.rightBtnL2.text))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.val.rightBtnL1
            ? _c(
                "button",
                {
                  staticClass: "btn d-inline-block",
                  class: {
                    "btn-primary": _vm.val.rightBtnL1.type === "primary",
                    "btn-success": _vm.val.rightBtnL1.type === "success",
                    "btn-danger": _vm.val.rightBtnL1.type === "danger"
                  },
                  attrs: {
                    disabled: _vm.val.rightBtnL1.isDisabled,
                    type: "button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("rightFuncL1")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.val.rightBtnL1.text))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.val.rightBtn
            ? _c(
                "button",
                {
                  staticClass: "btn d-inline-block",
                  class: {
                    "btn-primary": _vm.val.rightBtn.type === "primary",
                    "btn-success": _vm.val.rightBtn.type === "success",
                    "btn-danger": _vm.val.rightBtn.type === "danger"
                  },
                  attrs: {
                    disabled: _vm.val.rightBtn.isDisabled,
                    type: "button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("rightFunc")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.val.rightBtn.text))]
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
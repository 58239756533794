import DialogType from './data/constant/dialog-type'
import 'whatwg-fetch'

if (window.fetch === undefined) {
  window.fetch = fetch;
}

export default {
  data: function() {
    return {
      isSimpleModalVisible: false,
      simpleModalVal: {},
      isConfirmModalVisible: false,
      confirmModalVal: {},
      confirm() {},
      nodeEnv: ''
    }
  },
  created() {
    //開発環境のみ背景色を変更
    this.nodeEnv = process.env.NODE_ENV;
    if (this.nodeEnv ==='uat' || this.nodeEnv ==='uat-sej')
      document.body.className = 'uat-bg-color';
  },
  methods: {
    /**
     * エラーメッセージ表示
     *
     * @param e
     */
    displayErrorMessage(e) {

      let httpStatus = 0;
      // ステータスが400番台の場合、ログイン画面へ遷移
      if (e instanceof Error && this.getProperty(e, 'response.status')) {
        httpStatus = e.response.status;

      } else if (this.getProperty(e, 'status')) {
        httpStatus = e.status;
      }

      let message = (e instanceof Error) ? e.name + ': ' + e.message : (this.getProperty(e, 'data.errorMessage') || this.getProperty(e, 'data.message'));
      if (httpStatus == 400 || httpStatus == 401 || httpStatus == 500) {
        message = 'ログイン情報を確認できませんでした。'
        this.$router.push({ path: '/error'})
      }

      if (message) {
        let title = 'Webからのメッセージ';
        switch (this.getProperty(e, 'data.status')) {
          case 0:
            title = '成功';
            break;
          case 1:
            title = '成功';
            break;
          case 2:
            title = '情報';
            break;
        }

        this.showSimpleModal({
          type: (this.getProperty(e, 'data.status') !== undefined) ? this.getProperty(e, 'data.status') : DialogType.NOTICE,
          title: title,
          message: message
        })
      }
    },
    /**
     * シンプルモーダル表示
     *
     * @param setting
     */
    showSimpleModal(setting) {
      this.simpleModalVal.type = setting.type;
      this.simpleModalVal.title = setting.title;
      this.simpleModalVal.message = setting.message;
      this.simpleModalVal.size = setting.size || '';
      this.simpleModalVal.closeFunc = setting.closeFunc ? setting.closeFunc : () => {};

      this.isSimpleModalVisible = true;
    },
    /**
     * 確認モーダル表示
     *
     * @param setting
     */
    showConfirmModal(setting) {
      this.confirmModalVal.type = setting.type;
      this.confirmModalVal.title = setting.title;
      this.confirmModalVal.message = setting.message;
      this.confirmModalVal.size = setting.size || '';
      this.confirm = setting.confirm;

      this.isConfirmModalVisible = true;
    }
  }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view", {
        on: {
          showSimpleModal: _vm.showSimpleModal,
          showConfirmModal: _vm.showConfirmModal,
          displayErrorMessage: _vm.displayErrorMessage
        }
      }),
      _c("progress-bar", { ref: "progressBar" }),
      _vm.isSimpleModalVisible
        ? _c("simple-modal", {
            attrs: { val: _vm.simpleModalVal },
            on: {
              close: function($event) {
                _vm.isSimpleModalVisible = false
              }
            }
          })
        : _vm._e(),
      _vm.isConfirmModalVisible
        ? _c("confirm-modal", {
            attrs: { val: _vm.confirmModalVal },
            on: {
              callParent: _vm.confirm,
              close: function($event) {
                _vm.isConfirmModalVisible = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }